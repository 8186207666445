import React, { useCallback, useEffect, useState } from "react";
import ContentWrap from "components/ContentWrap";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";
import { ReactComponent as Share } from "../assets/share.svg";

import Button from "components/Button";
import Chip from "components/Chip";
import StaffWorkCard from "components/StaffWorkCard";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authInfo, info } from "store/auth/atom";
import { useStaffDetail } from "api/Profile/Profile";
import BottomPopup from "components/BottomPopup";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import Textarea from "components/Textarea";
import { useSendProfileToStaff } from "api/DeliveryProfile/DeliveryProfile";
import { Toast } from "hooks/useToast";
import { useStaffProductions } from "api/Production/Production";
import InfiniteScroll from "react-infinite-scroller";

const StaffDetail = () => {
  const navigate = useNavigate();
  const { staffId } = useParams();
  const [sendProfile, setSendProfile] = useState(false);
  const [auth, setAuth] = useRecoilState(authInfo);
  const [sendProfileData, setSendProfileData] = useState<{
    staffId: number | undefined;
    profileId: number | null;
    title: string;
    description: string;
  }>({
    staffId: 0,
    profileId: Number(auth?.profileId),
    title: "",
    description: "",
  });
  const [userData, setUserData] = useRecoilState<{
    id: string;
    username: string;
    role: string;
  }>(info);

  const { data, refetch, isLoading } = useStaffDetail(Number(staffId));

  const StaffData = data?.data.data;

  const {
    data: prod,
    fetchNextPage,
    hasNextPage,
  } = useStaffProductions({ memberId: Number(StaffData?.memberId) });
  const loadMore = useCallback(() => {
    return setTimeout(() => {
      fetchNextPage();
    }, 50);
  }, []);

  // const { data: workData, refetch: productData } = useProductions({
  //   title: "",
  //   format: "",
  //   directorName: "",
  //   companyName: "",
  //   memberId: Number(StaffData?.memberId),
  // });

  const sortedFilmo = StaffData?.filmographies.sort(
    (a, b) => Number(b.releaseYear) - Number(a.releaseYear)
  );

  const mutate = useSendProfileToStaff();

  const sendProfileToStaff = () => {
    mutate.mutate(
      {
        ...sendProfileData,
        staffId: StaffData?.memberId,
      },
      {
        onSuccess: () => {
          Toast.success("프로필을 보냈어요.");
          setSendProfile(false);
        },
        onError: () => {
          Toast.error("에러가 발생했습니다 다시 시도해주세요.");
        },
      }
    );
  };

  useEffect(() => {
    refetch();
  }, [refetch, staffId]);

  if (isLoading) return <div></div>;

  return (
    <ContentWrap className="py-[70px] pb-24 flex flex-col">
      <HeaderWithBackButton title={""}>
        <Bookmark fill="white" stroke="gray" />
      </HeaderWithBackButton>
      {sendProfile && (
        <BottomPopup onClose={() => setSendProfile(false)}>
          <div className="flex flex-col gap-4">
            <div className="BBody20">
              프로필과 함께 보낼 내용을
              <br />
              자유롭게 적어주세요.
            </div>
            <Textarea
              onChange={(e) => {
                setSendProfileData({
                  ...sendProfileData,
                  description: e.target.value,
                });
              }}
              className="h-[112px]"
            />
            <div className="text-Gray05 RBody14">
              스탭 회원의 캐스팅 공고와 제안은 캐스팅보트가 책임지지 않으니
              승인되지 않은 스탭의 허위 공고 또는 제안을 조심하세요.
            </div>
            <Button
              onClick={() => {
                sendProfileToStaff();
              }}
              className="bg-Blue04 w-full py-3 MBody16 text-white border-none"
              text={"프로필 보내기"}
            ></Button>
          </div>
        </BottomPopup>
      )}
      <div>
        <div className="w-full items-start flex gap-7">
          <img
            src={`${process.env.REACT_APP_MEDIA_URL}${StaffData?.thumbnailUrl}`}
            alt="Thumnai"
            className="w-[160px] h-[196px] rounded-[10px] object-cover"
          />
          <div>
            <div className="flex items-center justify-between BBody20 text-Gray09">
              <div>
                {StaffData?.profileName ? StaffData?.profileName : "홍길동"}
              </div>
            </div>
            <div className="my-2 MCaption12 flex items-start flex-wrap text-Gray05">
              <div>{StaffData?.role.label ? StaffData?.role.label : "Job"}</div>
              <div className="flex items-center">
                <div>•</div>
                <div className=" overflow-hidden text-ellipsis break-words max-w-[91px]">
                  {StaffData?.agency}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {sortedFilmo?.map((item, i) => {
                return (
                  <div key={i} className="flex items-center gap-2">
                    <div className="RBody14 text-Gray05">
                      {item?.releaseYear}
                    </div>
                    <div className="MBody14 text-Gray09">{item?.title}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {(userData?.role === "ARTIST") && (
          <Button
            onClick={() => {
              if (auth.profileId === null) {
                navigate("/artist/my");
              } else {
                setSendProfile(true);
              }
            }}
            className="w-full bg-Blue04 py-3 text-white !border-none mt-4"
            text={"프로필 보내기"}
          ></Button>
        )}
      </div>
      <div className="w-full my-4">
        {/* <div>
          <Chip title={"전체"}></Chip>
        </div> */}
        <InfiniteScroll
          threshold={10}
          hasMore={hasNextPage}
          loadMore={() => loadMore()}
        >
          {prod?.pages[0].data.content.length !== 0 ? (
            <div className=" gap-x-4 gap-y-2 pt-4 grid grid-cols-2">
              {prod?.pages.map((item: any, i) => {
                return item.data.content.map((prodItem: any) => {
                  return (
                    <StaffWorkCard
                      key={prodItem.productionId}
                      onClick={() =>
                        navigate(`/staff/filmo/${prodItem.productionId}`)
                      }
                      title={prodItem.title}
                      src={prodItem.thumbnailUrl}
                    />
                  );
                });
              })}
            </div>
          ) : (
            <div className="mt-36 items-center text-center">
              <div className="text-Gray05 RBody14">
                캐스팅 중인 공고가 없어요.
              </div>
            </div>
          )}
        </InfiniteScroll>
      </div>
    </ContentWrap>
  );
};

export default StaffDetail;
