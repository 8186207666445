import { AxiosResponse } from "axios";
import { UseQueryResult, useQuery } from "react-query";
import { StaffResponse } from "type/member";
import requestToCastingVote from "utils/requestToCastingVote";

// 스태프 목록 조회
const getStaffList = (name: string = "", page: number = 0, size: number = 10) => {
    return requestToCastingVote({
      method: "GET",
      url: "/members/staff",
      params: {
        name,
        page,
        size,
      },
    });
  };
  export const useStaffProfileList = (
    name: string = "",
    page: number = 0,
    size: number = 10
  ): UseQueryResult<AxiosResponse<StaffResponse>, Error> =>
    useQuery(["staffs", name, page, size], () => getStaffList(name, page, size));