import React from "react";

interface ProfileInfoCardType {
  name: string;
  info: string;
  size?: string;
  onClick?: () => void;
  src?: string;
}

const ProfileInfoCard = ({
  name,
  info,
  size,
  src,
  onClick,
}: ProfileInfoCardType) => {
  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
  return (
    <div>
      {src && (
        <img
          src={`${MEDIA_URL}${src}`}
          alt=""
          onClick={onClick}
          className="w-[109px] h-[136px] object-cover cursor-pointer rounded-[10px]"
        />
      )}
      <div className="BBody16 mt-2">{name}</div>

      <div className="MBody14 text-Gray05">{`${info?.split("-")[0]}년생`}</div>
      {/* {size && <div className="MBody14 text-Gray05">{size}</div>} */}
    </div>
  );
};

export default ProfileInfoCard;
