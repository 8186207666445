import React, { useEffect, useState } from "react";
import Logo from "../assets/CastingVote_logo.png";
import { ReactComponent as Noti } from "../assets/notification_icon.svg";
import {
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Button from "./Button";
import ContentWrap from "./ContentWrap";
import { useRecoilState } from "recoil";
import { loginCheck } from "store/auth/atom";

const Header = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const [login, setLogin] = useRecoilState(loginCheck);
  const [searchParams, setSearchParams] = useSearchParams();

  const productionId = Number(searchParams.get("id"));

  if (location.pathname === `/kmc/callback`) return null;

  if (location.pathname === "/login") return null;
  if (location.pathname === "/signup") return null;
  if (location.pathname === "/my") return null;

  if (location.pathname === "/artist/my") return null;
  if (location.pathname === `/artist/${params.artistId}`) return null;
  if (location.pathname === "/artist/profile") return null;
  if (location.pathname === "/artist/profile/edit") return null;
  if (location.pathname === "/artist/suggest") return null;
  if (location.pathname === "/artist/send") return null;

  if (location.pathname === "/staff/my") return null;
  if (location.pathname === `/staff/${params?.staffId}`) return null;
  if (location.pathname === "/staff/profile") return null;
  if (location.pathname === `/staff/profile/edit`) return null;
  if (location.pathname === "/staff/post") return null;
  if (location.pathname === `/staff/post/edit/${params?.id}`) return null;
  if (location.pathname === `/staff/casting-call/edit/${params?.id}`) return null;
  if (location.pathname === "/staff/casting-call") return null;
  if (location.pathname === `/staff/feedback/${params?.artistId}`) return null;
  if (location.pathname === `/staff/filmo/${params?.staffId}`) return null;
  if (location.pathname === `/staff/filmo/my/${params?.productId}`) return null;
  if (location.pathname === "/staff/casting/my") return null;
  if (location.pathname === `/staff/casting/process/${params?.id}`) return null;
  if (location.pathname === "/staff/casting-call/my") return null;
  if (location.pathname === `/staff/casting-call/my?id=${productionId}`)
    return null;
  if (location.pathname === `/staff/received`) return null;

  if (location.pathname === `/casting/${params?.itemId}`) return null;
  if (location.pathname === `/casting/my/${params?.itemId}`) return null;
  if (location.pathname === `/casting/${params?.itemId}`) return null;
  
  if (location.pathname === `/suggest`) return null;
  
  if (location.pathname === `/point`) return null;
  if (location.pathname === `/point/charge`) return null;
  

  return (
    <ContentWrap className="flex justify-between mx-auto px-5 pt-4 pb-2.5">
      <div className="flex w-full justify-between items-center">
        <img
          onClick={() => {
            navigate("/");
          }}
          className="w-[76px] h-7 mr-6 cursor-pointer"
          src={Logo}
          alt="로고"
        />
        {!login && (
          <Button
            onClick={() => {
              navigate("/login");
            }}
            className="!px-2 !py-1 rounded-md bg-Blue01 text-Blue04 border-none"
            text={"로그인"}
          />
        )}
      </div>
    </ContentWrap>
  );
};

export default Header;
