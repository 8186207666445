import React from "react";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";
import test1 from "../assets/longTestImg.png";

interface NoticeCardType {
  src?: string;
  onClick?: () => void;
  type?: string;
  productionTitle: string;
  title: string;
  gender: string;
  age: string;
  deadline?: string;
}

const NoticeCard = ({
  src,
  type,
  productionTitle,
  title,
  gender,
  age,
  deadline,
  onClick,
}: NoticeCardType) => {
  return (
    <div
      onClick={onClick}
      className="w-full p-4 cursor-pointer border border-[#D9D9D9] rounded-[10px] overflow-hidden"
    >
      <div className="flex items-center justify-between">
        <div className={`flex flex-wrap ${type && "gap-2"}`}>
          <div className="MBody14 text-Gray05">{type ? type : "작품"}</div>
          <div className="BBody14 text-Gray05">{productionTitle}</div>
        </div>
        <Bookmark />
      </div>
      <div className="mb-2 BBody18 overflow-hidden text-ellipsis whitespace-nowrap max-w-[328px]">
        {title}
      </div>
      <div className="mb-1 MBody14 gap-2 flex items-center">
        <div>{gender}</div>
        <div>|</div>
        <div>{age}</div>
      </div>
      <div className="flex items-center justify-between">
        <div className="MBody14 text-Gray05">{deadline}</div>
        {/* <div className="bg-[#FFEFEF] MCaption10 text-[#FF6060] px-2 py-1 rounded-[5px]">
          마감임박
        </div> */}
      </div>
    </div>
  );
};

export default NoticeCard;
