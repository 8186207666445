import React, { useEffect, useState } from "react";

import ContentWrap from "components/ContentWrap";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import TitleWrap from "components/TitleWrap";
import Input from "components/Input";
import Textarea from "components/Textarea";
import Button from "components/Button";
import Dropdown from "components/Dropdown";
import { CreateCastingCallData } from "type/staff";
import DateInput from "components/DateInput";
import { today } from "utils/onToday";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCreateCastingCallMutation } from "api/CastingCall/CastingCall";
import { Toast } from "hooks/useToast";
import ImgUpload from "components/ImgUpload";

import { ReactComponent as XButton } from "assets/xbutton.svg";
import { CASTING_LIST, TYPE_LIST } from "utils/type";
import { useStaffProfileList } from "api/Member/Member";
import {
  useUploadFilesMutation,
  useUploadImagesMutation,
} from "api/Attachment/Attachment";

const CreateCastingCall = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const productionId = Number(searchParams.get("id"));
  const [castingCallData, setCastingCallData] = useState<CreateCastingCallData>(
    {
      productionId: null,
      type: "",
      title: "",
      description: "",
      deadlineDate: "",
      deadlineType: "",
      location: "",
      format: "",
      startDate: "",
      endDate: "",
      role: "",
      episodeInfo: "",
      minAge: 0,
      maxAge: 0,
      ageType: "",
      gender: "",
      preferentialTreatment: "",
      qualification: "",
      numberOfPositions: 0,
      fee: 0,
      attachments: [],
      stakeholders: [],
    }
  );

  const { data: staffList } = useStaffProfileList();

  const gender = [
    { id: 0, name: "남자", gender: "MALE" },
    { id: 1, name: "여자 ", gender: "FEMALE" },
    { id: 2, name: "성별무관", gender: "ALL" },
  ];

  useEffect(() => {
    setCastingCallData({
      ...castingCallData,
      productionId: state?.prodId ? state?.prodId : null,
    });
  }, [state?.prodId]);

  const staffProfileList = staffList?.data.data.content;

  const staffInfo = staffProfileList?.map((prod) => {
    return { name: prod.name, id: prod.memberId, agency: prod.agency };
  });

  const { mutate: uploadImages } = useUploadImagesMutation();
  const { mutate: uploadFile } = useUploadFilesMutation();

  const imgUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      uploadImages(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");
          const updateItem = res.data.data.map(
            (
              item: { attachmentId: number; attachmentUrl: string },
              idx: number
            ) => ({
              id: item.attachmentId,
              type: "THUMBNAIL",
              orderNumber: idx + 1,
              url: item.attachmentUrl,
            })
          );

          setCastingCallData({
            ...castingCallData,
            attachments: [...castingCallData.attachments!, ...updateItem],
          });
        },
        onError: (err) => {
          Toast.error("에러가 발생했어요");
        },
      });
    }
  };
  const fileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      uploadFile(files, {
        onSuccess: (res) => {
          Toast.success("업로드 성공했어요");
          const updateItem = res.data.data.map(
            (
              item: { attachmentId: number; attachmentUrl: string },
              idx: number
            ) => ({
              id: item.attachmentId,
              type: "FILE",
              orderNumber: idx + 1,
              url: item.attachmentUrl,
            })
          );

          setCastingCallData({
            ...castingCallData,
            attachments: [...castingCallData.attachments!, ...updateItem],
          });
        },
        onError: (err) => {
          Toast.error("에러가 발생했어요");
        },
      });
    }
  };

  const handleDeleteImage = (id: number) => {
    const updatedAttachments = castingCallData.attachments?.filter(
      (item) => item.id !== id
    );
    setCastingCallData({ ...castingCallData, attachments: updatedAttachments });
  };

  const handleDeleteFile = (id: number) => {
    const updatedAttachments = castingCallData.attachments?.filter(
      (item) => item.id !== id
    );
    setCastingCallData({ ...castingCallData, attachments: updatedAttachments });
  };

  const { mutate } = useCreateCastingCallMutation();

  const valid = (castingCallData: CreateCastingCallData) => {
    if (!castingCallData.title) return "공고제목은 필수입니다.";
    if (!state && !castingCallData.format) return "분야를 선택해주세요.";
    if (!castingCallData.gender) return "성별을 선택해주세요.";
    if (!castingCallData.minAge) return "최소나이 입력해주세요.";
    if (!castingCallData.maxAge) return "최대나이 입력해주세요.";
    return null;
  };

  console.log(castingCallData);

  return (
    <ContentWrap className="pt-[70px] pb-24">
      <HeaderWithBackButton title={state ? state?.prodTitle : "공고등록"} />
      <div className="flex flex-col gap-10">
        <TitleWrap require title={"공고 제목"}>
          <Input
            type={"text"}
            value={castingCallData.title}
            onChange={(e) => {
              setCastingCallData({ ...castingCallData, title: e.target.value });
            }}
            placeholder="공고 제목을 입력해 주세요"
          />
        </TitleWrap>
        {!state && (
          <>
            <TitleWrap title={"대표 사진 업로드"}>
              {castingCallData.attachments?.some(
                (i) => i.type === "THUMBNAIL"
              ) ? (
                castingCallData.attachments?.map((item, i) => {
                  if (item.type === "THUMBNAIL")
                    return (
                      <div key={item.id} className="relative">
                        <img
                          className="w-full h-[360px] object-cover"
                          src={`${process.env.REACT_APP_MEDIA_URL}${item.url}`}
                          alt="THUMBNAIL"
                        />
                        <XButton
                          onClick={() => {
                            handleDeleteImage(item.id);
                          }}
                          className="absolute cursor-pointer top-4 right-4"
                        />
                      </div>
                    );
                })
              ) : (
                <ImgUpload
                  onChange={(e) => {
                    imgUpload(e);
                  }}
                  id={"THUMBNAIL"}
                >
                  <div className="w-full MBody16 bg-Blue01 text-Blue04 px-4 py-3 rounded-[10px] text-center">
                    사진 업로드
                  </div>
                </ImgUpload>
              )}
            </TitleWrap>
            <TitleWrap title={"분야"} require>
              <Dropdown
                width="w-[360px]"
                innerText={
                  castingCallData.format === "MOVIE"
                    ? "영화"
                    : castingCallData.format === "TV_SERIES"
                    ? "드라마"
                    : castingCallData.format === "VARIETY_SHOW"
                    ? "예능"
                    : castingCallData.format === "ADVERTISEMENT"
                    ? "광고"
                    : castingCallData.format === "MUSIC_VIDEO"
                    ? "뮤직비디오"
                    : castingCallData.format === "WEB_SERIES"
                    ? "웹드라마"
                    : castingCallData.format === "SHORT_FORM"
                    ? "숏폼"
                    : castingCallData.format === "INDEPENDENT_FILM"
                    ? "독립영화"
                    : castingCallData.format === "SHORT_FILM"
                    ? "단편영화"
                    : castingCallData.format === "THEATER"
                    ? "연극"
                    : castingCallData.format === "MUSICAL"
                    ? "뮤지컬"
                    : castingCallData.format === "DOCUMENTARY"
                    ? "다큐멘터리"
                    : "선택"
                }
              >
                <div>
                  {TYPE_LIST.map((item) => {
                    return (
                      <li
                        onClick={() => {
                          setCastingCallData({
                            ...castingCallData,
                            format: item.role,
                          });
                        }}
                        key={item.id}
                      >
                        {item.title}
                      </li>
                    );
                  })}
                </div>
              </Dropdown>
            </TitleWrap>
          </>
        )}
        <div className="flex items-center gap-4">
          <TitleWrap title={"배역"}>
            <Dropdown
              width="w-[360px]"
              innerText={
                castingCallData.role === "LEADING_ACTOR"
                  ? "주연"
                  : castingCallData.role === "SUPPORTING_ACTOR"
                  ? "조연"
                  : castingCallData.role === "BIT_PART"
                  ? "단역"
                  : castingCallData.role === "IMAGE_BIT_PART"
                  ? "이미지"
                  : castingCallData.role === "EXTRA"
                  ? "보조출연"
                  : castingCallData.role === "CAMEO"
                  ? "특별출연"
                  : "선택"
              }
            >
              <div>
                {CASTING_LIST.map((item) => {
                  return (
                    <li
                      onClick={() => {
                        setCastingCallData({
                          ...castingCallData,
                          role: item.role,
                        });
                      }}
                      key={item.id}
                    >
                      {item.title}
                    </li>
                  );
                })}
              </div>
            </Dropdown>
          </TitleWrap>
        </div>
        <div className="flex items-center gap-4">
          <TitleWrap title={"성별"} require>
            <Dropdown
              width="w-[172px]"
              innerText={
                castingCallData.gender === "MALE"
                  ? "남자"
                  : castingCallData.gender === "FEMALE"
                  ? "여자"
                  : castingCallData.gender === "ALL"
                  ? "성별무관"
                  : "구분"
              }
            >
              <div>
                {gender.map((item) => {
                  return (
                    <li
                      onClick={() => {
                        setCastingCallData({
                          ...castingCallData,
                          gender: item.gender,
                        });
                      }}
                      key={item.id}
                    >
                      {item.name}
                    </li>
                  );
                })}
              </div>
            </Dropdown>
          </TitleWrap>

          <TitleWrap require title={"나이"}>
            <div className="flex items-center">
              <Input
                value={String(castingCallData.minAge)}
                onChange={(e) => {
                  const value = Number(e.target.value.replace(/[^0-9]/g, ""));
                  if (
                    castingCallData?.maxAge !== 0 &&
                    castingCallData?.maxAge! < value
                  ) {
                    setCastingCallData({
                      ...castingCallData,
                      minAge: castingCallData.maxAge! - 1,
                    });
                  } else {
                    setCastingCallData({ ...castingCallData, minAge: value });
                  }
                }}
                type={"text"}
              />
              ~
              <Input
                value={String(castingCallData.maxAge)}
                onChange={(e) => {
                  const value = Number(e.target.value.replace(/[^0-9]/g, ""));
                  setCastingCallData({ ...castingCallData, maxAge: value });
                }}
                type={"text"}
              />
            </div>
          </TitleWrap>
        </div>
        <hr />
        <TitleWrap title={"상세내용"}>
          <Textarea
            value={castingCallData.description}
            onChange={(e) => {
              setCastingCallData({
                ...castingCallData,
                description: e.target.value,
              });
            }}
            className="w-full h-[210px]"
            placeholder="상세내용을 입력해 주세요"
          />
        </TitleWrap>
        <TitleWrap subTitle="PDF파일만 올려주세요(최대 1개)" title={"첨부파일"}>
          {castingCallData.attachments?.some((i) => i.type === "FILE") ? (
            castingCallData.attachments?.map((item, idx) => {
              if (item.type === "FILE")
                return (
                  <div key={item.id}>
                    <div
                      onClick={() => {
                        window.open(
                          `${process.env.REACT_APP_MEDIA_URL}${item?.url}`,
                          "_blank",
                          "noopener, noreferrer"
                        );
                      }}
                      className="text-Blue04 cursor-pointer MBody16 py-3 text-center bg-Blue01 rounded-[10px] w-full"
                    >
                      첨부파일 미리보기
                    </div>
                    <div
                      onClick={() => {
                        handleDeleteFile(item.id);
                      }}
                      className="text-Blue04 border border-Blue04 cursor-pointer MBody16 mt-2 py-3 text-center bg-Gray00 rounded-[10px] w-full"
                    >
                      첨부파일 삭제
                    </div>
                  </div>
                );
            })
          ) : (
            <ImgUpload
              onChange={(e) => {
                fileUpload(e);
              }}
              accept=".pdf"
              id={"file"}
            >
              <div className="text-Blue04 MBody16 py-3 text-center bg-Blue01 rounded-[10px] w-[109px]">
                파일선택
              </div>
            </ImgUpload>
          )}
        </TitleWrap>
        <TitleWrap title={"마감일자"}>
          <DateInput
            value={castingCallData.deadlineDate}
            onChange={(e) => {
              setCastingCallData({
                ...castingCallData,
                deadlineDate: e.target.value,
              });
            }}
            min={today()}
            className="w-full"
          />
        </TitleWrap>
        {/* <div className="flex items-center gap-4">
          <TitleWrap title={"후보 확정 권한 추가"}>
            <Dropdown width="w-[172px]" innerText={"선택"}>
              <div>
                {staffInfo?.map((item, i) => {
                  return (
                    <li key={item.id} className="flex items-center max-w-[138px] gap-1 overflow-hidden text-ellipsis whitespace-nowrap">
                      <div className="MBody16 text-Gray09">{item.name}</div>
                      <div className="MBody14 text-Gray05">{item.agency}</div>
                    </li>
                  );
                })}
              </div>
            </Dropdown>
          </TitleWrap>

          <TitleWrap title={"참조 관계자 추가"}>
            <Dropdown width="w-[172px]" innerText={"선택"}>
              <div>
                {staffInfo?.map((item, i) => {
                  return (
                    <li key={item.id} className="flex items-center max-w-[138px] gap-1 overflow-hidden text-ellipsis whitespace-nowrap">
                      <div className="MBody16 text-Gray09">{item.name}</div>
                      <div className="MBody14 text-Gray05">{item.agency}</div>
                    </li>
                  );
                })}
              </div>
            </Dropdown>
          </TitleWrap>
        </div> */}
      </div>
      <div className="max-w-[400px] bg-Gray00 w-full fixed bottom-0 left-2/4 -translate-x-2/4 mt-5 pb-8 px-5">
        <Button
          onClick={() => {
            const validError = valid(castingCallData);
            if (validError !== null) {
              Toast.error(validError);
            } else {
              mutate(castingCallData, {
                onSuccess: (response) => {
                  navigate(`/casting/${response.data.data}`, { replace: true });
                  Toast.success("캐스팅 공고가 등록되었어요");
                },
                onError: (error) => {
                  console.error(
                    "공고 등록 중 오류 발생:",
                    error.response?.data
                  );
                },
              });
            }
          }}
          className="w-full h-12 bg-Blue04 text-white border-none"
          text={`등록하기`}
        ></Button>
      </div>
    </ContentWrap>
  );
};

export default CreateCastingCall;
