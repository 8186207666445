import React, { useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import requestToCastingVote from "utils/requestToCastingVote";

const KmcCallback = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const apiToken = searchParams.get("apiToken");
  const certNum = searchParams.get("certNum");

  const handleKmcToken = async ({ apiToken, apiCertNum }: any) => {
    const UserAgent = navigator.userAgent;

    const result = await requestToCastingVote({
      method: "POST",
      url: "kmc/token",
      params: { apiToken, apiCertNum },
    }).then((res) => {
      const data = {
        certNum: res.data.data.certNum,
        phoneNo: res.data.data.phoneNo,
        birthDay: res.data.data.birthDay,
        gender: res.data.data.gender,
        name: res.data.data.name,
      };
      if (
        UserAgent.match(
          /iPhone|iPad|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
        )
      ) {
        navigate("/signup", { state: data });
      } else {
        window.opener.postMessage(
          data,

          "https://test.castingvote.im"
        );
        window.self.close();
      }

      return res.data;
    });
  };

  handleKmcToken({
    apiToken: apiToken,
    apiCertNum: certNum,
  });

  return <div>본인인증 중입니다</div>;
};

export default KmcCallback;
