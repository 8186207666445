import React, { useState } from "react";
import NoProfile from "../assets/noProfile.png";
import ContentWrap from "components/ContentWrap";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import Button from "components/Button";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as Kebab } from "assets/kebabIcon.svg";
import OptionSelectBox from "components/OptionSelectBox";
import { useProductionCastingCalls } from "api/Mypage/Mypage";

const StaffMyCastingCall = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [menu, setMenu] = useState<number | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const productionId = Number(searchParams.get("id"));

  const { data } = useProductionCastingCalls(productionId);

  const myCastingCallData = data?.data.data.content;

  const menuList = [
    { data: "수정", loca: `/staff/casting-call` },
    { data: "마감" },
    { data: "캐스팅 보기" },
  ];

  return (
    <div>
      <HeaderWithBackButton title={state.title}>
        <div
          onClick={() => {
            navigate(`/staff/casting-call`);
          }}
          className="cursor-pointer BBody16 text-Blue04"
        >
          등록
        </div>
      </HeaderWithBackButton>
      {myCastingCallData?.length === 0 ? (
        <ContentWrap className="text-center mx-auto my-20">
          <img
            className="w-[60px] h-[60px] mx-auto"
            src={NoProfile}
            alt="NoProfile"
          />
          <div className="mt-4 mb-10 MBody16 text-Gray05 whitespace-normal">
            아직 공고가 없어요.
          </div>
          <Button
            className="px-4 py-3 text-Gray00 bg-Blue04 border-none"
            text={"공고 등록하기"}
            onClick={() => {
              navigate(`/staff/casting-call?id=${productionId}`);
            }}
          />
        </ContentWrap>
      ) : (
        <ContentWrap className="mx-auto my-20">
          {myCastingCallData?.map((item, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  navigate(`/casting/${item.castingCallId}`);
                }}
                className="w-full cursor-pointer py-4 relative border-b border-[#E0E3EB] flex flex-col gap-2"
              >
                <div className="BBody18 text-Gray09">{item.title}</div>
                <div className="RBody14 text-Gray05">
                  {item?.deadlineDate} 마감
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setMenu((prev) => (prev === i ? null : i));
                  }}
                  className="absolute top-[21px] right-0"
                >
                  <Kebab className="cursor-pointer" />
                </div>
                {menu === i && (
                  <OptionSelectBox onClose={() => setMenu(null)}>
                    {menuList.map((select, i) => {
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            // if (select?.path) {
                            //   navigate(`${select?.path}/${item.productionId}`);
                            // } else {
                            navigate(
                              `${select?.loca}?id=${item.castingCallId}`
                            );
                            // }
                          }}
                          key={i}
                        >
                          {select.data}
                        </div>
                      );
                    })}
                  </OptionSelectBox>
                )}
              </div>
            );
          })}
        </ContentWrap>
      )}
    </div>
  );
};

export default StaffMyCastingCall;
