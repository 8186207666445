import React from "react";
import Button from "./Button";

import Male from "assets/male.png";
import Female from "assets/female.png";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";

interface StaffCardType {
  onClick?: () => void;
  image?: string;
  name?: string;
  gender?: string;
  children?: React.ReactNode;
  role?: string;
  agency?: string;
}

const StaffCard = ({
  onClick,
  image,
  children,
  name,
  gender,
  role,
  agency,
}: StaffCardType) => {
  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
  return (
    <div
      onClick={onClick}
      className="cursor-pointer border border-[#E0E3EB] gap-4 rounded-[10px] p-4 w-full flex items-start"
    >
      <img
        src={`${MEDIA_URL}${image}`}
        alt="Thumnail"
        className="w-[60px] h-[60px] object-cover rounded-full"
      />
      <div className="flex-1">
        <div className="flex items-center justify-between BBody16">
          <div>{name}</div>
          <Bookmark />
        </div>
        <div className="flex items-center text-Gray05 gap-2 my-2 BBody14">
          <div>{role}</div>
          <div>|</div>
          <div className="flex-1 text-ellipsis overflow-hidden whitespace-nowrap">
            {agency}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default StaffCard;
