import React, { useEffect, useState } from "react";
import ContentWrap from "components/ContentWrap";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";

import Insta from "../assets/Instagram_black.png";
import Youtue from "../assets/YouTube_black.png";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { info } from "store/auth/atom";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import YouTube from "react-youtube";
import { Autoplay } from "swiper/modules";
import Button from "components/Button";
import { useArtistDetail } from "api/Profile/Profile";
import ImageModal from "components/ImageModal";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";
import { ReactComponent as XButton } from "assets/xbutton.svg";

const ArtistDetail = () => {
  const navigate = useNavigate();
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [imageDetail, setImageDetail] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [userData, setUserData] = useRecoilState<{
    id: string;
    username: string;
    role: string;
  }>(info);

  console.log(startIndex);

  const { artistId } = useParams();

  const { data, isLoading, isError, refetch } = useArtistDetail(
    Number(artistId) as number
  );

  const ArtistData = data?.data.data;

  const calculateAge = () => {
    const currentYear = new Date().getFullYear();
    const calculatedAge =
      currentYear - Number(ArtistData?.birthDate.split("-")[0]);
    return calculatedAge;
  };

  const handleOpenNewTab = (url: string) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  const opt = {
    height: "203",
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
    },
  };

  useEffect(() => {
    refetch();
  }, [artistId, refetch]);

  const groupedByYear = ArtistData?.filmographies?.reduce((acc: any, item) => {
    const year = item.releaseYear;
    if (!acc[year]) {
      acc[year] = [];
    }

    acc[year].push(item);

    acc[year].sort((a: any, b: any) => a.orderNumber - b.orderNumber);

    return acc;
  }, {});

  const artistInfo = [
    { id: 1, type: "거주지", data: ArtistData?.basicInfo.address.sido },
    { id: 2, type: "학력", data: ArtistData?.basicInfo.education },
    { id: 3, type: "매니저/소속사", data: ArtistData?.basicInfo.agency },
  ];

  const filteredAttachments = ArtistData?.attachments
    ?.filter(
      (attachment) =>
        attachment.type.code !== "THUMBNAIL" &&
        attachment.type.code !== "ADDITIONAL_VIDEO"
    )
    ?.sort((a, b) => {
      if (
        a.type.code === "ADDITIONAL_IMAGE" &&
        b.type.code !== "ADDITIONAL_IMAGE"
      ) {
        return -1;
      } else if (
        a.type.code !== "ADDITIONAL_IMAGE" &&
        b.type.code === "ADDITIONAL_IMAGE"
      ) {
        return 1;
      }
      return 0;
    });

  console.log(filteredAttachments);

  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

  const handlePrev = () => {
    // 이전으로 이동
    swiper?.slidePrev();
  };
  const handleNext = () => {
    // 이전으로 이동
    swiper?.slideNext();
  };

  return (
    <>
      {isLoading || isError ? (
        <div>loading</div>
      ) : (
        <ContentWrap className="flex py-[70px] pb-24 flex-col items-start">
          <HeaderWithBackButton title={""}>
            <Bookmark className="cursor-pointer" />
          </HeaderWithBackButton>
          {imageDetail && (
            <ImageModal
              onClose={() => setImageDetail(false)}
              className="max-w-[360px] w-full"
            >
              <Swiper
                onSwiper={(e: SwiperClass) => {
                  setSwiper(e);
                }}
                slidesPerView={1}
                className="relative"
              >
                {ArtistData?.attachments?.map((item, index) => {
                  return (
                    <SwiperSlide key={index} className="h-fit relative">
                      <img
                        src={`${MEDIA_URL}${item.url}`}
                        alt="IMAGES"
                        className="rounded-[10px] w-full max-w-[360px] h-[514px] mx-auto object-cover "
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <XButton
                onClick={() => {
                  setImageDetail(false);
                }}
                className="absolute cursor-pointer top-4 right-4 z-50"
              />
              <ArrowLeft
                onClick={() => {
                  handlePrev();
                }}
                className="absolute top-2/4 left-0 z-50 cursor-pointer"
              />
              <ArrowLeft
                onClick={() => {
                  handleNext();
                }}
                className="absolute top-2/4 right-0 z-50 rotate-180 cursor-pointer"
              />
            </ImageModal>
          )}
          <div className="w-full flex flex-col gap-2">
            <div className="BBody14 text-Gray05">
              {ArtistData?.basicInfo.job.label}
            </div>
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <div className="BBody20">{ArtistData?.profileName}</div>
                {ArtistData?.snsInfo.instagram && (
                  <img
                    onClick={() => {
                      if (
                        ArtistData?.snsInfo.instagram.startsWith(
                          "https://www.instagram.com/"
                        )
                      ) {
                        handleOpenNewTab(ArtistData?.snsInfo.instagram!);
                      } else {
                      }
                      handleOpenNewTab(
                        `https://www.instagram.com/${ArtistData?.snsInfo
                          .instagram!}`
                      );
                    }}
                    className="cursor-pointer"
                    src={Insta}
                    alt="instagram"
                  />
                )}
                {ArtistData?.snsInfo.youtube && (
                  <img
                    onClick={() => {
                      handleOpenNewTab(ArtistData?.snsInfo.youtube!);
                    }}
                    className="cursor-pointer"
                    src={Youtue}
                    alt="youtube"
                  />
                )}
              </div>
            </div>
            <div className="flex mb-2 items-center RBody16 gap-2">
              <div>{`${
                ArtistData?.birthDate.split("-")[0]
              }년생 (${calculateAge()}세)`}</div>
              <div>{`${ArtistData?.basicInfo.height}cm`}</div>
              <div>{`${ArtistData?.basicInfo.weight}kg`}</div>
            </div>
            {/* <div className="flex flex-wrap gap-x-2 RBody16 text-Blue04">
                {ArtistData?.hashtags?.map((item) => {
                  return <div key={item.id}>#{item.name}</div>;
                })}
              </div> */}
            <div>
              <img
                onClick={() => {
                  setImageDetail(true);
                  setStartIndex(0);
                }}
                src={`${MEDIA_URL}${ArtistData?.thumbnailUrl}`}
                alt=""
                className="w-full h-[450px] object-cover rounded-[10px]"
              />
              <div className="w-full mt-2">
                <Swiper
                  // autoplay={{ delay: 2000, disableOnInteraction: false }}
                  // loop={true}
                  // modules={[Autoplay]}
                  spaceBetween={8}
                  slidesPerView={filteredAttachments?.length! < 4 ? 3 : "auto"}
                >
                  {filteredAttachments?.map((item, idx) => {
                    return (
                      <SwiperSlide
                        className={
                          filteredAttachments.length! < 4 ? "w-full" : "!w-fit"
                        }
                        onClick={() => {
                          setImageDetail(true);
                          setStartIndex(idx + 1);
                        }}
                        key={idx}
                      >
                        <div className="w-full">
                          <img
                            src={`${MEDIA_URL}${item.url}`}
                            alt="IMAGES"
                            className="w-[114px] h-[114px] rounded-[10px] object-cover"
                          />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {ArtistData?.attachments?.map((item, idx) => {
                if (item.type.code === "ADDITIONAL_VIDEO") {
                  return (
                    <video
                      key={item.id}
                      controls
                      className="w-full h-[186px] rounded-[10px]"
                    >
                      <source src={`${MEDIA_URL}${item.url}`}></source>
                    </video>
                  );
                }
              })}
              {ArtistData?.videoLinks?.map((item, idx) => {
                let link = "";

                if (item.link.startsWith("https://www.youtube.com")) {
                  link = item.link.split("watch?v=")[1];
                } else {
                  link = item.link.split("?")[0].split("/")[3];
                }
                return (
                  <YouTube
                    key={item.orderNumber}
                    className={"w-full"}
                    opts={opt}
                    iframeClassName="youtubeRounded"
                    videoId={link}
                  />
                );
              })}
            </div>
          </div>
          <div className="flex-1 w-full">
            <div className="flex flex-wrap gap-y-10 items-center">
              {artistInfo.map((item) => {
                if (item?.id < 2 && item?.data)
                  return (
                    <>
                      <hr className="w-full h-px mt-10 bg-[#D9D9D9]" />
                      <div key={item.id}>
                        {item.data !== "0cm/0kg" ? (
                          <div className="flex flex-col w-[180px]">
                            <div className="RBody14 text-Gray05">
                              {item.type}
                            </div>
                            <div className="MBody16">{item.data}</div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  );
              })}
              <div>
                {ArtistData?.hobbies.length !== 0 && (
                  <div className="flex flex-col w-[360px]">
                    <div className="RBody14 text-Gray05">특기</div>
                    <div className="MBody16 flex items-center">
                      {ArtistData?.hobbies.map((item, idx) => {
                        return (
                          <div key={item.id}>
                            {item.name}
                            {idx < ArtistData.hobbies.length - 1 ? "/" : ""}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              {artistInfo.map((item) => {
                if (item.id > 1 && item.data)
                  return (
                    <div className="flex flex-col w-[360px]" key={item.id}>
                      {item.data !== "" ? (
                        <div className="flex flex-col">
                          <div className="RBody14 text-Gray05">{item.type}</div>
                          <div className="MBody16">{item.data}</div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
              })}
            </div>
            {ArtistData?.introduce !== "" && (
              <>
                <hr className="w-full h-px my-10 bg-[#D9D9D9]" />
                <div className="w-full flex flex-col gap-2">
                  <div className="RBody16 text-Gray05">자기소개</div>
                  <div className="MBody16 max-h-[336px] whitespace-pre-wrap overflow-y-scroll">
                    {ArtistData?.introduce}
                  </div>
                </div>
              </>
            )}
            {ArtistData?.filmographies?.length !== 0 && (
              <>
                <hr className="w-full h-px my-10 bg-[#D9D9D9]" />
                <div className="w-full flex flex-col gap-2">
                  <div className="RBody16 text-Gray05">필모그래피</div>
                  {ArtistData ? (
                    <div className="flex flex-col gap-4">
                      {Object.keys(groupedByYear)
                        .sort((a, b) => Number(b) - Number(a))
                        ?.map((year) => (
                          <div className="flex items-start gap-4" key={year}>
                            <div className="RBody14 text-Gray09">{year}</div>
                            <div className="flex flex-col gap-4">
                              {groupedByYear[year].map((item: any) => (
                                <div key={item.id}>
                                  <div className="RBody14 text-Gray05">
                                    {item?.type?.label}
                                  </div>
                                  <div className="BBody16 flex  items-center gap-2">
                                    <div>{item.title}</div>
                                    <div className="text-Gray05">
                                      {item?.role?.label}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </div>
          {userData?.role !== "ARTIST" && (
            <div className="max-w-[400px] bg-Gray00 w-full fixed z-[9998] bottom-0 left-2/4 -translate-x-2/4 mt-5 pb-8 px-5">
              <Button
                onClick={() => {
                  navigate("/suggest#step=1", {
                    state: {
                      artistId: artistId,
                      memberId: ArtistData?.memberId,
                      artistName: ArtistData?.profileName,
                    },
                  });
                }}
                text={"제안하기"}
                className="w-full text-Gray00 bg-Blue04"
              ></Button>
            </div>
          )}
        </ContentWrap>
      )}
    </>
  );
};

export default ArtistDetail;
