import Chip from "components/Chip";
import ContentWrap from "components/ContentWrap";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useResetRecoilState } from "recoil";
import { onLogOut } from "utils/onLogOut";
import { authInfo, loginCheck } from "store/auth/atom";

const Mypage = () => {
  const navigate = useNavigate();
  const [login, setLogin] = useRecoilState(loginCheck);
  const [auth, setAuth] = useRecoilState(authInfo);

  const [userInfo, setUserInfo] = useState<{
    id: string;
    username: string;
    role: string;
  }>({ id: "", username: "", role: "" });

  useEffect(() => {
    const updatedUserInfo = localStorage.getItem("user");

    let userInfo = updatedUserInfo ? JSON.parse(updatedUserInfo) : null;
    if (userInfo && userInfo.id) {
      setLogin(true);
      setUserInfo({
        id: userInfo.id,
        username: userInfo.username,
        role: userInfo.role,
      });
    } else {
      setLogin(false);
    }
  }, []);

  const ArtistMenu = [
    { id: 0, title: "내 프로필", path: "/artist/my" },
    { id: 1, title: "보낸 프로필", path: "/artist/send" },
    { id: 2, title: "받은 제안", path: "/artist/suggest" },
    { id: 3, title: "스크랩", path: "" },
  ];

  const StaffMenu = [
    { id: 0, title: "내 프로필", path: "/staff/my" },
    {
      id: 1,
      title: "내 캐스팅",
      path: auth.profileId === null ? "/staff/my" : "/staff/casting/my",
    },
    { id: 2, title: "받은 프로필", path: "/staff/received" },
    // { id:3, title: "공고에 받은 프로필", path: "" },
    { id: 4, title: "스크랩", path: "" },
  ];

  const resetList = useResetRecoilState(authInfo);

  return (
    <ContentWrap>
      <HeaderWithBackButton title="내 프로필" />
      <div className="py-[70px]">
        <div>
          <div className="flex items-center gap-4">
            {auth?.thumbnailUrl && (
              <img
                src={`${process.env.REACT_APP_MEDIA_URL}${auth?.thumbnailUrl}`}
                alt={"profileThumnial"}
                className="w-12 h-12 rounded-full object-cover"
              />
            )}
            <div className="BBody20 text-Gray09">{auth?.name}</div>
          </div>
          <div className="mt-10 flex flex-col gap-4">
            <div className="flex items-center justify-between">
              <div className="MBody18">포인트</div>
              <div className="flex items-center gap-2">
                <div
                  onClick={() => {
                    navigate("/point");
                  }}
                  className="text-Blue04 cursor-pointer !BBody18"
                >
                  0
                </div>
                <Chip
                  selected
                  title={userInfo?.role === "ARTIST" ? "충전" : "환급"}
                ></Chip>
              </div>
            </div>
          </div>
          <hr className="w-full h-px bg-Gray03 my-4" />
          <div className="flex flex-col MBody18 gap-4">
            {userInfo?.role === "ARTIST"
              ? ArtistMenu.map((item, i) => {
                  return (
                    <div key={item.id}>
                      <div
                        onClick={() => {
                          navigate(item.path);
                        }}
                        className="MBody18 cursor-pointer"
                      >
                        {item.title}
                      </div>
                    </div>
                  );
                })
              : StaffMenu.map((item, i) => {
                  return (
                    <div key={item.id}>
                      <div
                        onClick={() => {
                          navigate(item.path);
                        }}
                        className="MBody18 cursor-pointer"
                      >
                        {item.title}
                      </div>
                    </div>
                  );
                })}
          </div>
          <hr className="w-full h-px bg-Gray03 my-4" />
          <div className="flex MBody18 flex-col gap-4">
            {/* <div className="cursor-pointer">내정보</div> */}
            <div
              onClick={() => {
                window.open(
                  "https://daffy-periwinkle-38f.notion.site/FAQ-1344e358e81b80f9bbd3d7c0a76f3398",
                  "_blank",
                  "noopener, noreferrer"
                );
              }}
              className="cursor-pointer"
            >
              고객센터
            </div>
            <div
              className="cursor-pointer text-Gray05"
              onClick={() => {
                resetList();
                onLogOut();
                setLogin(false);
              }}
            >
              로그아웃
            </div>
          </div>
        </div>
      </div>
    </ContentWrap>
  );
};

export default Mypage;
