import { AxiosResponse, AxiosError } from "axios";
import {
  UseMutationResult,
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";
import {
  ArtistDetailData,
  ArtistList,
  ArtistListData,
  CreateArtistProfileType,
  CreateArtistProfileData,
  ProfileUpdate,
  ARTIST_PROFILE,
  StaffProfileUpdateRequest,
  ProfileUpdateResponse,
} from "type/artist";
import {
  CreateStaffProfile,
  CreateStaffProfileData,
  StaffDetailData,
  StaffList,
  StaffListData,
} from "type/staff";
import requestToCastingVote from "utils/requestToCastingVote";

// 아티스트 프로필 등록
const createArtist = ({
  name,
  job,
  sido,
  height,
  weight,
  education,
  rank,
  agency,
  introduce,
  instagram,
  youtube,
  // hashtags,
  hobbies,
  filmographies,
  attachments,
  videoLinks,
}: ARTIST_PROFILE) => {
  return requestToCastingVote({
    method: "POST",
    url: "profiles/artist",
    data: {
      name: name,
      job: job,
      sido: sido,
      height: height,
      weight: weight,
      education: education,
      rank: rank,
      agency: agency,
      introduce: introduce,
      instagram: instagram,
      youtube: youtube,
      // hashtags: hashtags,
      hobbies: hobbies,
      filmographies: filmographies,
      attachments: attachments,
      videoLinks: videoLinks,
    },
  });
};
export const useCreateArtistProfileMutation = (
  data: ARTIST_PROFILE
): UseMutationResult<
  AxiosResponse<CreateArtistProfileData>,
  Error,
  ARTIST_PROFILE
> => {
  const createArtistMutation = useMutation<
    AxiosResponse<any>,
    AxiosError,
    ARTIST_PROFILE
  >({
    mutationFn: createArtist,
  });

  return createArtistMutation;
};

// 스태프 프로필 등록
const createStaff = ({ filmographies, attachments }: CreateStaffProfile) => {
  return requestToCastingVote({
    method: "POST",
    url: "profiles/staff",
    data: {
      filmographies,
      attachments,
    },
  });
};
export const useCreateStaffProfileMutation = (
  data: CreateStaffProfile
): UseMutationResult<
  AxiosResponse<CreateStaffProfileData>,
  Error,
  CreateStaffProfile
> => {
  const createStaffMutation = useMutation<
    AxiosResponse<CreateStaffProfileData>,
    AxiosError,
    CreateStaffProfile
  >({
    mutationFn: createStaff,
  });

  return createStaffMutation;
};

// 아티스트 프로필 수정
const updateArtist = ({
  profileId,
  name,
  job,
  sido,
  height,
  weight,
  education,
  rank,
  agency,
  introduce,
  instagram,
  youtube,
  // addHashtags,
  // removeHashtagIds,
  hobbies,
  addHobbies,
  removeHobbyIds,
  addAttachments,
  removeAttachments,
  addFilmographies,
  removeFilmographyIds,
  addVideoLinks,
  removeVideoLinks,
}: ARTIST_PROFILE) => {
  return requestToCastingVote({
    method: "PATCH",
    url: "profiles/artist",
    data: {
      profileId: profileId,
      name: name,
      job: job,
      sido: sido,
      height: height,
      weight: weight,
      education: education,
      rank: rank,
      agency: agency,
      introduce: introduce,
      instagram: instagram,
      youtube: youtube,
      // addHashtags,
      // removeHashtagIds,
      hobbies,
      addHobbies,
      removeHobbyIds,
      addAttachments,
      removeAttachments,
      addFilmographies,
      removeFilmographyIds,
      addVideoLinks,
      removeVideoLinks,
    },
  });
};
export const useUpdateArtistProfileMutation = (
  data: ARTIST_PROFILE
): UseMutationResult<
  AxiosResponse<ProfileUpdateResponse>,
  Error,
  ARTIST_PROFILE
> => {
  const updateArtistMutation = useMutation<
    AxiosResponse<ProfileUpdateResponse>,
    AxiosError,
    ARTIST_PROFILE
  >({
    mutationFn: updateArtist,
  });

  return updateArtistMutation;
};

// 스탭 프로필 수정
const updateStaff = (
  profileData: StaffProfileUpdateRequest
): Promise<AxiosResponse<ProfileUpdateResponse>> => {
  return requestToCastingVote.patch("/profiles/staff", profileData);
};
export const useUpdateStaffProfileMutation = (
  data: StaffProfileUpdateRequest
): UseMutationResult<
  AxiosResponse<ProfileUpdateResponse>,
  Error,
  StaffProfileUpdateRequest
> => {
  const updateStaffMutation = useMutation<
    AxiosResponse<ProfileUpdateResponse>,
    AxiosError,
    StaffProfileUpdateRequest
  >({
    mutationFn: updateStaff,
  });
  return updateStaffMutation;
};

// 아티스트 프로필 목록 조회
// 무한스크롤
const fetchArtists = ({
  name,
  gender,
  job,
  ageGoe,
  ageLoe,
  heightGoe,
  heightLoe,
  weightGoe,
  weightLoe,
  hashtagIds,
  hobbyIds,
  page,
  size,
}: ArtistList) => {
  return requestToCastingVote({
    method: "GET",
    url: "/profiles/artist",
    params: {
      name,
      gender,
      job,
      ageGoe,
      ageLoe,
      heightGoe,
      heightLoe,
      weightGoe,
      weightLoe,
      hashtagIds,
      hobbyIds,
      page,
      size,
    },
  }).then((res) => res.data);
};
export const useArtists = (params: ArtistList) => {
  return useInfiniteQuery(
    ["artistProfiles", params],
    ({ pageParam = 0 }) =>
      fetchArtists({ ...params, page: pageParam, size: 20}),
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      refetchOnWindowFocus: false,
    }
  );
};

// 아티스트 프로필 상세 조회
const getArtistDetail = (id: number) => {
  return requestToCastingVote({
    method: "GET",
    url: `profiles/${id}/artist`,
  });
};
export const useArtistDetail = (
  id: number
): UseQueryResult<AxiosResponse<ArtistDetailData>, Error> =>
  useQuery(["useArtistDetail"], () => getArtistDetail(id));

// 스태프 프로필 목록 조회
const fetchStaffs = ({ name, role, page, size }: StaffList) => {
  return requestToCastingVote({
    method: "GET",
    url: "profiles/staff",
    params: {
      name,
      role,
      page,
      size,
    },
  }).then((res) => res.data);
};
export const useStaffs = (params: StaffList) => {
  return useInfiniteQuery(
    ["staffProfiles", params],
    ({ pageParam = 0 }) =>
      fetchStaffs({ ...params, page: pageParam, size: 20 }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      refetchOnWindowFocus: false,
    }
  );
};

// 스태프 프로필 상세 조회
const getStaffDetail = (id: number) => {
  return requestToCastingVote({
    method: "GET",
    url: `profiles/${id}/staff`,
  });
};
export const useStaffDetail = (
  id: number
): UseQueryResult<AxiosResponse<StaffDetailData>, Error> =>
  useQuery(["useStaffDetail"], () => getStaffDetail(id));
