import React, { useCallback, useState } from "react";

import ContentWrap from "components/ContentWrap";
import Button from "components/Button";
import { ScrollRestoration, useNavigate } from "react-router-dom";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import {
  useMyCastingCalls,
  useMyProductionCastingCalls,
  useProductionCastingCalls,
  useProductions,
} from "api/Mypage/Mypage";
import { ReactComponent as Kebab } from "assets/kebabIcon.svg";
import Thumnail from "assets/thumnail.png";

import BottomPopup from "components/BottomPopup";
import OptionSelectBox from "components/OptionSelectBox";
import InfiniteScroll from "react-infinite-scroller";

const StaffMyCasting = () => {
  const navigate = useNavigate();
  const [accordionOpenId, setAccordionOpenId] = useState<number | null>(null);
  const [productId, setProductId] = useState(0);
  const [productOption, setProductOption] = useState<number | null>(null);
  // const [selectProduct, setSelectProduct] = useState(false);
  const [castingCallOption, setCastingCallOption] = useState<number | null>(
    null
  );
  const [tabId, setTabId] = useState(0);

  const tab = [
    { id: 0, title: "작품별" },
    { id: 1, title: "작품 미분류" },
  ];

  const { data: prod, fetchNextPage, hasNextPage, refetch } = useProductions();
  const loadMore = useCallback(() => {
    return setTimeout(() => {
      fetchNextPage();
    }, 50);
  }, []);

  const {
    data: castings,
    fetchNextPage: castingsNextPage,
    hasNextPage: castingHasPage,
  } = useMyCastingCalls({ isWithProduction: false });
  const castingsloadMore = useCallback(() => {
    return setTimeout(() => {
      castingsNextPage();
    }, 50);
  }, []);
  
  const {
    data: productWidthCastingCallData,
    fetchNextPage: productWithCastingcallNextPage,
    hasNextPage: productWithCastingcallHasPage,
  } = useMyProductionCastingCalls({ id: productId });
  const productWithCastingsloadMore = useCallback(() => {
    return setTimeout(() => {
      productWithCastingcallNextPage();
    }, 50);
  }, []);
  
  const { data: productCastingCall } = useProductionCastingCalls(productId);
  const productCastingCallList = productCastingCall?.data.data.content;

  const optionItem = [
    {
      id: 0,
      title: "수정",
      action: (id: number) => {
        navigate(`/staff/post/edit/${id}`);
      },
    },
    {
      id: 1,
      title: "공고등록",
      action: (id: number, title: string) => {
        navigate("/staff/casting-call", {
          state: {
            prodTitle: title,
            prodId: id,
          },
        });
      },
    },
    {
      id: 2,
      title: "작품보기",
      action: (id: number) => {
        navigate(`/staff/filmo/my/${id}`);
      },
    },
  ];

  const castingCallItem = [
    {
      id: 0,
      title: "수정",
      action: (id: number, ProductTitle?: string) =>
        navigate(`/staff/casting-call/edit/${id}`, { state: ProductTitle }),
    },
    { id: 1, title: "마감", action: () => console.log("마감 선택됨") },
    {
      id: 2,
      title: "공고보기",
      action: (id: number) => navigate(`/casting/my/${id}`),
    },
  ];

  return (
    <div>
      <HeaderWithBackButton title="내 캐스팅">
        <div
          onClick={() => {
            navigate("/staff/post");
          }}
          className={`BBody16 text-Blue04 flex items-center gap-2 cursor-pointer`}
        >
          <div>작품등록</div>
        </div>
      </HeaderWithBackButton>
      {/* {selectProduct && (
        <BottomPopup onClose={() => setSelectProduct(false)}>
          <div className="text-Gray09 BBody20">작품선택</div>
          <InfiniteScroll
            threshold={20}
            hasMore={bottomHasPage}
            loadMore={() => bottomloadMore()}
          >
            <div className="flex flex-col my-4 gap-4 max-h-[471px] overflow-y-scroll">
              {bottonProd?.pages.map((item: any) => {
                return item.data.content.map((botItem: any) => {
                  return (
                    <div
                      onClick={() => {
                        navigate("/staff/casting-call", {
                          state: {
                            prodTitle: botItem.title,
                            prodId: botItem.id,
                          },
                        });
                      }}
                      className="flex gap-4 items-center cursor-pointer"
                      key={botItem.id}
                    >
                      <img
                        className="w-12 h-12 rounded-[10px] object-cover"
                        src={
                          botItem.thumbnailUrl
                            ? `${process.env.REACT_APP_MEDIA_URL}${botItem.thumbnailUrl}`
                            : Thumnail
                        }
                        alt=""
                      />
                      <div>{botItem.title}</div>
                    </div>
                  );
                });
              })}
            </div>
          </InfiniteScroll>

          <div className="max-w-[400px] bg-Gray00 w-full fixed bottom-0 left-2/4 -translate-x-2/4 mt-5 pb-8 px-5">
            <Button
              onClick={() => {
                navigate("/staff/casting-call");
              }}
              text={"작품 없이 공고 등록하기"}
              className="w-full text-Gray09 bg-white border border-Gray03 MBody16"
            ></Button>
          </div>
        </BottomPopup>
      )} */}
      <ContentWrap className="py-[70px] pb-24">
        <div className="flex items-center">
          {tab.map((item, i) => {
            return (
              <div
                className={`flex-1 text-center cursor-pointer pb-4 BBody14 ${
                  tabId === item.id
                    ? "border-b-2 border-[#171717] text-Gray09"
                    : "border-b border-Gray03 text-Gray05"
                }`}
                onClick={() => {
                  setTabId(item.id);
                  setCastingCallOption(null);
                  setProductOption(null);
                  setAccordionOpenId(null);
                }}
                key={item.id}
              >
                {item.title}
              </div>
            );
          })}
        </div>
        {tabId === 0 ? (
          <div className="pt-4">
            <InfiniteScroll
              threshold={100}
              hasMore={hasNextPage}
              loadMore={() => loadMore()}
            >
              <div className="flex flex-col gap-4">
                {prod?.pages.map((item: any, i) => {
                  return item.data.content.map((prodItem: any) => {
                    // 작품
                    return (
                      <div key={prodItem?.productionId}>
                        <div
                          onClick={() => {
                            if (prodItem.productionId === accordionOpenId) {
                              setAccordionOpenId(null);
                              setProductId(0);
                            } else {
                              setProductId(prodItem.productionId);
                              setAccordionOpenId(prodItem.productionId);
                            }
                          }}
                          className="flex cursor-pointer items-center gap-4 w-full relative"
                        >
                          <img
                            className="w-12 h-12 rounded-[10px] object-cover"
                            src={
                              prodItem.thumbnailUrl !== null
                                ? `${process.env.REACT_APP_MEDIA_URL}${prodItem.thumbnailUrl}`
                                : Thumnail
                            }
                            alt=""
                          />
                          <div>
                            <div className="MCaption12 text-Gray05">
                              {prodItem.format.label}
                            </div>
                            <div className="BBody16 text-Gray09 max-w-[269px] text-ellipsis overflow-hidden whitespace-nowrap">
                              {prodItem.title}
                            </div>
                          </div>
                          <Kebab
                            onClick={(e) => {
                              e.stopPropagation();
                              if (productOption === prodItem.productionId) {
                                setProductOption(null);
                                setCastingCallOption(null);
                              } else {
                                setCastingCallOption(null);

                                setProductOption(prodItem.productionId);
                              }
                            }}
                            className="absolute right-0 top-4 cursor-pointer"
                          ></Kebab>
                          {productOption === prodItem.productionId && (
                            <OptionSelectBox
                              onClose={() => setProductOption(null)}
                            >
                              {optionItem.map((opItem) => {
                                // 작품 옵션박스
                                return (
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      opItem.action(
                                        prodItem?.productionId,
                                        prodItem.title
                                      );
                                    }}
                                    className="hover:bg-Gray03 rounded-[10px] p-1"
                                    key={opItem.id}
                                  >
                                    {opItem.title}
                                  </div>
                                );
                              })}
                            </OptionSelectBox>
                          )}
                        </div>

                        {prodItem.productionId === accordionOpenId && (
                          <div className="max-h-[500px] h-full overflow-y-auto">
                            <InfiniteScroll
                              threshold={10}
                              // useWindow={false}
                              hasMore={productWithCastingcallHasPage}
                              loadMore={() => productWithCastingsloadMore()}
                            >
                              <div className={`flex flex-col `}>
                                {productCastingCallList?.length !== 0 ? (
                                  <>
                                    {productWidthCastingCallData?.pages.map(
                                      (item: any, i) => {
                                        return item.data.content.map(
                                          (prodWithCastingCallItem: any) => {
                                            // 작품을 클릭해서 열먼 나오는 공고
                                            return (
                                              <div
                                                onClick={() => {
                                                  navigate(
                                                    `/staff/casting/process/${prodWithCastingCallItem.castingCallId}`,
                                                    {
                                                      state: {
                                                        title:
                                                          prodWithCastingCallItem.title,
                                                        castingCallId:
                                                          prodWithCastingCallItem.castingCallId,
                                                      },
                                                    }
                                                  );
                                                }}
                                                key={
                                                  prodWithCastingCallItem?.castingCallId
                                                }
                                                className="relative bg-Gray01 cursor-pointer first:mt-4 mt-0 py-2 px-5 first:border-t border-Gray03"
                                              >
                                                <div className="text-Gray09 BBody16 mb-2 max-w-[333px] text-ellipsis overflow-hidden whitespace-nowrap">
                                                  {
                                                    prodWithCastingCallItem.title
                                                  }
                                                </div>
                                                <div className="text-Gray05 RBody14">
                                                  {prodWithCastingCallItem.deadlineDate
                                                    ? prodWithCastingCallItem.deadlineDate
                                                    : "캐스팅 확정 시 마감"}
                                                </div>
                                                <Kebab
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (
                                                      productOption ===
                                                      prodWithCastingCallItem.castingCallId
                                                    ) {
                                                      setCastingCallOption(
                                                        null
                                                      );
                                                      setProductOption(null);
                                                    } else {
                                                      setProductOption(null);

                                                      setCastingCallOption(
                                                        prodWithCastingCallItem.castingCallId
                                                      );
                                                    }
                                                  }}
                                                  className="absolute right-0 top-2 cursor-pointer"
                                                ></Kebab>
                                                {castingCallOption ===
                                                  prodWithCastingCallItem.castingCallId && (
                                                  <OptionSelectBox
                                                    onClose={() =>
                                                      setCastingCallOption(null)
                                                    }
                                                  >
                                                    {castingCallItem.map(
                                                      (opItem) => {
                                                        // 공고 옵션박스
                                                        return (
                                                          <div
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                              opItem.action(
                                                                prodWithCastingCallItem.castingCallId,
                                                                prodItem.title
                                                              );
                                                            }}
                                                            className="hover:bg-Gray03 rounded-[10px] p-1"
                                                            key={opItem.id}
                                                          >
                                                            {opItem.title}
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </OptionSelectBox>
                                                )}
                                              </div>
                                            );
                                          }
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <div className="RBody16 bg-Gray01 mt-4 py-2 px-5 border-t border-Gray03">
                                    캐스팅 중인 공고가 없어요.
                                  </div>
                                )}
                              </div>
                            </InfiniteScroll>
                          </div>
                        )}
                      </div>
                    );
                  });
                })}
              </div>
            </InfiniteScroll>
          </div>
        ) : (
          <InfiniteScroll
            threshold={100}
            hasMore={castingHasPage}
            loadMore={() => castingsloadMore()}
          >
            <div className="flex flex-col gap-4 pt-4">
              {castings?.pages?.map((item) => {
                // 작품 미분류
                return item.data.content.map((CastingsItem: any) => {
                  return (
                    <div
                      onClick={() => {
                        navigate(
                          `/staff/casting/process/${CastingsItem.castingCallId}`,
                          {
                            state: {
                              title: CastingsItem.title,
                              castingCallId: CastingsItem.castingCallId,
                            },
                          }
                        );
                      }}
                      key={CastingsItem.castingCallId}
                      className="relative cursor-pointer"
                    >
                      <div className="BBody16 text-Gray09 mb-2 flex-1 pr-[34px] whitespace-nowrap text-ellipsis overflow-hidden">
                        {CastingsItem.title}
                      </div>
                      <div className="RBody14 text-Gray05">
                        {CastingsItem.deadlineDate} 마감
                      </div>
                      <Kebab
                        onClick={(e) => {
                          e.stopPropagation();
                          if (productOption === CastingsItem.castingCallId) {
                            setCastingCallOption(null);
                            setProductOption(null);
                          } else {
                            setProductOption(null);

                            setCastingCallOption(CastingsItem.castingCallId);
                          }
                        }}
                        className="absolute right-0 top-0 cursor-pointer"
                      ></Kebab>
                      {castingCallOption === CastingsItem.castingCallId && (
                        <OptionSelectBox
                          onClose={() => setCastingCallOption(null)}
                        >
                          {castingCallItem.map((opItem) => {
                            return (
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  opItem.action(item.castingCallId);
                                }}
                                className="hover:bg-Gray03 rounded-[10px] p-1"
                                key={opItem.id}
                              >
                                {opItem.title}
                              </div>
                            );
                          })}
                        </OptionSelectBox>
                      )}
                    </div>
                  );
                });
              })}
            </div>
          </InfiniteScroll>
        )}
      </ContentWrap>
      {tabId === 1 && (
        <div className="max-w-[400px] bg-Gray00 w-full fixed z-50 bottom-0 left-2/4 -translate-x-2/4 mt-5 pb-8 px-5">
          <Button
            onClick={() => {
              // if (productList?.length === 0) {
              navigate("/staff/casting-call");
              // } else {
              // setSelectProduct(true);
              // }
            }}
            text={"작품 없이 공고 등록하기"}
            className="w-full text-Gray09 bg-white border border-Gray03 MBody16"
          ></Button>
        </div>
      )}
    </div>
  );
};

export default StaffMyCasting;
