import {
  useFetchMyCastingCallList,
  useMyCastingCalls,
  useMyProductionCastingCalls,
  useProductionCastingCalls,
  useProductions,
  useProductionsQuery,
} from "api/Mypage/Mypage";
import ContentWrap from "components/ContentWrap";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Thumnail from "assets/thumnail.png";

import Button from "components/Button";
import Textarea from "components/Textarea";
import { useCreateCastingOfferMutation } from "api/CastingOffer/CastingOffer";
import { Toast } from "hooks/useToast";
import DateInput from "components/DateInput";
import Checkbox from "components/Checkbox";
import Input from "components/Input";
import InfiniteScroll from "react-infinite-scroller";

const SuggestProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [accordionOpenId, setAccordionOpenId] = useState<number | null>(null);
  const [profileInfo, setProfileInfo] = useState(location?.state);
  const [productId, setProductId] = useState<number | null>(null);
  const [selectedCastingCall, setSelectedCastingCall] = useState<number | null>(
    null
  );
  const [deadlineDate, setDeadlineDate] = useState<null | string>("");
  const [selectedCastingCallType, setSelectedCastingCallType] = useState(0);
  const [sugDesc, setSugDesc] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const suggestCastingCallType = [
    { id: 0, title: "작품별" },
    { id: 1, title: "작품미분류" },
  ];

  const step = location?.hash.split("=")[1];

  const { data: prod, fetchNextPage, hasNextPage, refetch } = useProductions();
  const loadMore = useCallback(() => {
    return setTimeout(() => {
      fetchNextPage();
    }, 50);
  }, []);

  const {
    data: castings,
    fetchNextPage: castingsNextPage,
    hasNextPage: castingHasPage,
  } = useMyCastingCalls({ isWithProduction: false });
  const castingsloadMore = useCallback(() => {
    return setTimeout(() => {
      castingsNextPage();
    }, 50);
  }, []);

  const {
    data: productWidthCastingCallData,
    fetchNextPage: productWithCastingcallNextPage,
    hasNextPage: productWithCastingcallHasPage,
  } = useMyProductionCastingCalls({ id: productId! });
  const productWithCastingsloadMore = useCallback(() => {
    return setTimeout(() => {
      productWithCastingcallNextPage();
    }, 50);
  }, []);

  useEffect(() => {
    if (profileInfo === null) {
      window.location.href = "/artist";
    }
  }, [profileInfo]);

  const mutation = useCreateCastingOfferMutation();

  const suggest = () => {
    mutation.mutate(
      {
        artistId: profileInfo.memberId,
        castingCallId: selectedCastingCall!,
        description: sugDesc!,
        deadlineDate: deadlineDate!,
      },
      {
        onSuccess: () => {
          Toast.success(`아티스트에게 제안을 보냈어요.`);
          navigate(`/artist/${profileInfo.artistId}`, { replace: true });
        },
        onError: () => {
          Toast.error("에러가 발생했어요");
        },
      }
    );
  };

  const isDateValid = (date: string) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(date);
  };

  return (
    <ContentWrap className="py-[70px] pb-24">
      <HeaderWithBackButton title="" />
      {step === "1" && (
        <div>
          <div className="BHead24 mb-4">제안할 캐스팅을 선택해주세요</div>
          <div>
            <div className="w-full flex bg-Gray03 items-center border-Gray03 border rounded-[10px]">
              {suggestCastingCallType.map((item, i) => {
                return (
                  <div
                    key={item.id}
                    onClick={() => {
                      setSelectedCastingCallType(item.id);
                    }}
                    className={`flex-1 text-center BBody14 cursor-pointer rounded-[10px] py-2 ${
                      selectedCastingCallType === item.id
                        ? "text-Gray09 bg-Gray00"
                        : "text-Gray05"
                    }`}
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
            <div className="pt-4">
              {selectedCastingCallType === 0 ? (
                <InfiniteScroll
                  threshold={100}
                  hasMore={hasNextPage}
                  loadMore={() => loadMore()}
                >
                  <div className="flex flex-col gap-4">
                    {prod?.pages[0]?.data.content.length !== 0 ? (
                      <>
                        {prod?.pages.map((item: any, i) => {
                          return item.data.content.map((prodItem: any) => {
                            // 작품
                            return (
                              <div key={prodItem.productionId}>
                                <div
                                  onClick={() => {
                                    if (
                                      prodItem.productionId === accordionOpenId
                                    ) {
                                      setAccordionOpenId(null);
                                      setProductId(0);
                                    } else {
                                      setProductId(prodItem.productionId);
                                      setAccordionOpenId(prodItem.productionId);
                                    }
                                  }}
                                  className="flex cursor-pointer items-center gap-4 w-full relative pb-2"
                                >
                                  <img
                                    className="w-12 h-12 rounded"
                                    src={
                                      prodItem.thumbnailUrl
                                        ? `${process.env.REACT_APP_MEDIA_URL}${prodItem.thumbnailUrl}`
                                        : Thumnail
                                    }
                                    alt=""
                                  />
                                  <div>
                                    <div className="text-Gray05 MCaption12">
                                      {prodItem.format.label}
                                    </div>
                                    <div className="MBody18 text-Gray09 max-w-[223px] text-ellipsis overflow-hidden whitespace-nowrap">
                                      {prodItem.title}
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  {prodItem.productionId ===
                                    accordionOpenId && (
                                    <div className="max-h-[500px] h-full overflow-y-auto">
                                      <InfiniteScroll
                                        threshold={10}
                                        useWindow={false}
                                        hasMore={productWithCastingcallHasPage}
                                        loadMore={() =>
                                          productWithCastingsloadMore()
                                        }
                                      >
                                        <div className={`flex flex-col`}>
                                          {productWidthCastingCallData?.pages[0]
                                            .data.content?.length !== 0 ? (
                                            <>
                                              {productWidthCastingCallData?.pages.map(
                                                (castingCallItem: any, i) => {
                                                  return castingCallItem.data.content.map(
                                                    (
                                                      prodWithCastingCallItem: any
                                                    ) => {
                                                      // 작품을 클릭해서 열먼 나오는 공고
                                                      if (
                                                        prodWithCastingCallItem
                                                          ?.status.code ===
                                                        "ACTIVE"
                                                      )
                                                        return (
                                                          <div
                                                            key={
                                                              prodWithCastingCallItem?.castingCallId
                                                            }
                                                            onClick={() => {
                                                              setSelectedCastingCall(
                                                                prodWithCastingCallItem?.castingCallId
                                                              );
                                                              navigate(
                                                                "#step=2"
                                                              );
                                                            }}
                                                            className="cursor-pointer bg-Gray01 py-2 px-5 first:border-t border-Gray03"
                                                          >
                                                            <div className="text-Gray09  BBody16 mb-2 max-w-[333px] text-ellipsis overflow-hidden whitespace-nowrap">
                                                              {
                                                                prodWithCastingCallItem.title
                                                              }
                                                            </div>
                                                            <div className="text-Gray05 RBody14">
                                                              {prodWithCastingCallItem.deadlineDate
                                                                ? prodWithCastingCallItem.deadlineDate
                                                                : "캐스팅 확정 시 마감"}
                                                            </div>
                                                          </div>
                                                        );
                                                    }
                                                  );
                                                }
                                              )}
                                            </>
                                          ) : (
                                            <div className="items-center text-center">
                                              <div className="text-Gray05 RBody14">
                                                캐스팅 중인 공고가 없어요.
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </InfiniteScroll>
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          });
                        })}
                      </>
                    ) : (
                      <div className="items-center text-center">
                        <div className="text-Gray05 RBody14">
                          제안할 공고가 없어요.
                        </div>
                      </div>
                    )}
                  </div>
                </InfiniteScroll>
              ) : (
                <InfiniteScroll
                  threshold={100}
                  hasMore={castingHasPage}
                  loadMore={() => castingsloadMore()}
                >
                  <div className="flex flex-col gap-4">
                    {castings?.pages[0].data.content !== 0 ? (
                      <>
                        {castings?.pages?.map((item) => {
                          // 작품 미분류
                          return item.data.content.map((CastingsItem: any) => {
                            return (
                              <div
                                key={CastingsItem.castingCallId}
                                onClick={() => {
                                  setSelectedCastingCall(
                                    CastingsItem?.castingCallId
                                  );
                                  navigate("#step=2");
                                }}
                                className="cursor-pointer"
                              >
                                <div className="BBody16">
                                  {CastingsItem.title}
                                </div>
                                <div className="RBody14 text-Gray05">
                                  {CastingsItem.deadlineDate
                                    ? CastingsItem.deadlineDate
                                    : "캐스팅 확정 시"}{" "}
                                  마감
                                </div>
                              </div>
                            );
                          });
                        })}
                      </>
                    ) : (
                      <div className="mt-36 items-center text-center">
                        <div className="text-Gray05 RBody14">
                          제안할 공고가 없어요.
                        </div>
                      </div>
                    )}
                  </div>
                </InfiniteScroll>
              )}
            </div>
          </div>

          <div className="max-w-[400px] bg-Gray00 w-full fixed bottom-0 left-2/4 -translate-x-2/4 mt-5 pb-8 px-5">
            <Button
              onClick={() => {
                setSelectedCastingCall(null);
                navigate("#step=2");
              }}
              text={"직접입력하기"}
              className="w-full text-Blue04 bg-Gray03 border-none"
            ></Button>
          </div>
        </div>
      )}
      {step === "2" && (
        <div>
          <div className="text-Gray09 mb-4 BHead24 whitespace-pre-wrap">
            제안할 내용을 입력해주세요
          </div>
          <Textarea
            placeholder={
              "안녕하세요 님!\n저희가 찾고 있는 이미지와 일치하여 아티킴님에게\n지금 모집중인 [작품명]의 [배역이름]역 제안드립니다!\n\n수락하신다면, 보내드린 캐스팅 공고 확인하시고 지원해주시면 감사하겠습니다 :)\n\n감사합니다."
            }
            value={sugDesc}
            onChange={(e) => {
              setSugDesc(e.target.value);
            }}
            className="w-full h-[248px]"
          ></Textarea>
          <ul className="RBody14 mt-4 text-Gray05">
            <li>
              • {profileInfo?.artistName}님이 관심 가질 수 있는 내용을
              구체적으로 전달해주시면 제안에 응할 가능성이 높아요.
            </li>
            <li>• 허위 제안일 경우 서비스 이용이 제한될 수 있어요.</li>
          </ul>
          <div className="max-w-[400px] bg-Gray00 w-full fixed bottom-0 left-2/4 -translate-x-2/4 mt-5 pb-8 px-5">
            <Button
              onClick={() => {
                navigate(`#step=4`);
              }}
              className="bg-Blue04 text-Gray00 w-full MBody16 border-none "
              text="제안하기"
            ></Button>
          </div>
        </div>
      )}
      {step === "4" && (
        <div>
          <div className="text-Gray09 BHead24 whitespace-pre-wrap">
            언제까지 유효한 제안인가요?
          </div>
          <div className="RBody14 mt-2 mb-4 text-Gray05">
            설정하신 날짜가 지나면 자동으로 취소돼요.
          </div>
          <div>
            <Input
              disabled={deadlineDate === null}
              value={deadlineDate!}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/\D/g, "");

                let formattedDate = inputValue;
                if (inputValue.length > 4) {
                  formattedDate =
                    inputValue.slice(0, 4) + "-" + inputValue.slice(4);
                }
                if (inputValue.length > 6) {
                  formattedDate =
                    inputValue.slice(0, 4) +
                    "-" +
                    inputValue.slice(4, 6) +
                    "-" +
                    inputValue.slice(6, 8);
                }

                setDeadlineDate(formattedDate);
              }}
              type={"text"}
              placeholder="YYYY-MM-DD 형식으로 입력해주세요"
            />
          </div>
          <div className="mt-4">
            <Checkbox
              onChange={(e) => {
                const checked = e.target.checked;
                if (checked) {
                  setDeadlineDate(null);
                } else {
                  setDeadlineDate("");
                }
              }}
              title={"아무때나 좋아요"}
              id="allTime"
            />
          </div>
          <div className="max-w-[400px] bg-Gray00 w-full fixed bottom-0 left-2/4 -translate-x-2/4 mt-5 pb-8 px-5">
            <Button
              onClick={() => {
                suggest();
              }}
              disabled={
                deadlineDate !== null ? !isDateValid(deadlineDate!) : false
              }
              className="bg-Blue04 text-Gray00 w-full MBody16 border-none "
              text="제안하기"
            ></Button>
          </div>
        </div>
      )}
    </ContentWrap>
  );
};

export default SuggestProduct;
