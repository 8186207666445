import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Outlet, ScrollRestoration } from "react-router-dom";
import TabBar from "./TabBar";
import requestToCastingVote from "utils/requestToCastingVote";
import Loading from "./Loading";

const Layout = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    requestToCastingVote.interceptors.request.use(
      (config) => {
        setLoading(true);
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    requestToCastingVote.interceptors.response.use(
      (response) => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
        return response;
      },
      (error) => {
        setLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);
  return (
    <>
      {loading && <Loading />}
      <Header />
      <div className="w-full min-w-[280px] max-w-[400px] px-5 mx-auto">
        <Outlet />
      </div>
      <TabBar />
      <Footer />
    </>
  );
};

export default Layout;
