import React, { useCallback, useEffect, useState } from "react";

import ContentWrap from "components/ContentWrap";
import NoticeCard from "components/NoticeCard";
import SearchBox from "components/SearchBox";
import WorkCard from "components/WorkCard";
import { useLocation, useNavigate } from "react-router-dom";
import useDebounce from "hooks/useDebounce";
import Chip from "components/Chip";
import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";
import TitleWrap from "components/TitleWrap";
import Button from "components/Button";
import SideTab from "components/SideTab";
import NoProfile from "../assets/noProfile.png";
import { useProductions } from "api/Production/Production";
import { useCastingCalls } from "api/CastingCall/CastingCall";
import { innderHeightValue } from "store/auth/atom";
import { useRecoilState } from "recoil";
import { CASTING_LIST, GENDER_LIST, TYPE_LIST } from "utils/type";
import InfiniteScroll from "react-infinite-scroller";

const CastingList = () => {
  const navigate = useNavigate();
  const [innderHeight, setInnderHeight] =
    useRecoilState<number>(innderHeightValue);

  const [format, setFormat] = useState("");
  const [role, setRole] = useState("");
  const [gender, setGender] = useState("");
  const [search, setSearch] = useState<string>("");
  const [isSideTabOpen, setIsSideTabOpen] = useState(false);

  const [filters, setFilters] = useState({
    type: "",
    format: "",
    deadlineType: "",
    role: "",
    ageType: "",
    gender: "",
  });

  const handleOpenTab = () => setIsSideTabOpen(true);
  const handleCloseTab = () => setIsSideTabOpen(false);

  const debouncedSearchText = useDebounce(search, 500);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    refetch: staffRefetch,
  } = useCastingCalls({
    title: debouncedSearchText,
    ...filters,
  });

  const loadMore = useCallback(() => {
    return setTimeout(() => {
      fetchNextPage();
    }, 200);
  }, []);

  console.log(filters)

  return (
    <ContentWrap className="pb-24">
      <SideTab isOpen={isSideTabOpen} onClose={handleCloseTab}>
        <div className="pl-2 p-4 flex items-center justify-between">
          <div
            onClick={handleCloseTab}
            className="MBody18 cursor-pointer flex items-center"
          >
            <ArrowLeft />
            <div>필터</div>
          </div>
          <div
            onClick={() => {
              setGender("");
              setFormat("");
              setRole("");
            }}
            className="BBody18 cursor-pointer text-Blue04"
          >
            초기화
          </div>
        </div>
        <div
          style={{ height: `${innderHeight - 127}px` }}
          className="pt-6 pl-4 pb-[100px] flex flex-col items-start gap-10 overflow-y-auto"
        >
          <TitleWrap title="분야">
            <div className="flex items-center flex-wrap gap-2">
              {TYPE_LIST.map((item, i) => {
                return (
                  <Chip
                    selected={item.role === format}
                    onClick={() => {
                      if (format === item.role) {
                        setFormat("");
                      } else {
                        setFormat(item.role);
                      }
                    }}
                    key={i}
                    title={item.title}
                  ></Chip>
                );
              })}
            </div>
          </TitleWrap>
          <TitleWrap title="배역">
            <div className="flex items-center flex-wrap gap-2">
              {CASTING_LIST.map((item, i) => {
                return (
                  <Chip
                    selected={item.role === role}
                    onClick={() => {
                      if (role === item.role) {
                        setRole("");
                      } else {
                        setRole(item.role);
                      }
                    }}
                    key={i}
                    title={item.title}
                  ></Chip>
                );
              })}
            </div>
          </TitleWrap>
          <TitleWrap title="성별">
            <div className="flex items-center flex-wrap gap-2">
              {GENDER_LIST.map((item, i) => {
                return (
                  <Chip
                    selected={item.role === gender}
                    onClick={() => {
                      if (gender === item.role) {
                        setGender("");
                      } else {
                        setGender(item.role);
                      }
                    }}
                    key={i}
                    title={item.title}
                  ></Chip>
                );
              })}
            </div>
          </TitleWrap>
        </div>
        <div className="px-4">
          <Button
            onClick={() => {
              setFilters({
                ...filters,
                format: format,
                gender: gender,
                role: role,
              });
              handleCloseTab();
            }}
            className="w-full border-none bg-Blue04 text-white"
            text={"필터 적용하기"}
          ></Button>
        </div>
      </SideTab>
      <div className="flex items-center pt-4 gap-4">
        <SearchBox
          className="w-[316px]"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          value={search}
          placeholder="작품 또는 캐스팅 공고를 입력해 주세요"
        />
        <div
          className="cursor-pointer min-w-7 text-Blue04 BBody16"
          onClick={handleOpenTab}
        >
          필터
        </div>
      </div>
      {data?.pages[0].data.content.length === 0 ? (
        <div className="mt-36 items-center text-center">
          <div className="text-Gray05 RBody14">모집중인 캐스팅이 없어요.</div>
        </div>
      ) : (
        <div>
          <InfiniteScroll
            threshold={100}
            hasMore={hasNextPage}
            loadMore={() => loadMore()}
          >
            <div className="flex flex-col gap-4 pt-4">
              {data?.pages?.map((item) => {
                return item.data.content.map((staffItem: any) => {
                  return (
                    <NoticeCard
                      key={staffItem.castingCallId}
                      title={staffItem.title}
                      productionTitle={staffItem.productionTitle}
                      src={staffItem.thumbnailUrl}
                      type={staffItem?.productionFormat?.label}
                      gender={staffItem.gender.label}
                      age={`${staffItem.minAge}세 ~ ${staffItem.maxAge}세`}
                      deadline={
                        staffItem?.deadlineDate !== null
                          ? `${staffItem?.deadlineDate}까지`
                          : "캐스팅 확정 시 마감"
                      }
                      onClick={() => {
                        navigate(`/casting/${staffItem.castingCallId}`);
                      }}
                    />
                  );
                });
              })}
            </div>
          </InfiniteScroll>
        </div>
      )}
    </ContentWrap>
  );
};

export default CastingList;
