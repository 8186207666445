import React, { useEffect, useState } from "react";

import ContentWrap from "components/ContentWrap";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import TitleWrap from "components/TitleWrap";
import { ReactComponent as IconPlus } from "assets/icon_plus.svg";
import Input from "components/Input";
import Textarea from "components/Textarea";
import Button from "components/Button";
import Dropdown from "components/Dropdown";
import { ProductionData } from "type/staff";
import { ReactComponent as XButton } from "assets/xbutton.svg";
import ImgUpload from "components/ImgUpload";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Toast } from "hooks/useToast";
import {
  useCreateProductionMutation,
  useProductionDetail,
} from "api/Production/Production";
import { TYPE_LIST } from "utils/type";
import { useUploadImagesMutation } from "api/Attachment/Attachment";

const CreateProduction = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const productionId = Number(searchParams.get("id"));

  const [postData, setPostData] = useState<ProductionData>({
    title: "",
    format: "",
    description: "",
    directorName: "",
    companyName: "",
    attachmentId: null,
  });

  const { data, refetch } = useProductionDetail(productionId);

  const editData = data?.data.data;

  useEffect(() => {
    if (editData)
      setPostData({
        ...postData,
        title: editData?.title,
        format: editData?.format.code,
        description: editData?.description,
        directorName: editData?.directorName,
        companyName: editData?.companyName,
        attachmentUrl: editData?.thumbnailUrl,
      });
  }, [editData]);

  const { mutate } = useCreateProductionMutation(postData);

  const { mutate: uploadImages } = useUploadImagesMutation();

  const imgUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      uploadImages(files, {
        onSuccess: (res) => {
          setPostData({
            ...postData,
            attachmentId: res?.data.data[0].attachmentId,
            attachmentUrl: res?.data.data[0].attachmentUrl,
          });
        },
        onError: (err) => {
          Toast.error("에러가 발생했어요");
        },
      });
    }
  };

  const valid = (postData: ProductionData) => {
    if (!postData.title) return "작품제목은 필수입니다.";
    if (!postData.format) return "분야를 선택해주세요.";
    if (!postData.description) return "작품설명을 입력해주세요.";
    return null;
  };

  return (
    <ContentWrap className="pb-24">
      <HeaderWithBackButton title={`작품등록`} />
      <div className="pt-20 flex flex-col gap-10">
        <TitleWrap title={"대표사진 업로드"}>
          {postData.attachmentUrl ? (
            <div className="relative">
              <img
                className="w-full h-[450px] rounded-[10px] object-cover"
                src={`${process.env.REACT_APP_MEDIA_URL}${postData?.attachmentUrl}`}
                alt="ProfileImg"
              />
              <XButton
                onClick={() => {
                  setPostData({
                    ...postData,
                    attachmentId: 0,
                    attachmentUrl: "",
                  });
                }}
                className="absolute cursor-pointer top-4 right-4"
              />
            </div>
          ) : (
            <ImgUpload
              onChange={(e) => {
                imgUpload(e);
              }}
              id={"Prod_profile"}
            >
              <div className="w-full h-[450px] MBody16 flex flex-col justify-center text-center text-Blue04 border border-[#D9D9D9] border-dashed">
                <div>대표사진 업로드</div>
                <IconPlus className="mx-auto mt-4" />
              </div>
            </ImgUpload>
          )}
        </TitleWrap>
        <TitleWrap require title={"작품제목"}>
          <Input
            value={postData?.title}
            onChange={(e) => {
              setPostData({ ...postData, title: e.target.value });
            }}
            type={"text"}
            placeholder="작품제목을 입력해 주세요"
          />
        </TitleWrap>
        <TitleWrap require title={"분야"}>
          <Dropdown
            innerText={
              postData.format === "MOVIE"
                ? "영화"
                : postData.format === "TV_SERIES"
                ? "드라마"
                : postData.format === "VARIETY_SHOW"
                ? "예능"
                : postData.format === "MUSIC_VIDEO"
                ? "뮤직비디오"
                : postData.format === "WEB_SERIES"
                ? "웹드라마"
                : postData.format === "SHORT_FORM"
                ? "숏폼"
                : postData.format === "INDEPENDENT_FILM"
                ? "독립영화"
                : postData.format === "ADVERTISEMENT"
                ? "광고"
                : postData.format === "SHORT_FILM"
                ? "단편영화"
                : postData.format === "THEATER"
                ? "연극"
                : postData.format === "MUSICAL"
                ? "뮤지컬"
                : postData.format === "DOCUMENTARY"
                ? "다큐멘터리"
                : "선택"
            }
            width="w-full"
          >
            <div>
              {TYPE_LIST.map((item) => {
                return (
                  <li
                    onClick={() => {
                      setPostData({ ...postData, format: item.role });
                    }}
                    key={item.id}
                  >
                    {item.title}
                  </li>
                );
              })}
            </div>
          </Dropdown>
        </TitleWrap>
        <div className="flex items-center gap-4">
          <TitleWrap title={"감독"}>
            <Input
              value={postData?.directorName}
              onChange={(e) => {
                setPostData({ ...postData, directorName: e.target.value });
              }}
              type={"text"}
              placeholder="감독을 입력해 주세요"
            />
          </TitleWrap>
          <TitleWrap title={"제작사"}>
            <Input
              value={postData?.companyName}
              onChange={(e) => {
                setPostData({ ...postData, companyName: e.target.value });
              }}
              type={"text"}
              placeholder="제작사를 입력해 주세요"
            />
          </TitleWrap>
        </div>
        <TitleWrap require title={"작품설명"}>
          <Textarea
            value={postData?.description}
            onChange={(e) => {
              setPostData({ ...postData, description: e.target.value });
            }}
            className="w-full h-[210px]"
            placeholder="작품설명을 입력해 주세요"
          />
        </TitleWrap>
      </div>
      <div className="max-w-[400px] bg-Gray00 w-full fixed bottom-0 left-2/4 -translate-x-2/4 mt-5 pb-8 px-5">
        <Button
          onClick={() => {
            const validError = valid(postData);
            if (validError !== null) {
              Toast.error(validError);
            } else {
              mutate(postData, {
                onSuccess: (data) => {
                  if (data.data.code === "200") {
                    Toast.success("작품이 등록되었어요");
                    navigate("/staff/casting/my", { replace: true });
                  }
                },
                onError: (error) => {
                  Toast.error("에러가 발생했어요");
                },
              });
            }
          }}
          className="w-full h-12 bg-Blue04 text-white border-none"
          text={`${productionId ? "수정" : "등록"}하기`}
        ></Button>
      </div>
    </ContentWrap>
  );
};

export default CreateProduction;
