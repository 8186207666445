import { AxiosError, AxiosResponse } from "axios";
import { useInfiniteQuery, useQuery, UseQueryResult } from "react-query";
import {
  APIResponse,
  CastingCallDetailResponse,
  CastingCallListsResponse,
} from "type/artist";
import { CastingCallResponse, FetchCastingCallsParams } from "type/myPage";
import {
  CastingCallsResponse,
  myCastingCallByProduct,
  MyPageData,
  MyProductionDetail,
  MyProductions,
  ProductionsResponse,
} from "type/staff";
import requestToCastingVote from "utils/requestToCastingVote";

// 내 프로필
const getMyPage = () => {
  return requestToCastingVote({
    method: "GET",
    url: "/mypage/profile",
  });
};
export const useMyPage = (): UseQueryResult<AxiosResponse<MyPageData>, Error> =>
  useQuery(["useMyPage"], () => getMyPage());

// 스태프 내 작품 목록
// 무한스크롤 => 아래 지우기 fetchProductions
const fetchProductions = (): Promise<AxiosResponse<ProductionsResponse>> => {
  return requestToCastingVote.get("mypage/productions");
};
export const useProductionsQuery = (): UseQueryResult<
  AxiosResponse<ProductionsResponse>,
  AxiosError
> => {
  return useQuery("myProducts", fetchProductions);
};

const fetchProductionList = ({ status, page, size }: MyProductions) => {
  return requestToCastingVote({
    method: "GET",
    url: "/mypage/productions",
    params: {
      status,
      page,
      size,
    },
  }).then((res) => res.data);
};
export const useProductions = (params?: MyProductions) => {
  return useInfiniteQuery(
    ["myProductions", params],
    ({ pageParam = 0 }) =>
      fetchProductionList({ ...params, page: pageParam, size: 20 }),
    {
      getNextPageParam: (lastPage, allPosts) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      refetchOnWindowFocus: false,
    }
  );
};

// 스태프 내 작품 상세
const fetchProductionDetail = (
  id: number
): Promise<AxiosResponse<MyProductionDetail>> => {
  return requestToCastingVote.get(`/mypage/productions/${id}`);
};

export const useProductionDetailQuery = (
  id: number
): UseQueryResult<AxiosResponse<MyProductionDetail>, AxiosError> => {
  return useQuery(["productionDetail", id], () => fetchProductionDetail(id), {
    enabled: !!id, // id가 있을 때만 요청 수행
  });
};

// 스태프 내 공고 목록
const fetchMyCastingCallList = async (params: FetchCastingCallsParams) => {
  const { data } = await requestToCastingVote.get<CastingCallResponse>(
    "/mypage/castingcalls",
    { params }
  );
  return data.data;
};
export const useFetchMyCastingCallList = (params: FetchCastingCallsParams) => {
  return useQuery(["myCastingCalls", params], () =>
    fetchMyCastingCallList(params)
  );
};

const fetchMyCastingCalls = (param: FetchCastingCallsParams) => {
  return requestToCastingVote({
    method: "GET",
    url: "/mypage/castingcalls",
    params: param,
  }).then((res) => res.data);
};

export const useMyCastingCalls = (param: FetchCastingCallsParams) => {
  return useInfiniteQuery(
    ["myCastingCalls", param],
    ({ pageParam = 0 }) =>
      fetchMyCastingCalls({ page: pageParam, ...param, size: 24 }),
    {
      getNextPageParam: (lastPage, allPosts) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      refetchOnWindowFocus: false,
    }
  );
};

// 스태프 내 공고 상세
const fetchMyCastingCallDetail = (id: number) => {
  return requestToCastingVote.get<CastingCallDetailResponse>(
    `mypage/castingcalls/${id}`
  );
};
export const useMyCastingCallDetail = (
  id: number
): UseQueryResult<
  AxiosResponse<APIResponse<CastingCallDetailResponse>>,
  Error
> => {
  return useQuery(
    ["castingCallDetail", id],
    () => fetchMyCastingCallDetail(id),
    {
      enabled: !!id,
    }
  );
};

// 스태프 내 작품별 공고 목록
// 무한스크롤 지우기 fetchProductionCastingCalls
const fetchProductionCastingCalls = (id: number) => {
  return requestToCastingVote.get<CastingCallsResponse>(
    `mypage/productions/${id}/castingcalls`
  );
};
export const useProductionCastingCalls = (id: number) => {
  return useQuery(
    ["myCastingCallByProduct", id],
    () => fetchProductionCastingCalls(id),
    {
      enabled: !!id,
    }
  );
};

const fetchMyProductionCastingCalls = (params: myCastingCallByProduct) => {
  return requestToCastingVote({
    method: "GET",
    url: `mypage/productions/${params.id}/castingcalls`,
    params,
  }).then((res) => res.data);
};

export const useMyProductionCastingCalls = (params: myCastingCallByProduct) => {
  return useInfiniteQuery(
    ["myCastingCallByProduct", params],
    ({ pageParam = 0 }) =>
      fetchMyProductionCastingCalls({ page: pageParam, size: 8, ...params }),
    {
      getNextPageParam: (lastPage, allPosts) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      refetchOnWindowFocus: false,
      enabled: !!params?.id,
    }
  );
};
