import Button from "components/Button";
import Checkbox from "components/Checkbox";
import ContentWrap from "components/ContentWrap";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import PointCharge from "components/PointCharge";
import Radio from "components/Radio";
import React, { useState } from "react";

const CacheCharge = () => {
  const [payType, setPayType] = useState<string>("");
  const [selectedPoint, setSelectedPoint] = useState<string | number>("");
  const [agreeCharge, setAgreeCharge] = useState<boolean>(false);
  const pointType = [
    { id: 0, price: "10000" },
    { id: 1, price: "20000" },
    { id: 2, price: "30000" },
    { id: 3, price: "50000" },
  ];

  const payCheck = selectedPoint !== "" && agreeCharge === true;

  return (
    <ContentWrap className="py-[70px] pb-24">
      <HeaderWithBackButton title={"포인트"} />
      <div>
        <div className="flex items-center gap-2">
          <div className="text-Gray05 MBody16">내 포인트</div>
          <div className="text-Blue04 BBody18">999,999원</div>
        </div>
        <div className="flex flex-col gap-2 mt-4 mb-10">
          {pointType.map((item) => {
            return (
              <PointCharge
                onClick={() => {
                  if (selectedPoint === item.price) {
                    setSelectedPoint("");
                  } else {
                    setSelectedPoint(item.price);
                  }
                }}
                selected={selectedPoint === item.price}
                key={item.id}
                price={item.price}
              />
            );
          })}
        </div>
        <div className="mb-10">
          <div className="text-Gray05 MBody16">결제수단</div>
          <div className="flex items-center mt-4 gap-2">
            <div className="py-2.5 border rounded-[10px] px-4 hover:bg-Blue01 cursor-pointer">
              토스페이
            </div>
            <div className="py-2.5 border rounded-[10px] px-4 hover:bg-Blue01 cursor-pointer">
              카드결제
            </div>
          </div>
        </div>
      </div>
      <div>
        <Checkbox
          onChange={(e) => {
            setAgreeCharge(e.target.checked);
          }}
          title={
            "상품, 가격, 할인 정보, 유의 사항 등을 확인하였으며 구매에 동의합니다. (필수)"
          }
        ></Checkbox>
      </div>
      <div className="max-w-[400px] bg-Gray00 w-full fixed bottom-0 left-2/4 -translate-x-2/4 mt-5 pb-8 px-5">
        <Button
          disabled={!payCheck}
          className="px-3 mt-2 bg-Blue04 !border-none text-Gray00 w-full"
          text={`${
            selectedPoint !== ""
              ? Number(selectedPoint).toLocaleString("ko-KR")
              : ""
          }포인트 충전하기`}
        />
      </div>
    </ContentWrap>
  );
};

export default CacheCharge;
