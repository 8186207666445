import React from "react";
import { ReactComponent as ArrowRight } from "assets/arrowRight.svg";

interface MainWrapType {
  title: string;
  more: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

const MainWrap = ({
  className,
  title,
  children,
  more,
  onClick,
}: MainWrapType) => {
  return (
    <div className={`mt-10 ${className}`}>
      <div className="flex items-center justify-between">
        <div className="BBody18">{title}</div>
        {more && (
          <div
            onClick={onClick}
            className="RBody14 gap-1 flex items-center cursor-pointer"
          >
            <div>더보기</div>
            <ArrowRight />
          </div>
        )}
      </div>
      <div className="pt-4 w-full">{children}</div>
    </div>
  );
};

export default MainWrap;
