import React, { useCallback, useState } from "react";

import {
  useCastingsByCastingCalls,
  useFetchCastingByCallId,
  useShortlistCasting,
  useUpdateCastingStatus,
} from "api/CastingStaff/CastingStaff";
import ContentWrap from "components/ContentWrap";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as SelectCastingCall } from "assets/selecCastingCall.svg";
import ReceivedProfileCard from "components/ReceivedProfileCard";
import BottomButtonPopup from "components/BottomButtonPopup";
import { Toast } from "hooks/useToast";
import BottomPopup from "components/BottomPopup";
import Button from "components/Button";
import { useReceivedProfiles } from "api/DeliveryProfile/DeliveryProfile";
import InfiniteScroll from "react-infinite-scroller";
import requestToCastingVote from "utils/requestToCastingVote";

const CastingProcess = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [tabInfo, setTabInfo] = useState({
    id: 0,
    role: "APPLIED",
  });
  const [selectedList, setSelectedList] = useState<number[]>([]);
  const [selectMode, setSelectMode] = useState(false);

  const [selectType, setSelectType] = useState(false);

  const [selectedType, setSelecedtType] = useState("APPLIED");

  const [pageData, setPageData] = useState<{
    castingCallId: null | number;
    status: string;
    page: number;
    size: number;
  }>({
    castingCallId: Number(id),
    status: "APPLIED",
    page: 0,
    size: 20,
  });

  const {
    data,
    fetchNextPage: listNextPage,
    hasNextPage: listHasNextPage,
    refetch,
  } = useCastingsByCastingCalls({
    castingCallId: pageData.castingCallId,
    status: pageData.status,
  });
  const ListLoadMore = useCallback(() => {
    return setTimeout(() => {
      listNextPage();
    }, 200);
  }, []);

  const {
    data: received,
    fetchNextPage,
    hasNextPage,
    refetch: receivedRefetch,
  } = useReceivedProfiles(Number(id));
  const loadMore = useCallback(() => {
    return setTimeout(() => {
      fetchNextPage();
    }, 200);
  }, []);

  const mutate = useUpdateCastingStatus({
    castingCallId: state.castingCallId,
    status: selectedType,
    castingIds: selectedList,
  });

  const { mutate: APPLIED_PROFILE } = useShortlistCasting();

  const tab = [
    { id: 0, title: "지원", role: "APPLIED" },
    { id: 1, title: "후보 리스트", role: "SHORTLIST_SELECTED" },
    { id: 2, title: "후보 확정", role: "FINALIST_SELECTED" },
    { id: 3, title: "캐스팅 확정", role: "PASSED" },
  ];

  const type = [
    { id: 0, title: "지원 프로필", role: "APPLIED" },
    { id: 1, title: "후보 리스트", role: "SHORTLIST_SELECTED" },
    { id: 2, title: "후보 확정", role: "FINALIST_SELECTED" },
    { id: 3, title: "캐스팅 확정", role: "PASSED" },
  ];

  const handleDownload = async () => {
    try {
      // 서버에서 엑셀 파일을 다운로드 받기 위한 GET 요청
      const response = await requestToCastingVote.get(
        `/castingcalls/${state?.castingCallId}/castings/excel?status=${selectedType}`,
        {
          responseType: "blob", // 응답을 blob 형태로 받기
        }
      );

      // 다운로드할 파일의 URL을 생성
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // 가짜 링크를 만들어서 다운로드 트리거
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "test.xlsx"); // 기본 파일명 설정
      document.body.appendChild(link);
      link.click(); // 링크 클릭 이벤트 트리거
      document.body.removeChild(link); // 다운로드 후 링크 삭제
    } catch (error) {
      console.error("엑셀 파일 다운로드 중 오류가 발생했습니다.", error);
    }
  };

  const handleShortlist = () => {
    if (tabInfo.role === "APPLIED") {
      const data = {
        castingCallId: Number(id),
        deliveryProfileIds: selectedList,
      };
      APPLIED_PROFILE(data, {
        onSuccess: (res) => {
          Toast.success("프로필을 이동했어요.");
          setSelectMode(false);
          setSelectType(false);
          setSelecedtType(tabInfo.role);
          setSelectedList([]);
          receivedRefetch();
        },
        onError: (err) => {
          Toast.error("에러가 발생했어요.");
        },
      });
    } else {
      const data = {
        castingCallId: state.castingCallId,
        status: selectedType,
        castingIds: selectedList,
      };
      mutate.mutate(data, {
        onSuccess: () => {
          Toast.success("프로필을 이동했어요.");
          setSelectMode(false);
          setSelectType(false);
          setSelecedtType(tabInfo.role);
          setSelectedList([]);
          refetch();
        },
        onError: (err) => {
          Toast.error("에러가 발생했어요.");
        },
      });
    }
  };

  return (
    <ContentWrap className="py-[70px] pb-24">
      <HeaderWithBackButton title={state.title} />
      {!selectMode && (
        <div className="flex items-center">
          {tab.map((item) => {
            return (
              <div
                className={`flex-1 text-center cursor-pointer pb-4 BBody14 ${
                  tabInfo.id === item.id
                    ? "border-b-2 border-[#171717] text-Gray09"
                    : "border-b border-Gray03 text-Gray05"
                }`}
                onClick={() => {
                  setTabInfo({ ...tabInfo, id: item.id, role: item.role });
                  setSelecedtType(item.role);
                  setPageData({
                    ...pageData,
                    status: item.role,
                  });
                }}
                key={item.id}
              >
                {item.title}
              </div>
            );
          })}
        </div>
      )}
      <div>
        {tabInfo.role === "APPLIED" ? (
          <>
            <div className="py-4">
              {received?.pages[0].data.content.length !== 0 && (
                <div
                  onClick={() => {
                    setSelectMode((selectMode) => !selectMode);
                    if (selectMode) {
                      setSelectedList([]);
                    }
                  }}
                  className="cursor-pointer text-right text-Blue04 BBody14"
                >
                  {selectMode ? "취소" : "캐스팅단계이동"}
                </div>
              )}
            </div>
            {received?.pages[0].data.content.length === 0 ? (
              <div className="mt-36 items-center text-center">
                <div className="text-Gray05 RBody14">프로필이 없어요.</div>
              </div>
            ) : (
              <InfiniteScroll
                threshold={100}
                hasMore={hasNextPage}
                loadMore={() => loadMore()}
              >
                <div className="grid grid-cols-3 gap-4">
                  {received?.pages.map((item: any) => {
                    return item.data.content.map((receivedItem: any) => {
                      return (
                        <ReceivedProfileCard
                          onClick={() => {
                            if (selectMode) {
                              if (
                                selectedList.includes(
                                  receivedItem.deliveryProfileId
                                )
                              ) {
                                setSelectedList(
                                  selectedList.filter(
                                    (id) =>
                                      id !== receivedItem.deliveryProfileId
                                  )
                                );
                              } else {
                                setSelectedList([
                                  ...selectedList,
                                  receivedItem.deliveryProfileId,
                                ]);
                              }
                            } else {
                              navigate(
                                `/staff/feedback/${receivedItem.deliveryProfileId}`
                              );
                            }
                          }}
                          selected={selectedList.includes(
                            receivedItem.deliveryProfileId
                          )}
                          selectMode={selectMode}
                          key={receivedItem?.deliveryProfileId}
                          src={receivedItem.thumbnailUrl}
                          name={receivedItem.profileName}
                          isOffered={receivedItem.isOffered}
                        />
                      );
                    });
                  })}
                </div>
              </InfiniteScroll>
            )}
          </>
        ) : (
          <>
            <div className="py-4">
              <div className="flex items-center justify-between">
                {data?.pages[0].data.content.length !== 0 &&
                <div
                onClick={() => {
                  handleDownload();
                }}
                className="text-Blue04 cursor-pointer BBody14"
                >
                  엑셀 다운로드(임시)
                </div>
                }
                {data?.pages[0].data.content.length !== 0 &&
                  tabInfo.role !== "PASSED" && (
                    <div
                      onClick={() => {
                        setSelectMode((selectMode) => !selectMode);
                        if (selectMode) {
                          setSelectedList([]);
                        }
                      }}
                      className="cursor-pointer text-right text-Blue04 BBody14"
                    >
                      {selectMode ? "취소" : "캐스팅단계이동"}
                    </div>
                  )}
              </div>
            </div>
            {data?.pages[0].data.content.length === 0 ? (
              <div className="mt-36 items-center text-center">
                <div className="text-Gray05 RBody14">프로필이 없어요.</div>
              </div>
            ) : (
              <InfiniteScroll
                threshold={100}
                hasMore={listHasNextPage}
                loadMore={() => ListLoadMore()}
              >
                <div className="grid grid-cols-3 gap-4">
                  {data?.pages.map((item) => {
                    return item.data.content.map((listItem: any) => {
                      return (
                        <ReceivedProfileCard
                          key={listItem?.castingId}
                          onClick={() => {
                            if (selectMode) {
                              if (selectedList.includes(listItem.castingId)) {
                                setSelectedList(
                                  selectedList.filter(
                                    (id) => id !== listItem.castingId
                                  )
                                );
                              } else {
                                setSelectedList([
                                  ...selectedList,
                                  listItem.castingId,
                                ]);
                              }
                            } else {
                              navigate(`/staff/feedback/${listItem.profileId}`);
                            }
                          }}
                          selected={selectedList.includes(listItem.castingId)}
                          birth={listItem.birthDate}
                          selectMode={selectMode}
                          src={listItem?.thumbnailUrl}
                          name={listItem.profileName}
                        />
                      );
                    });
                  })}
                </div>
              </InfiniteScroll>
            )}
          </>
        )}
      </div>
      {selectType && (
        <BottomPopup
          onClose={() => {
            setSelecedtType(tabInfo.role);
            setSelectType(false);
          }}
        >
          <div className="BBody20 text-Gray09 ">캐스팅 단계 선택</div>
          <div className="flex flex-col gap-2 mt-5 mb-4 overflow-y-auto">
            {type
              .filter((typeItem) => {
                if (tabInfo.role === "APPLIED") {
                  return typeItem.id === 1;
                } else if (tabInfo.role === "SHORTLIST_SELECTED") {
                  return typeItem.id > 1;
                } else if (tabInfo.role === "FINALIST_SELECTED") {
                  return typeItem.id > 2;
                }
              })
              .map((typeItem) => (
                <div
                  onClick={() => {
                    setSelecedtType(typeItem.role);
                  }}
                  key={typeItem.id}
                  className={`px-4 py-3 cursor-pointer box-border MBody16 rounded-[10px] ${
                    selectedType === typeItem.role
                      ? "bg-Blue01 border border-Blue04"
                      : "bg-Gray01"
                  }`}
                >
                  {typeItem.title}
                </div>
              ))}
          </div>
          {selectedType === "PASSED" && (
            <div className="RBody14">
              캐스팅 확정 하면 해당 공고가 마감되고
              <br />
              프로필 피드백이 불가능해요
            </div>
          )}

          <div className="max-w-[400px] flex items-center gap-2 bg-Gray00 w-full fixed bottom-0 left-2/4 -translate-x-2/4 mt-5 pb-8 px-5">
            <Button
              onClick={() => {
                setSelectType(false);
                setSelecedtType(tabInfo.role);
              }}
              text={"닫기"}
              className="w-full text-Gray05 flex-1 bg-Gray03 "
            />
            <Button
              onClick={() => {
                handleShortlist();
              }}
              text={"확정하기"}
              className="w-full bg-Blue04 flex-1 text-Gray00 MBody16"
            />
          </div>
        </BottomPopup>
      )}
      {selectMode && (
        <BottomButtonPopup
          onClose={() => {
            setSelectMode(false);
          }}
        >
          <div
            onClick={() => {
              if (selectedList.length !== 0) {
                setSelectType(true);
              } else {
                Toast.error("프로필을 선택해주세요");
              }
            }}
            className="flex flex-col pt-2 justify-center items-center MBody14 cursor-pointer text-Gray00"
          >
            <SelectCastingCall />

            <div>캐스팅 단계 이동</div>
          </div>
        </BottomButtonPopup>
      )}
    </ContentWrap>
  );
};

export default CastingProcess;
