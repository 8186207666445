import React from "react";

interface CheckBoxType {
  title: string;
  className?: string;
  onClick?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  id?: string;
}

const Checkbox = ({
  title,
  id,
  className,
  onChange,
  onClick,
  checked,
}: CheckBoxType) => {
  return (
    <div className="flex items-center">
      <input
        id={id}
        type="checkbox"
        value=""
        checked={checked}
        onChange={onChange}
        className="appearance-none min-w-[18px] min-h-[18px] bg-check checked:bg-checked bg-no-repeat bg-center"
      />
      <label
        onClick={onClick}
        htmlFor={id}
        className={`nonDragable flex-1 RBody14 ms-2 ${className}`}
      >
        <div className={`${checked ? "text-Gray09" : "text-Gray05"}`}>
          {title}
        </div>
      </label>
    </div>
  );
};

export default Checkbox;
