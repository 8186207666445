import { AxiosResponse } from "axios";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { CastingByCallId, CastingCallByCasting, ShortlistCastingRequest, ShortlistCastingResponse, UpdateCastingStatusRequest, UpdateCastingStatusResponse } from "type/castingStaff";
import requestToCastingVote from "utils/requestToCastingVote";

// 캐스팅 후보 선정
const shortlistCasting = (data: ShortlistCastingRequest): Promise<AxiosResponse<ShortlistCastingResponse>> => {
  return requestToCastingVote.patch('/castings/shortlist', data);
};
export const useShortlistCasting = () => {
  return useMutation<AxiosResponse<ShortlistCastingResponse>, Error, ShortlistCastingRequest>(
    shortlistCasting
  );
};
// 캐스팅 최종 후보 선정
const selectFinalistCasting = (castingCallId: number, castingIds: number[]) => {
  return requestToCastingVote.patch("/castings/finalist", {
    castingCallId,
    castingIds,
  });
};
export const useSelectFinalistCasting = () => {
  return useMutation(
    (finalistData: { castingCallId: number; castingIds: number[] }) =>
      selectFinalistCasting(finalistData.castingCallId, finalistData.castingIds)
  );
};

// 캐스팅 오디션 제안
const proposeCastingAudition = (
  castingCallId: number,
  castingIds: number[],
  auditionDetails: string
) => {
  return requestToCastingVote.patch("/castings/audition", {
    castingCallId,
    castingIds,
    auditionDetails,
  });
};
export const useProposeCastingAudition = () => {
  return useMutation(
    (auditionData: {
      castingCallId: number;
      castingIds: number[];
      auditionDetails: string;
    }) =>
      proposeCastingAudition(
        auditionData.castingCallId,
        auditionData.castingIds,
        auditionData.auditionDetails
      )
  );
};

// 캐스팅 최종 합격
const passCastingFinalists = (castingCallId: number, castingIds: number[]) => {
  return requestToCastingVote.patch("/castings/pass", {
    castingCallId,
    castingIds,
  });
};
export const usePassCastingFinalists = () => {
  return useMutation(
    (finalistData: { castingCallId: number; castingIds: number[] }) =>
      passCastingFinalists(finalistData.castingCallId, finalistData.castingIds)
  );
};

//   캐스팅 불합격
const failCastingCandidates = (castingCallId: number, castingIds: number[]) => {
  return requestToCastingVote.patch("/castings/fail", {
    castingCallId,
    castingIds,
  });
};
export const useFailCastingCandidates = () => {
  return useMutation(
    (failData: { castingCallId: number; castingIds: number[] }) =>
      failCastingCandidates(failData.castingCallId, failData.castingIds)
  );
};

// 공고별 캐스팅 조회
// SHORTLIST_SELECTED(후보 선정), FINALIST_SELECTED(최종 후보 선정), AUDITION_OFFERED(오디션 제안), OFFER_PROPOSED(협의 제안), OFFER_ACCEPTED(협의 수락), OFFER_REJECTED(협의 거절), PASSED(최종 합격), FAILED(불합격)
const fetchCastingByCallId = (
  castingCallId: number | null,
  status: string,
  page: number = 0,
  size: number = 10
): Promise<AxiosResponse<CastingByCallId>> => {
  return requestToCastingVote.get(`/castingcalls/${castingCallId}/castings`, {
    params: {
      status,
      page,
      size,
    },
  });
};
export const useFetchCastingByCallId = (
  castingCallId: number | null,
  status: string,
  page: number = 0,
  size: number = 10
) => {
  return useQuery(
    ["castingByProduct", castingCallId, status, page, size],
    () => fetchCastingByCallId(castingCallId, status, page, size),
    {
      enabled: !!castingCallId, // castingCallId가 존재할 때만 호출
    }
  );
};

const fetchCastingsByCastingCalls = (params?:CastingCallByCasting) => {
  return requestToCastingVote({
    method:"GET",
    url:`/castingcalls/${params?.castingCallId}/castings`,
    params
  })
  .then((res) => res.data);
}
export const useCastingsByCastingCalls = (params:CastingCallByCasting) => {
  return useInfiniteQuery(
    ["castingByCastingcall", params],
    ({pageParam = 0}) => fetchCastingsByCastingCalls({...params, page:pageParam, size:20}),
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      refetchOnWindowFocus: false,
      enabled: !!params?.castingCallId, 
    }
  )
} 

// 캐스팅 상태 변경
const updateCastingStatus = async (data: UpdateCastingStatusRequest): Promise<AxiosResponse<UpdateCastingStatusResponse>> => {
  return requestToCastingVote.patch("/castings/status", data);
};

// React Query를 사용한 훅
export const useUpdateCastingStatus = (updateCastingData: { castingCallId: null | number; status: string; castingIds: number[]; }) => {
  return useMutation<AxiosResponse<UpdateCastingStatusResponse>, Error, UpdateCastingStatusRequest>(
    updateCastingStatus
  );
};

