import React from "react";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";
import Thumnail from "assets/thumnail.png";

interface StaffWorkCardType {
  onClick?: () => void;
  title: string;
  src: string;
}

const StaffWorkCard = ({ onClick, title, src }: StaffWorkCardType) => {
  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
  return (
    <div className="relative">
      <img
        onClick={onClick}
        src={src !== null ? `${MEDIA_URL}${src}` : Thumnail}
        className="w-[172px] h-[211px] rounded-[10px] object-cover cursor-pointer"
        alt="staffWordCard"
      />
      <div className="bg-Blue01 left-4 top-4 absolute MCaption10 text-Blue04 px-2 py-1 rounded-[5px]">
        캐스팅
      </div>
      <Bookmark className="absolute right-4 bottom-10 cursor-pointer" />
      <div className="BBody16 mt-2 text-Gray09 text-ellipsis overflow-hidden whitespace-nowrap">
        {title}
      </div>
    </div>
  );
};

export default StaffWorkCard;
