import { Toast } from "hooks/useToast";
import requestToCastingVote from "./requestToCastingVote";

export const onLogOut = () => {
  requestToCastingVote
    .post("auth/logout")
    .then((res) => {
      if (res.status === 200) {
        Toast.error("세션이 만료되었습니다 다시 로그인 해주세요.")
        localStorage.clear();
        
        window.location.replace("/login");
        return;
      }
    })
    .catch((err) => {
      localStorage.removeItem("user");
    });
};
