import React, { useEffect, useState } from "react";

import MainWrap from "components/MainWrap";
import ArtistCard from "components/ArtistCard";
import { useLocation, useNavigate } from "react-router-dom";

import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

import FAQ from "assets/FAQ.png";

import { Autoplay } from "swiper/modules";
import ContentWrap from "components/ContentWrap";
import CastingCard from "components/CastingCard";
import { useMainCastingList, useMainHotList, useMainNewList } from "api/Main";
import { MainCasting, MainCommon } from "type/common";
import { useRecoilState } from "recoil";
import { innderHeightValue } from "store/auth/atom";
import YouTube from "react-youtube";

const Main = () => {
  const navigate = useNavigate();
  const [innderHeight, setInnderHeight] =
    useRecoilState<number>(innderHeightValue);

  const [mainCastingType, setMainCastingType] = useState<MainCasting>({
    status: "",
    page: 0,
    size: 20,
  });
  const [mainHotType, setMainHotType] = useState<MainCommon>({
    page: 0,
    size: 20,
  });
  const [mainNewType, setMainNewType] = useState<MainCommon>({
    page: 0,
    size: 20,
  });

  const opt = {
    height: "171",
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
    },
  };

  const { data: castingList } = useMainCastingList(mainCastingType);
  const { data: hotList } = useMainHotList(mainHotType);
  const { data: newList } = useMainNewList(mainNewType);

  return (
    <ContentWrap className="pb-24">
      <Swiper
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        loop={true}
        modules={[Autoplay]}
        slidesPerView={"auto"}
      >
        <SwiperSlide className="w-fit">
          <img
            onClick={() => {
              window.open(
                "https://daffy-periwinkle-38f.notion.site/FAQ-1344e358e81b80f9bbd3d7c0a76f3398",
                "_blank",
                "noopener, noreferrer"
              );
            }}
            className="mt-4 cursor-pointer rounded-[10px]"
            src={FAQ}
            alt=""
          ></img>
        </SwiperSlide>
      </Swiper>
      <MainWrap
        className="relative"
        onClick={() => {
          navigate("/casting");
        }}
        more={true}
        title="🎥 진행중인 캐스팅"
      >
        <Swiper
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          loop={true}
          modules={[Autoplay]}
          slidesPerView={
            castingList?.data.data.content.length! < 4 ? 3 : "auto"
          }
          spaceBetween={16}
        >
          {castingList?.data.data.content.map((item, i) => {
            if (item.productionThumbnailUrl !== null)
              return (
                <SwiperSlide
                  className={
                    castingList?.data.data.content.length! < 3
                      ? "w-full"
                      : "!w-fit"
                  }
                  key={item.castingCallId}
                >
                  <div className="w-full">
                    <CastingCard
                      onClick={() => {
                        navigate(`/casting/${item.castingCallId}`);
                      }}
                      title={item?.title}
                      src={item?.productionThumbnailUrl}
                      type={item?.productionFormat?.label}
                    />
                  </div>
                </SwiperSlide>
              );
          })}
        </Swiper>
      </MainWrap>
      <MainWrap className="relative" more={false} title="👑 캐스팅보트 Pick">
        <Swiper
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          loop={true}
          modules={[Autoplay]}
          slidesPerView={hotList?.data.data.content.length! < 4 ? 3 : "auto"}
          spaceBetween={16}
        >
          {hotList?.data.data.content.map((item, i) => {
            return (
              <SwiperSlide
                className={
                  hotList?.data.data.content.length! < 3 ? "w-full" : "!w-fit"
                }
                key={i}
              >
                <div className="w-full">
                  <ArtistCard
                    src={item.thumbnailUrl}
                    // onClick={() => {
                    //   navigate(`/artist/${item.deliveryProfileId}`);
                    // }}
                    artistName={item.profileName}
                    artistJop={item.job.label}
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </MainWrap>
      <MainWrap
        className="relative"
        title="🌟 새로 올라온 프로필"
        onClick={() => {
          navigate("/artist");
        }}
        more
      >
        <Swiper
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          loop={true}
          modules={[Autoplay]}
          slidesPerView={newList?.data.data.content.length! < 4 ? 3 : "auto"}
          spaceBetween={16}
        >
          {newList?.data.data.content.map((item, i) => {
            return (
              <SwiperSlide
                className={
                  newList?.data.data.content.length! < 3 ? "w-full" : "!w-fit"
                }
                key={item.profileId}
              >
                <div className="w-full">
                  <ArtistCard
                    src={item.thumbnailUrl}
                    onClick={() => {
                      navigate(`/artist/${item.profileId}`);
                    }}
                    artistName={item.profileName}
                    artistJop={item.job.label}
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </MainWrap>
      {/* <MainWrap more={false} title="🔥 캐스팅보트 핫클립">
        <YouTube
          className={"w-full"}
          opts={opt}
          iframeClassName="youtubeRounded"
          videoId="QLXEu3SVbh8"
        />
      </MainWrap> */}
    </ContentWrap>
  );
};

export default Main;
