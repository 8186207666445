import React, { useCallback, useEffect, useState } from "react";

import SearchBox from "components/SearchBox";
import { useNavigate } from "react-router-dom";
import ProfileInfoCard from "components/ProfileInfoCard";
import ContentWrap from "components/ContentWrap";
import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";
import useDebounce from "hooks/useDebounce";
import { ArtistList as ArtistListType } from "type/artist";
import Chip from "components/Chip";
import SideTab from "components/SideTab";
import TitleWrap from "components/TitleWrap";
import Button from "components/Button";
import { useArtists } from "api/Profile/Profile";
import { innderHeightValue } from "store/auth/atom";
import { useRecoilState } from "recoil";
import { GENDER_LIST, HOBBY_LIST, JOB_LIST, KEYWORD_LIST } from "utils/type";
import InfiniteScroll from "react-infinite-scroller";

const ArtistList = () => {
  const navigate = useNavigate();
  const [isSideTabOpen, setIsSideTabOpen] = useState(false);
  const [search, setSearch] = useState<string>("");
  const debouncedSearchText = useDebounce(search, 500);

  const [job, setJop] = useState("");
  const [gender, setGender] = useState("");
  const [hashtagIds, setHashtagIds] = useState<number[]>([]);
  const [hobbyIds, setHobbyIds] = useState<number[]>([]);
  const [innderHeight, setInnderHeight] =
    useRecoilState<number>(innderHeightValue);

  const [artistData, setArtistData] = useState<ArtistListType>({
    gender: "",
    job: "",
    ageGoe: "",
    ageLoe: "",
    heightGoe: "",
    heightLoe: "",
    weightGoe: "",
    weightLoe: "",
    hashtagIds: "",
    hobbyIds: "",
    page: 0,
    size: 20,
  });

  const handleOpenTab = () => setIsSideTabOpen(true);
  const handleCloseTab = () => setIsSideTabOpen(false);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    refetch: artistsRefetch,
  } = useArtists({
    name: debouncedSearchText,
    ...artistData,
  });

  const loadMore = useCallback(() => {
    return setTimeout(() => {
      fetchNextPage();
    }, 200);
  }, []);

  const hobby: { id: number; title: string }[] = [
    { id: 15, title: "액션" },
    { id: 16, title: "승마" },
    { id: 17, title: "영어" },
    { id: 18, title: "태권도" },
    { id: 19, title: "중국어" },
    { id: 20, title: "검도" },
    { id: 21, title: "달리기" },
    { id: 22, title: "일본어" },
    { id: 23, title: "한국무용" },
    { id: 24, title: "발레" },
    { id: 25, title: "수영" },
    { id: 26, title: "불어" },
  ];

  return (
    <ContentWrap className="pb-24">
      <SideTab isOpen={isSideTabOpen} onClose={handleCloseTab}>
        <div className="pl-2 p-4 flex items-center justify-between">
          <div
            onClick={handleCloseTab}
            className="MBody18 cursor-pointer flex items-center"
          >
            <ArrowLeft />
            <div></div>
          </div>
          <div
            onClick={() => {
              setArtistData({
                ...artistData,
                hashtagIds: "",
                hobbyIds: "",
                gender: "",
                job: "",
              });
              setJop("");
              setGender("");
              setHashtagIds([]);
              setHobbyIds([]);
            }}
            className="BBody18 cursor-pointer text-Blue04"
          >
            초기화
          </div>
        </div>
        <div
          style={{ height: `${innderHeight - 127}px` }}
          className="pt-6 pl-4 pb-[100px] flex flex-col items-start gap-10 overflow-y-auto"
        >
          <TitleWrap title="성별">
            <div className="flex items-center flex-wrap gap-2">
              {GENDER_LIST.map((item, i) => {
                return (
                  <Chip
                    selected={gender === item.role}
                    onClick={() => {
                      if (artistData.gender === item.role) {
                        setGender("");
                      } else {
                        setGender(item.role);
                      }
                    }}
                    key={item.id}
                    title={item.title}
                  ></Chip>
                );
              })}
            </div>
          </TitleWrap>
          <TitleWrap title="직업">
            <div className="flex items-center flex-wrap gap-2">
              {JOB_LIST.map((item, i) => {
                return (
                  <Chip
                    selected={job === item.role}
                    onClick={() => {
                      if (artistData.job === item.role) {
                        setJop("");
                      } else {
                        setJop(item.role);
                      }
                    }}
                    key={item.id}
                    title={item.title}
                  ></Chip>
                );
              })}
            </div>
          </TitleWrap>
          {/* <TitleWrap title="키워드" subTitle="중복선택가능">
            <div className="flex items-center flex-wrap gap-2">
              {KEYWORD_LIST.map((item, i) => {
                return (
                  <Chip
                    selected={hashtagIds.includes(item.id)}
                    onClick={() => {
                      if (hashtagIds.includes(item.id)) {
                        setHashtagIds(hashtagIds.filter((i) => i !== item.id));
                      } else {
                        setHashtagIds((pre) => [...pre, item.id]);
                      }
                    }}
                    key={item.id}
                    title={item.title}
                  ></Chip>
                );
              })}
            </div>
          </TitleWrap> */}
          <TitleWrap title="특기" subTitle="중복선택가능">
            <div className="flex items-center flex-wrap gap-2">
              {HOBBY_LIST.map((item, i) => {
                return (
                  <Chip
                    selected={hobbyIds.includes(item.id)}
                    onClick={() => {
                      if (hobbyIds.includes(item.id)) {
                        setHobbyIds(hobbyIds.filter((i) => i !== item.id));
                      } else {
                        setHobbyIds((pre) => [...pre, item.id]);
                      }
                    }}
                    key={item.id}
                    title={item.title}
                  ></Chip>
                );
              })}
            </div>
          </TitleWrap>
          {/* <TitleWrap title="나이" subTitle="중복선택가능">
            <RangeInput fixedMinPrice={10} fixedMaxPrice={60} priceGap={10} />
          </TitleWrap>
          <TitleWrap title="몸무게" subTitle="중복선택가능">
            progrssBar
          </TitleWrap>
          <TitleWrap title="키" subTitle="중복선택가능">
            progrssBar
          </TitleWrap> */}
        </div>
        <div className="px-4">
          <Button
            onClick={() => {
              setArtistData({
                ...artistData,
                hashtagIds: hashtagIds.join(","),
                hobbyIds: hobbyIds.join(","),
                gender: gender,
                job: job,
              });
              setIsSideTabOpen(false);
            }}
            className="w-full border-none bg-Blue04 text-white"
            text={"필터 적용하기"}
          ></Button>
        </div>
      </SideTab>

      <div className="flex items-center pt-4 gap-4">
        <SearchBox
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="아티스트 이름을 입력해 주세요"
        />
        <div
          className="cursor-pointer min-w-[28px] text-Blue04 BBody16"
          onClick={handleOpenTab}
        >
          필터
        </div>
      </div>
      {data?.pages[0].data.content.length === 0 ? (
        <div className="mt-36 items-center text-center">
          <div className="text-Gray05 RBody14">등록된 프로필이 없어요.</div>
        </div>
      ) : (
        <div>
      <InfiniteScroll
        threshold={100}
        hasMore={hasNextPage}
        loadMore={() => loadMore()}
      >
        <div className="pt-4 grid grid-cols-3 gap-4">
          {data?.pages.map((item) => {
            return item.data.content.map((aritstItem: any) => {
              return (
                <ProfileInfoCard
                  key={aritstItem?.profileId}
                  onClick={() => {
                    navigate(`${aritstItem.profileId}`);
                  }}
                  src={aritstItem?.thumbnailUrl}
                  name={aritstItem.profileName}
                  info={aritstItem.birthDate}
                  size={`${aritstItem.height}cm ${aritstItem.weight}kg`}
                  />
                );
              });
            })}
          </div>
        </InfiniteScroll>
      </div>
    )}
    </ContentWrap>
  );
};

export default ArtistList;
