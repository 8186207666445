import React, { useEffect, useState } from "react";
import { Route, Router, Routes } from "react-router-dom";
import Main from "./pages/Main";
import Layout from "./components/Layout";
import Login from "pages/Login";
import Signup from "pages/Signup";

import ArtistList from "pages/ArtistList";
import ArtistDetail from "pages/ArtistDetail";
import StaffList from "pages/StaffList";
import StaffDetail from "pages/StaffDetail";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "react-datepicker/dist/react-datepicker.css";

import CastingList from "pages/CastingList";
import CastingDetail from "pages/CastingDetail";
import StaffFilmoDetail from "pages/StaffFilmoDetail";
import CreateArtistProfile from "pages/CreateArtistProfile";
import CreateStaffProfile from "pages/CreateStaffProfile";

import { authInfo, info, loginCheck } from "store/auth/atom";
import { useRecoilState } from "recoil";
import { innderHeightValue } from "store/auth/atom";
import StaffMy from "pages/StaffMy";
import ArtistMy from "pages/ArtistMy";
import Mypage from "pages/Mypage";
import CreateCastingCall from "pages/CreateCastingCall";
import CreateProduction from "pages/CreateProduction";
import StaffMyCasting from "pages/StaffMyCasting";
import { onLogOut } from "utils/onLogOut";
import StaffMyCastingCall from "pages/StaffMyCastingCall";
import PointsDetails from "pages/PointsDetails";
import CacheCharge from "pages/CacheCharge";
import ReceivedProfile from "pages/ReceivedProfile";
import { useAuthInfo, useLoginCheck } from "api/auth";
import SuggestProduct from "pages/SuggestProduct";
import ArtistReceiveSuggest from "pages/ArtistReceiveSuggest";
import UpdateArtistProfile from "pages/UpdateArtistProfile";
import ArtistSendProfile from "pages/ArtistSendProfile";
import StaffMyFilmo from "pages/StaffMyFilmo";
import MyCasting from "pages/MyCasting";
import CastingProcess from "pages/CastingProcess";
import ArtistFeedback from "pages/ArtistFeedback";
import KmcCallback from "pages/KmcCallback";
import UpdateStaffProfile from "pages/UpdateStaffProfile";
import UpdateCastingCall from "pages/UpdateCastingCall";
import UpdateProduction from "pages/UpdateProduction";

function App() {
  const [innderHeight, setInnderHeight] =
    useRecoilState<number>(innderHeightValue);
  const [login, setLogin] = useRecoilState(loginCheck);
  const updatedUserInfo = localStorage.getItem("user");
  let userInfo = updatedUserInfo ? JSON.parse(updatedUserInfo) : null;
  const [auth, setAuth] = useRecoilState(authInfo);
  const [userData, setUserData] = useRecoilState<{
    id: string;
    username: string;
    role: string;
  }>(info);

  const { data } = useLoginCheck(!!userInfo?.id);
  const { data: authData } = useAuthInfo(!!userInfo?.id);

  useEffect(() => {
    if (data) {
      if (data.data.message === "OK") {
        const info = data.data.data;
        const id = String(info?.memberId);
        const userInfo = {
          id: id,
          username: info.username,
          role: info.role,
        };
        const userJson = JSON.stringify(userInfo);
        setUserData(userInfo);
        localStorage.clear();
        localStorage.setItem("user", userJson);
        setLogin(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (authData) {
      setAuth(authData?.data.data!);
      setLogin(true);
    }
  }, [authData]);

  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
  useEffect(() => {
    setScreenSize();
  });

  // useEffect(() => {
  //   requestToCastingVote({
  //     method: "get",
  //     url: "/dev/members/s2/approved",
  //   });
  // }, []);

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Main />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/kmc/callback" element={<KmcCallback />} />

        <Route path="/my" element={<Mypage />} />

        <Route path="/artist" element={<ArtistList />} />
        <Route path="/artist/:artistId" element={<ArtistDetail />} />
        <Route path="/artist/profile" element={<CreateArtistProfile />} />
        <Route path="/artist/profile/edit" element={<UpdateArtistProfile />} />
        <Route path="/artist/my" element={<ArtistMy />} />
        <Route path="/artist/suggest" element={<ArtistReceiveSuggest />} />
        <Route path="/artist/send" element={<ArtistSendProfile />} />

        <Route path="/suggest" element={<SuggestProduct />} />

        <Route path="/point" element={<PointsDetails />} />
        <Route path="/point/charge" element={<CacheCharge />} />

        <Route path="/staff" element={<StaffList />} />
        <Route path="/staff/feedback/:artistId" element={<ArtistFeedback />} />
        <Route path="/staff/:staffId" element={<StaffDetail />} />
        <Route path="/staff/profile" element={<CreateStaffProfile />} />
        <Route path="/staff/my" element={<StaffMy />} />
        <Route path="/staff/profile/edit" element={<UpdateStaffProfile />} />
        <Route path="/staff/casting/my" element={<StaffMyCasting />} />
        <Route path="/staff/casting/process/:id" element={<CastingProcess />} />
        <Route path="/staff/post" element={<CreateProduction />} />
        <Route path="/staff/post/edit/:id" element={<UpdateProduction />} />
        <Route path="/staff/casting-call" element={<CreateCastingCall />} />
        <Route path="/staff/casting-call/edit/:id" element={<UpdateCastingCall />} />
        <Route path="/staff/casting-call/my" element={<StaffMyCastingCall />} />
        <Route path="/staff/filmo/:staffId" element={<StaffFilmoDetail />} />
        <Route path="/staff/filmo/my/:productId" element={<StaffMyFilmo />} />
        <Route path="/staff/received" element={<ReceivedProfile />} />

        <Route path="/casting" element={<CastingList />} />
        <Route path="/casting/:itemId" element={<CastingDetail />} />
        <Route path="/casting/my/:itemId" element={<MyCasting />} />
      </Route>
    </Routes>
  );
}

export default App;
