import { useImageUpload } from "api/upload";
import Button from "components/Button";
import DateInput from "components/DateInput";
import ImgUpload from "components/ImgUpload";
import Input from "components/Input";
import Radio from "components/Radio";
import TitleWrap from "components/TitleWrap";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import requestToCastingVote from "utils/requestToCastingVote";
import { ReactComponent as IconPlus } from "assets/icon_plus.svg";
import { today } from "utils/onToday";
import { encryptData } from "utils/crypto";
import { Toast } from "hooks/useToast";
import { useSignupMutation } from "api/auth";
import { STAFF_JOB_LIST } from "utils/type";

const Signup = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedType, setSelectedType] = useState("");
  const [currentBizFile, setCurrnetBizFile] = useState({
    fileName: "",
    id: 0,
  });
  const [currentStep, setCurrentStep] = useState(1);

  const [passCheck, setPassCheck] = useState("");
  const [data, setData] = useState({
    userId: "",
    password: "",
    name: "",
    phoneNumber: "",
    email: "",
    gender: "",
    birthDate: "",
    zonecode: "",
    roadAddress: "",
    jibunAddress: "",
    sido: "",
    sigungu: "",
    termsOfService: true,
    termsOfServiceAt: "",
    privacyPolicy: true,
    privacyPolicyAt: "",
    thirdPartySharing: true,
    thirdPartySharingAt: "",
    marketingOptIn: false,
    marketingOptInAt: "",
    recommendId: "",
    role: "",
    businessName: "",
    businessNumber: "",
    businessLicenseId: null,
    certNum: 0,
  });

  const preDate = location.state;

  useEffect(() => {
    const agree = JSON.parse(localStorage.getItem("agree")!);

    const birthDay = preDate?.birthDay;
    let birthDate = `${birthDay?.slice(0, 4)}-${birthDay?.slice(
      4,
      6
    )}-${birthDay?.slice(6)}`;
    setData({
      ...data,
      ...agree,
      name: preDate?.name,
      certNum: preDate?.certNum,
      birthDate: birthDate === "undefined-undefined-undefined" ? "" : birthDate,
      gender:
        preDate?.gender === "0"
          ? "MALE"
          : preDate?.gender === "1"
          ? "FEMALE"
          : "",
      phoneNumber: preDate?.phoneNo,
    });
  }, [preDate]);

  const totalStep = 3;

  const selectType = (type: string) => {
    if (selectedType === type) {
      setSelectedType("");
    } else {
      setSelectedType(type);
    }
  };

  const getDots = () => {
    const dots = [];
    for (let i = 1; i <= totalStep; i++) {
      dots.push(i <= currentStep ? "blue" : "gray");
    }
    return dots;
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = (pageNum?: number) => {
    setCurrentStep(currentStep - 1);

    if (pageNum === 2) {
      setData((pre) => ({
        ...pre,
        email: "",
        role: "",
        businessName: "",
      }));
    } else {
      setPassCheck("");
      setData((pre) => ({
        ...pre,
        userId: "",
        password: "",
      }));
    }
  };

  const signupData = {
    ...data,
    password: encryptData(data?.password),
  };

  const { mutate } = useSignupMutation(signupData);

  const fileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    const formData = new FormData();
    if (files) {
      const fileRef = files[0];
      formData.append("file", fileRef);
      requestToCastingVote({
        method: "POST",
        url: "auth/upload",
        data: formData,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
      })
        .then((res) => {
          setData({
            ...data,
            businessLicenseId: res.data.data.attachmentId,
          });
          setCurrnetBizFile({
            id: res.data.data.attachmentId,
            fileName: fileRef.name,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const passwordRegex =
    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>\/?~-]).{8,50}$/;
  const userIdMinLength = 6;
  const userIdMaxLength = 15;

  const validateInfo = () => {
    let isValid = true;

    if (!data.email) {
      Toast.error("이메일을 입력해주세요.");
      isValid = false;
    } else if (data.email.length > 50) {
      Toast.error("이메일은 최대 50자까지 입력 가능해요.");
      isValid = false;
    } else if (!emailRegex.test(data.email)) {
      Toast.error("이메일 형식을 확인해주세요.");
      isValid = false;
    } else if (!data.name) {
      Toast.error("KMC 고객센터로 문의해주세요");
      isValid = true;
    } else if (!data.birthDate) {
      Toast.error("KMC 고객센터로 문의해주세요");
      isValid = true;
    } else if (!data.gender) {
      Toast.error("KMC 고객센터로 문의해주세요");
      isValid = true;
    }

    return isValid;
  };

  const validateStaffInfo = () => {
    let isValid = true;

    if (!data.role) {
      Toast.error("직업을 선택해주세요.");
      isValid = false;
    } else if (!data.email) {
      Toast.error("이메일을 입력해주세요.");
      isValid = false;
    } else if (data.email.length > 50) {
      Toast.error("이메일은 최대 50자까지 입력 가능해요");
      isValid = false;
    } else if (!emailRegex.test(data.email)) {
      Toast.error("이메일 형식을 확인해주세요.");
      isValid = false;
    } else if (!data.birthDate) {
      Toast.error("생년월일을 입력해주세요.");
      isValid = false;
    } else if (!data.businessName) {
      Toast.error("소속회사를 입력해주세요.");
      isValid = false;
    } else if (!data.businessLicenseId) {
      Toast.error("사업자등록증을 첨부해주세요.");
      isValid = false;
    }

    return isValid;
  };

  const validateIdAndPassword = () => {
    let isValid = true;

    if (data.userId.length < userIdMinLength) {
      Toast.error(`아이디는 ${userIdMinLength}자 이상으로 입력해주세요.`);
      isValid = false;
    } else if (data.userId.length > userIdMaxLength) {
      Toast.error(`아이디는 최대 ${userIdMaxLength}자 이내로 입력해 주세요.`);
      isValid = false;
    } else if (!passwordRegex.test(data.password)) {
      Toast.error(
        "비밀번호는 숫자, 영어, 특수문자를 포함하여 8자 이상으로 입력해 주세요."
      );
      isValid = false;
    } else if (data.password !== passCheck) {
      Toast.error("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
      isValid = false;
    }

    return isValid;
  };

  const staffInfoCheck =
    data.role &&
    data.email &&
    data.email.length <= 50 &&
    emailRegex.test(data.email) &&
    data.birthDate &&
    data.businessName &&
    data.businessLicenseId;

  return (
    <div className="py-[70px] mx-auto flex flex-col max-w-[520px] justify-between gap-10">
      <div className="flex items-center gap-2">
        {getDots().map((color, i) => {
          return (
            <div
              key={i}
              className={`w-2 h-2 rounded-full ${
                color === "gray" ? "bg-[#D9D9D9]" : "bg-Blue04"
              }`}
            />
          );
        })}
        <div className="w-5 h-6">{currentStep === 3 ? "🙌" : "🏃‍♀️‍"}</div>
      </div>

      {currentStep === 1 && (
        <div className="flex flex-col">
          <div className="text-left text-2xl font-bold">
            시작하기 전에
            <br />
            직업을 선택해 주세요
          </div>
          <div className="flex flex-col gap-4 mt-10 mb-20">
            <Button
              onClick={() => {
                selectType("ARTIST");
                setData({
                  ...data,
                  role: "ARTIST",
                });
              }}
              className={`w-full border ${
                selectedType === "ARTIST"
                  ? "text-Blue04 border-Blue04"
                  : "text-Gray05 border-Gray05"
              }`}
              text={"아티스트"}
            ></Button>
            <Button
              onClick={() => {
                selectType("STAFF");
              }}
              className={`w-full border  ${
                selectedType === "STAFF"
                  ? "text-Blue04 border-Blue04"
                  : "text-Gray05 border-Gray05"
              }`}
              text={"Staff (캐스팅 디렉터, 감독, 프로듀서 등등)"}
            ></Button>
          </div>
          <Button
            onClick={() => {
              handleNextStep();
            }}
            disabled={!selectedType}
            className={`w-full bg-Blue04 text-white`}
            text={"다음"}
          ></Button>
        </div>
      )}
      {currentStep === 2 && selectedType === "ARTIST" && (
        <div className="flex flex-col">
          <div className="text-left text-2xl font-bold">
            환영해요!
            <br />
            기본 정보를 입력해 주세요
          </div>

          <div className="flex flex-col gap-10 mt-10 mb-20">
            <TitleWrap title="이메일">
              <Input
                value={data.email}
                onChange={(e) => {
                  const emailValue = e.target.value;
                  if (emailValue.length <= 50) {
                    setData({
                      ...data,
                      email: emailValue,
                    });
                  }
                }}
                className="w-full"
                placeholder="이메일을 입력해 주세요"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="이름">
              <Input
                value={data?.name}
                className="w-full"
                disabled
                placeholder="이름을 입력해 주세요"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="생년월일">
              <DateInput
                disabled
                placeholder="생년월일을 선택해 주세요"
                min="1920-01-01"
                max={today()}
                value={data.birthDate}
                className="w-full"
              />
            </TitleWrap>
            <TitleWrap title="성별">
              <Button
                className={`
                    w-full border border-Gray03 bg-Gray01 cursor-default
                    `}
                text={
                  data?.gender === "MALE"
                    ? "남성"
                    : data?.gender === "FEMALE"
                    ? "여성"
                    : ""
                }
              ></Button>
            </TitleWrap>
          </div>
          <div className="flex flex-col-reverse gap-4 w-full">
            <Button
              onClick={() => {
                handlePrevStep(2);
              }}
              className={`w-full border-none bg-[#D9D9D9] text-white`}
              text={"이전"}
            ></Button>
            <Button
              onClick={() => {
                if (validateInfo()) {
                  handleNextStep();
                }
              }}
              className={`w-full bg-Blue04 text-white`}
              text={"다음"}
            ></Button>
          </div>
        </div>
      )}
      {currentStep === 2 && selectedType === "STAFF" && (
        <div className="flex flex-col">
          <div className="text-left text-2xl font-bold">
            환영해요!
            <br />
            기본 정보를 입력해 주세요
          </div>
          <div className="flex flex-col gap-10 mt-10 mb-20">
            <TitleWrap title="직업">
              <div className="flex items-center flex-wrap gap-5">
                {STAFF_JOB_LIST.map((item, i) => {
                  return (
                    <Radio
                      checked={item.role === data.role}
                      value={item?.role}
                      onChange={(e) => {
                        setData({
                          ...data,
                          role: e.target.value,
                        });
                      }}
                      key={item.id}
                      id={item.role}
                      name={item.name}
                      title={item.title}
                    />
                  );
                })}
              </div>
            </TitleWrap>
            <TitleWrap title="이메일">
              <Input
                value={data.email}
                onChange={(e) => {
                  setData({
                    ...data,
                    email: e.target.value,
                  });
                }}
                placeholder="이메일을 입력해 주세요"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="이름">
              <Input
                value={data.name}
                disabled
                placeholder="이름을 입력해 주세요"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="생년월일">
              <DateInput
                placeholder="생년월일을 선택해 주세요"
                min="1920-01-01"
                max={today()}
                value={data.birthDate}
                disabled
                className="w-full"
              />
            </TitleWrap>
            <TitleWrap title="소속사">
              <Input
                value={data.businessName}
                onChange={(e) => {
                  setData({
                    ...data,
                    businessName: e.target.value,
                  });
                }}
                placeholder="소속회사를 입력해 주세요"
                type={"text"}
              ></Input>
            </TitleWrap>
            <TitleWrap title="사업자등록증">
              <ImgUpload
                onChange={(e) => {
                  fileUpload(e);
                }}
                id={"bizFile"}
              >
                <div className="border border-[#D9D9D9] border-dashed text-center text-Blue04 py-4 MBody16">
                  <div>사업자 등록증 업로드</div>
                  <IconPlus className="mx-auto mt-4" />
                </div>
              </ImgUpload>
              {currentBizFile && currentBizFile.fileName && (
                <div className="flex items-center justify-between">
                  <div>{currentBizFile?.fileName}</div>
                  <div
                    onClick={() => {
                      setCurrnetBizFile({
                        fileName: "",
                        id: 0,
                      });
                      setData({ ...data, businessLicenseId: null });
                    }}
                  >
                    X
                  </div>
                </div>
              )}
            </TitleWrap>
          </div>
          <div className="flex flex-col-reverse gap-4 w-full">
            <Button
              onClick={() => {
                handlePrevStep(2);
              }}
              className={`w-full border-none bg-[#D9D9D9] text-white`}
              text={"이전"}
            ></Button>
            <Button
              onClick={() => {
                if (validateStaffInfo()) {
                  handleNextStep();
                }
              }}
              className={`w-full bg-Blue04 text-white`}
              text={"다음"}
            ></Button>
          </div>
        </div>
      )}

      {currentStep === 3 && (
        <div className="flex flex-col">
          <div className="text-left text-2xl font-bold">
            아이디와 비밀번호를
            <br />
            입력해 주세요
          </div>
          <div className="flex flex-col BBody18 gap-10 mt-10 mb-20">
            <TitleWrap title={"아이디"}>
              <Input
                value={data.userId}
                onChange={(e) => {
                  const userIdValue = e.target.value;
                  if (userIdValue.length <= userIdMaxLength) {
                    setData({
                      ...data,
                      userId: userIdValue,
                    });
                  }
                }}
                className="w-[520px]"
                placeholder="아이디를 6자 이상으로 설정해 주세요"
                type={"text"}
              />
            </TitleWrap>
            <TitleWrap title={"비밀번호"}>
              <Input
                value={data.password}
                onChange={(e) => {
                  const passwordValue = e.target.value;
                  if (passwordValue.length <= 50) {
                    setData({
                      ...data,
                      password: passwordValue,
                    });
                  }
                }}
                className="w-[520px]"
                placeholder="알파벳, 숫자, 특수문자 포함 8자 이상으로 설정해 주세요"
                type={"password"}
              />
            </TitleWrap>
            <TitleWrap title={"비밀번호 확인"}>
              <Input
                onChange={(e) => {
                  setPassCheck(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    mutate(signupData, {
                      onSuccess: (data) => {
                        if (data.data.code === "200") {
                          Toast.success("회원가입 성공");
                          navigate("/login", { replace: true });
                        }
                      },
                      onError: (err) => {
                        Toast.error(err.response?.data.message);
                      },
                    });
                  }
                }}
                value={passCheck}
                className="w-[520px]"
                placeholder="동일한 비밀번호를 입력해 주세요"
                type={"password"}
              ></Input>
            </TitleWrap>
          </div>
          <div className="flex flex-col-reverse gap-4">
            <Button
              onClick={() => {
                handlePrevStep(4);
              }}
              className={`w-full border-none bg-[#D9D9D9] text-white`}
              text={"이전"}
            ></Button>
            <Button
              onClick={() => {
                if (validateIdAndPassword()) {
                  mutate(signupData, {
                    onSuccess: (data) => {
                      if (data.data.code === "200") {
                        Toast.success("회원가입을 완료했어요");
                        navigate("/login", { replace: true });
                      }
                    },
                    onError: (err) => {
                      Toast.error(err.response?.data.message);
                    },
                  });
                }
              }}
              className={`w-full bg-Blue04 text-white`}
              text={"완료"}
            ></Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signup;
