import React, { SetStateAction, useEffect, useRef, useState } from "react";

import KakaoLogin from "../assets/kakaoLogin.png";

import Button from "components/Button";
import Input from "components/Input";
import { useNavigate, useSearchParams } from "react-router-dom";
import Checkbox from "components/Checkbox";
import ContentWrap from "components/ContentWrap";
import BottomPopup from "components/BottomPopup";
import { useRecoilState } from "recoil";
import { loginCheck } from "store/auth/atom";
import { LoginCheckData } from "type/auth";
import axios, { AxiosResponse } from "axios";
import { encryptData } from "utils/crypto";
import { Toast } from "hooks/useToast";
import { useLoginMutation } from "api/auth";
import requestToCastingVote from "utils/requestToCastingVote";

const Login = () => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState<Window | null>(null);
  const reqKmcisForm = useRef<HTMLFormElement | null>(null);
  const [agreeTermModal, setAgreeTermModal] = useState(false);
  const [checkItems, setCheckItems] = useState<number[]>([]);
  const [login, setLogin] = useRecoilState(loginCheck);
  const [kmcCert, setKmcCert] = useState({
    cert: "",
    certNum: "",
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const code = Number(searchParams.get("code"));

  useEffect(() => {
    if (code === 4001) {
      Toast.error("로그인이 필요합니다.");
    }
  }, [code]);

  const [loginData, setLoginData] = useState({
    userId: "",
    password: "",
    remember: false,
  });
  const [selectAgree, setSelectAgree] = useState({
    termsOfService: false,
    termsOfServiceAt: "",
    privacyPolicy: false,
    privacyPolicyAt: "",
    thirdPartySharing: false,
    thirdPartySharingAt: "",
    marketingOptIn: false,
    marketingOptInAt: "",
  });

  const checkList = [
    { id: 0, title: "서비스 이용약관 동의 (필수)", type: "termsOfService" },
    {
      id: 1,
      title: "개인정보 수집 및 이용 동의 (필수)",
      type: "privacyPolicy",
    },
    { id: 2, title: "제 3자 정보 제공 동의 (필수)", type: "thirdPartySharing" },
    { id: 3, title: "마케팅 수신 동의 (선택)", type: "marketingOptIn" },
  ];

  const requiredValues = [0, 1, 2];

  const isFormValid = requiredValues.every((val) => checkItems.includes(val));

  const checkItemHandler = (id: number, isChecked: boolean, type: string) => {
    const formattedDate = new Date().toISOString().slice(0, 19);
    if (isChecked) {
      setCheckItems((prev) => [...prev, id]);
      setSelectAgree((pre) => ({
        ...pre,
        [type]: true,
        [`${type}At`]: formattedDate,
      }));
    } else {
      setCheckItems(checkItems.filter((item) => item !== id));
      setSelectAgree((pre) => ({ ...pre, [type]: false, [`${type}At`]: "" }));
    }
  };

  const allCheckedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedDate = new Date().toISOString().slice(0, 19);
    if (e.target.checked) {
      setCheckItems(checkList.map((item) => item.id));
      setSelectAgree((pre) => ({
        ...pre,
        termsOfService: true,
        termsOfServiceAt: formattedDate,
        privacyPolicy: true,
        privacyPolicyAt: formattedDate,
        thirdPartySharing: true,
        thirdPartySharingAt: formattedDate,
        marketingOptIn: true,
        marketingOptInAt: formattedDate,
      }));
    } else {
      setCheckItems([]);
      setSelectAgree({
        termsOfService: false,
        termsOfServiceAt: "",
        privacyPolicy: false,
        privacyPolicyAt: "",
        thirdPartySharing: false,
        thirdPartySharingAt: "",
        marketingOptIn: false,
        marketingOptInAt: "",
      });
    }
  };

  const data = {
    remember: loginData.remember,
    userId: loginData.userId.trim(),
    password: encryptData(loginData.password).trim(),
  };

  const { mutate } = useLoginMutation(data);

  const successLogin = (data: AxiosResponse<LoginCheckData>) => {
    Toast.success("로그인이 완료되었어요.");
    setLogin(true);
    const info = data.data.data;
    const id = String(info?.memberId);
    const userInfo = {
      id: id,
      username: info.username,
      role: info.role,
    };
    const userJson = JSON.stringify(userInfo);
    localStorage.clear();
    localStorage.setItem("user", userJson);
    navigate("/", { replace: true });
  };

  const OpenKMCISWindow = () => {
    const UserAgent = navigator.userAgent;
    const width = 500;
    const height = 550;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;

    const KMCIS_URL = "https://www.kmcert.com/kmcis/web/kmcisReq.jsp";
    if (reqKmcisForm.current) {
      if (
        UserAgent.match(
          /iPhone|iPad|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
        )
      ) {
        reqKmcisForm.current.target = "";
      } else {
        const kmcisPopup = window.open(
          "",
          "KMCISWindow",
          `width=${width},height=${height},left=${left},top=${top},resizable=0,scrollbars=no,status=0,titlebar=0,toolbar=0`
        );
        if (kmcisPopup === null) {
          alert("팝업이 차단되어 있습니다. 팝업을 허용해주세요.");
          return;
        }
        setPopup(kmcisPopup);
        reqKmcisForm.current.target = "KMCISWindow";
      }
      reqKmcisForm.current.action = KMCIS_URL;
      reqKmcisForm.current.submit();
    }
  };

  const handleKmcCert = async () => {
    const result = await requestToCastingVote({
      method: "GET",
      url: "kmc/cert",
    });
    const { cert, certNum } = result.data.data;
    setKmcCert({ cert, certNum });
  };

  useEffect(() => {
    if (kmcCert.cert && kmcCert.certNum) {
      OpenKMCISWindow();
    }
  }, [kmcCert]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== "https://test.castingvote.im") return;
      if (event.data.certNum) {
        navigate("/signup", { state: event.data });
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <ContentWrap className="flex flex-col h-screen gap-10">
      <form ref={reqKmcisForm} name="reqKmcisForm" method="post" action="#">
        <input type="hidden" name="tr_cert" value={kmcCert.cert} />
        <input
          type="hidden"
          name="tr_url"
          value="https://test.castingvote.im/kmc/callback"
        />
        <input type="hidden" name="tr_ver" value="V2" />
      </form>
      {agreeTermModal && (
        <BottomPopup onClose={() => setAgreeTermModal(false)}>
          <div>
            <div className="BHead24 mb-4">
              회원가입 전에
              <br />
              약관 동의가 필요해요.
            </div>
            <div className="flex flex-col gap-4">
              {checkList.map((item) => {
                return (
                  <Checkbox
                    id={item.title}
                    checked={checkItems.includes(item.id) ? true : false}
                    onChange={(e) => {
                      checkItemHandler(item.id, e.target.checked, item.type);
                    }}
                    key={item.id}
                    title={item.title}
                  />
                );
              })}
            </div>
            <hr className="w-full h-px my-4 bg-[#D9D9D9]" />
            <Checkbox
              id="전체선택"
              checked={checkItems.length === checkList.length ? true : false}
              onChange={allCheckedHandler}
              className=""
              title="전체 동의하기"
            />
            <Button
              disabled={!isFormValid}
              onClick={() => {
                const agree = selectAgree;
                const agreeJson = JSON.stringify(agree);
                localStorage.setItem("agree", agreeJson);
                handleKmcCert();
              }}
              className="w-[348px] mt-4 BBody16 text-white bg-Blue04"
              text="동의하고 계속하기"
            />
          </div>
        </BottomPopup>
      )}
      <div className="text-center BBody20 mt-10 mx-auto">
        캐스팅을 향한 빠른 길,
        <br />
        캐스팅보트를 시작하세요 🙌
      </div>
      <div className="flex flex-col gap-4">
        <Input
          onChange={(e) => {
            setLoginData({ ...loginData, userId: e.target.value });
          }}
          value={loginData.userId}
          placeholder="아이디를 입력해 주세요"
          className=" w-full"
          type="text"
        />
        <Input
          onChange={(e) => {
            setLoginData({ ...loginData, password: e.target.value });
          }}
          value={loginData.password}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              mutate(data, {
                onSuccess: (data) => {
                  successLogin(data);
                },
                onError: (err: any) => {
                  if (err?.response.data.code === "ERR4002") {
                    Toast.error("아이디 또는 패스워드를 잘못 입력했습니다.");
                  }
                },
              });
            }
          }}
          placeholder="비밀번호를 입력해 주세요"
          className=" w-full"
          type="password"
        />
        <Checkbox
          onChange={(e) => {
            setLoginData({ ...loginData, remember: e.target.checked });
          }}
          id="login"
          checked={loginData?.remember}
          className="RBody14 text-Gray05"
          title="로그인 유지"
        />
      </div>
      <div className="flex text-center flex-col gap-4 max-w-[520px]">
        <Button
          onClick={() => {
            mutate(data, {
              onSuccess: (data) => {
                successLogin(data);
              },
              onError: (err: any) => {
                if (err?.response.data.code === "ERR4002") {
                  Toast.error("아이디 또는 패스워드를 잘못 입력했습니다.");
                }
              },
            });
          }}
          className="w-full mx-auto text-Gray00 text-center bg-Blue04"
          text="로그인"
        />
        <Button
          onClick={() => {
            setCheckItems([]);
            setAgreeTermModal(true);
          }}
          className="w-full border border-Blue04 text-Blue04"
          text="회원가입"
        />
        {/* <div className="flex items-center mx-auto gap-4 RBody14 text-Gray05">
          <div>아이디 찾기</div>
          <div>|</div>
          <div>비밀번호 찾기</div>
        </div> */}
      </div>

      {/* <div className="flex flex-col gap-4 mx-auto w-full">
        <div className="flex items-center justify-between mx-auto gap-[41px]">
          <hr className="flex-1 h-px bg-[#D9D9D9]" />
          <div className="text-sm MBody14 text-Gray05">SNS 간편 로그인</div>
          <hr className="flex-1 h-px bg-[#D9D9D9]" />
        </div>
        <div className="text-sm RBody14 cursor-pointer grid gap-2 text-Gray09 items-center mx-auto">
          <img src={KakaoLogin} alt="snsLogin" />
          <div>카카오톡</div>
        </div>
      </div> */}
    </ContentWrap>
  );
};

export default Login;
