import { AxiosResponse, AxiosError } from "axios";
import { UseMutationResult, UseQueryResult, useInfiniteQuery, useMutation, useQuery } from "react-query";
import { getProduction, ProductionData, ProductionDetailResponse, ProductionQueryParams, ProductionResponse, UpdateProductionRequest, UpdateProductionResponse } from "type/staff";
import requestToCastingVote from "utils/requestToCastingVote";

// 작품 등록
const createProduction = ({
    title,
    format,
    description,
    directorName,
    companyName,
    attachmentId,
  }: ProductionData) => {
    return requestToCastingVote({
      method: "POST",
      url: "productions",
      data: {
        title,
        format,
        description,
        directorName,
        companyName,
        attachmentId,
      },
    });
  };
  export const useCreateProductionMutation = (
    data: ProductionData
  ): UseMutationResult<
    AxiosResponse<ProductionResponse>,
    AxiosError,
    ProductionData
  > => {
    const createProductionMutation = useMutation<
      AxiosResponse<ProductionResponse>,
      AxiosError,
      ProductionData
    >({
      mutationFn: createProduction,
    });
  
    return createProductionMutation;
  };

  // 작품 수정
const updateProduction = async (data: UpdateProductionRequest): Promise<AxiosResponse<UpdateProductionResponse>> => {
    return requestToCastingVote.patch('productions', data);
  };
  export const useUpdateProduction = () => {
    return useMutation<AxiosResponse<UpdateProductionResponse>, Error, UpdateProductionRequest>(
      updateProduction
    );
  };
  
  // 작품 목록 조회
const getProductions = (
    params: ProductionQueryParams
  ): Promise<AxiosResponse<getProduction>> => {
    return requestToCastingVote("productions", {
      params: {
        title: params.title,
        format: params.format,
        directorName: params.directorName,
        companyName: params.companyName,
        memberId: params?.memberId,
        page: params.page ?? 0,
        size: params.size ?? 20,
      },
    });
  };
  export const useProductions = (
    params: ProductionQueryParams
  ): UseQueryResult<AxiosResponse<getProduction>, Error> => {
    return useQuery(["products", params], () => getProductions(params), {
      enabled: !!params.memberId
    });
  };

  const getStaffProductions = (params?: ProductionQueryParams) => {
    return requestToCastingVote({
      method:"GET",
      url:"/productions",
      params
    }).then((res) => res.data);
  }

  export const useStaffProductions = (params: ProductionQueryParams) => {
    return useInfiniteQuery(
      ["products", params],
      ({ pageParam = 0 }) =>
        getStaffProductions({ page: pageParam, size: 20, ...params }),
      {
        getNextPageParam: (lastPage, allPosts) => {
          return lastPage?.data.last
            ? undefined
            : lastPage.data.pageable.pageNumber + 1;
        },
        refetchOnWindowFocus: false,
        enabled: !!params?.memberId
      }
    );
  };

  // 작품 상세 조회
const getProductionById = (
    id: number
  ): Promise<AxiosResponse<ProductionDetailResponse>> => {
    return requestToCastingVote(`productions/${id}`);
  };
  export const useProductionDetail = (
    id: number
  ): UseQueryResult<AxiosResponse<ProductionDetailResponse>, Error> => {
    return useQuery(["productionDetail", id], () => getProductionById(id), {
      enabled: !!id,
    });
  };