import React from "react";
import Chip from "./Chip";
import { ReactComponent as Check } from "assets/checkProfile.svg";
import { ReactComponent as Checked } from "assets/checkedProfile.svg";

interface ReceivedProfileCardType {
  src?: string;
  name?: string;
  birth?: number;
  selectMode?: boolean;
  selected?: boolean;
  isOffered?: boolean;
  onClick?: () => void;
}

const ReceivedProfileCard = ({
  src,
  name,
  birth,
  selectMode,
  selected,
  isOffered,
  onClick,
}: ReceivedProfileCardType) => {
  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

  return (
    <div className="relative">
      <img
        onClick={onClick}
        src={`${MEDIA_URL}${src}`}
        alt="thumnailUrl"
        className={`w-[109px] relative object-cover h-[136px] rounded-[10px] ${
          selectMode && selected ? "cursor-pointer" : ""
        }
          ${selectMode && selected ? "opacity-50" : ""}
        `}
      />
      {selectMode && selected && (
        <Checked className="absolute right-2 top-2 z-50" />
      )}
      <div className="flex items-center gap-1 mt-2 mb-1">
        <div className="BBody16 text-Gray09">{name}</div>
        {isOffered && (
          <Chip
            selected
            className="bg-Blue01 h-[18px] !MCaption10 text-Blue04 border-none rounded-md"
            title={"제안"}
          />
        )}
      </div>
      <div className="flex items-center gap-2 MBody14 text-Gray05">{birth}</div>
    </div>
  );
};

export default ReceivedProfileCard;
