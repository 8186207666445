import { AxiosResponse, AxiosError } from "axios";
import {
  UseMutationResult,
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";
import {
  APIResponse,
  CastingCallDetailResponse,
  CastingCallResponse,
} from "type/artist";
import {
  castingCalls,
  CreateCastingCallData,
  CreateCastingCallResponse,
  UpdateCastingCallRequest,
  UpdateCastingCallResponse,
} from "type/staff";
import requestToCastingVote from "utils/requestToCastingVote";

// 공고 등록
const createCastingCall = (
  data: CreateCastingCallData
): Promise<AxiosResponse<CreateCastingCallResponse>> => {
  return requestToCastingVote.post("castingcalls", data);
};
export const useCreateCastingCallMutation = (): UseMutationResult<
  AxiosResponse<CreateCastingCallResponse>,
  AxiosError,
  CreateCastingCallData
> => {
  return useMutation(createCastingCall);
};

// 공고 수정
const updateCastingCall = async (
  data: UpdateCastingCallRequest
): Promise<AxiosResponse<UpdateCastingCallResponse>> => {
  return requestToCastingVote.patch("/castingcalls", data);
};
export const useUpdateCastingCall = (): UseMutationResult<
  AxiosResponse<UpdateCastingCallResponse>,
  AxiosError,
  UpdateCastingCallRequest
> => {
  return useMutation(updateCastingCall);
};

// 공고 목록 조회
// 무한스크롤
const fetchCastingCalls = ({
  title,
  type,
  deadlineType,
  role,
  ageType,
  gender,
  page,
  format,
  size,
}: castingCalls) => {
  return requestToCastingVote({
    method: "GET",
    url: "castingcalls",
    params: {
      title,
      type,
      deadlineType,
      role,
      format,
      ageType,
      gender,
      page,
      size,
    },
  }).then((res) => res.data);
};
export const useCastingCalls = (params: castingCalls) => {
  return useInfiniteQuery(
    ["castingcalls", params],
    ({ pageParam = 0 }) =>
      fetchCastingCalls({ ...params, page: pageParam, size: 20 }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.data.last
          ? undefined
          : lastPage.data.pageable.pageNumber + 1;
      },
      refetchOnWindowFocus: false,
    }
  );
};

// 공고 상세 조회
const fetchCastingCallDetail = async (
  id: number
): Promise<AxiosResponse<APIResponse<CastingCallDetailResponse>>> => {
  return requestToCastingVote.get(`/castingcalls/${id}`);
};

export const useCastingCallDetail = (
  castingCallId: number
): UseQueryResult<
  AxiosResponse<APIResponse<CastingCallDetailResponse>>,
  Error
> =>
  useQuery(["castingCallDetail", castingCallId], () =>
    fetchCastingCallDetail(castingCallId)
  );

// 작품별 공고 목록 조회
const fetchProductionCastingCalls = (
  productionId: number,
  page: number = 0,
  size: number = 10
): Promise<AxiosResponse<AxiosResponse<CastingCallResponse>>> => {
  return requestToCastingVote.get(`/productions/${productionId}/castingcalls`, {
    params: {
      page,
      size,
    },
  });
};
export const useFetchProductionCastingCalls = (
  productionId: number,
  page: number = 0,
  size: number = 10
): UseQueryResult<AxiosResponse<AxiosResponse<CastingCallResponse>>, Error> =>
  useQuery(
    ["castingCallByProduct", productionId, page],
    () => fetchProductionCastingCalls(productionId, page, size),
    {
      enabled: !!productionId,
    }
  );
