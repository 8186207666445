import { AxiosResponse, AxiosError } from "axios";
import { useMutation, UseMutationResult } from "react-query";
import { UploadResponse } from "type/attachment";
import requestToCastingVote from "utils/requestToCastingVote";

// 첨부 파일 업로드
const uploadFiles = async (
  formData: FormData
): Promise<AxiosResponse<UploadResponse>> => {
  return requestToCastingVote({
    method: "POST",
    url: "/upload",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const useUploadFilesMutation = (): UseMutationResult<
  AxiosResponse<UploadResponse>,
  AxiosError,
  FileList
> => {
  return useMutation({
    mutationFn: async (fileList) => {
      const FILE_SIZE_LIMIT = 10 * 1024 * 1024; // 10MB
      const TOTAL_SIZE_LIMIT = 100 * 1024 * 1024; // 100MB
      let totalSize = 0;

      for (const file of Array.from(fileList)) {
        if (file.size > FILE_SIZE_LIMIT) {
          throw new Error(`파일 ${file.name}의 크기가 10MB를 초과합니다.`);
        }
        totalSize += file.size;
      }

      if (totalSize > TOTAL_SIZE_LIMIT) {
        throw new Error("총 파일 크기가 100MB를 초과합니다.");
      }

      const formData = new FormData();
      Array.from(fileList).forEach((file) => {
        formData.append("files", file);
      });

      return uploadFiles(formData);
    },
  });
};

//   사진 파일 업로드
const uploadImages = async (
  formData: FormData
): Promise<AxiosResponse<UploadResponse>> => {
  return requestToCastingVote({
    method: "POST",
    url: "/upload/image",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const useUploadImagesMutation = (): UseMutationResult<
  AxiosResponse<UploadResponse>,
  AxiosError,
  FileList
> => {
  return useMutation({
    mutationFn: async (fileList) => {
      const IMAGE_SIZE_LIMIT = 10 * 1024 * 1024;
      const TOTAL_SIZE_LIMIT = 100 * 1024 * 1024;
      let totalSize = 0;

      for (const file of Array.from(fileList)) {
        if (file.size > IMAGE_SIZE_LIMIT) {
          throw new Error(`파일 ${file.name}의 크기가 10MB를 초과합니다.`);
        }
        totalSize += file.size;
        if (totalSize > TOTAL_SIZE_LIMIT) {
          throw new Error("총 파일 크기가 100MB를 초과합니다.");
        }
      }

      const formData = new FormData();
      Array.from(fileList).forEach((file) => {
        formData.append("files", file);
      });

      return uploadImages(formData);
    },
  });
};

// 영상 파일 업로드
const uploadVideos = async (
  formData: FormData
): Promise<AxiosResponse<UploadResponse>> => {
  return requestToCastingVote({
    method: "POST",
    url: "/upload/video",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const useUploadVideosMutation = (): UseMutationResult<
  AxiosResponse<UploadResponse>,
  AxiosError,
  FileList
> => {
  return useMutation({
    mutationFn: async (fileList) => {
      const VIDEO_SIZE_LIMIT = 50 * 1024 * 1024;
      const TOTAL_SIZE_LIMIT = 100 * 1024 * 1024;
      let totalSize = 0;

      for (const file of Array.from(fileList)) {
        if (file.size > VIDEO_SIZE_LIMIT) {
          throw new Error(`영상 파일 ${file.name}의 크기가 50MB를 초과합니다.`);
        }
        totalSize += file.size;
      }

      if (totalSize > TOTAL_SIZE_LIMIT) {
        throw new Error("총 파일 크기가 100MB를 초과합니다.");
      }

      const formData = new FormData();
      Array.from(fileList).forEach((file) => {
        formData.append("files", file);
      });

      return uploadVideos(formData);
    },
  });
};

// 음성 파일 업로드
const uploadAudios = async (
  formData: FormData
): Promise<AxiosResponse<UploadResponse>> => {
  return requestToCastingVote({
    method: "POST",
    url: "/upload/audio",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const useUploadAudiosMutation = (): UseMutationResult<
  AxiosResponse<UploadResponse>,
  AxiosError,
  FileList
> => {
  return useMutation({
    mutationFn: async (fileList) => {
      const AUDIO_SIZE_LIMIT = 10 * 1024 * 1024;
      const TOTAL_SIZE_LIMIT = 100 * 1024 * 1024;
      let totalSize = 0;

      for (const file of Array.from(fileList)) {
        if (file.size > AUDIO_SIZE_LIMIT) {
          throw new Error(`음성 파일 ${file.name}의 크기가 10MB를 초과합니다.`);
        }
        totalSize += file.size;
      }

      if (totalSize > TOTAL_SIZE_LIMIT) {
        throw new Error("총 파일 크기가 100MB를 초과합니다.");
      }

      const formData = new FormData();
      Array.from(fileList).forEach((file) => {
        formData.append("files", file);
      });

      return uploadAudios(formData);
    },
  });
};
