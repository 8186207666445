import React from "react";
import { ReactComponent as homeOn } from "assets/home_on.svg";
import { ReactComponent as homeOff } from "assets/home_off.svg";
import { ReactComponent as staffOn } from "assets/staff_on.svg";
import { ReactComponent as staffOff } from "assets/staff_off.svg";
import { ReactComponent as artistOn } from "assets/artist_on.svg";
import { ReactComponent as artistOff } from "assets/artist_off.svg";
import { ReactComponent as moreOn } from "assets/more_on.svg";
import { ReactComponent as moreOff } from "assets/more_off.svg";
import { ReactComponent as castingOn } from "assets/casting_on.svg";
import { ReactComponent as castingOff } from "assets/casting_off.svg";
import { NavLink, useLocation, useParams } from "react-router-dom";
import ContentWrap from "./ContentWrap";
import { useRecoilState } from "recoil";
import { loginCheck } from "store/auth/atom";

const TabBar = () => {
  const location = useLocation();
  const params = useParams();
  const [login, setLogin] = useRecoilState(loginCheck);
  
  const tabBarItem = [
    { id: 0, name: "홈", url: "/", icon: homeOff, iconOn: homeOn },
    {
      id: 1,
      name: "캐스팅",
      url: "/casting",
      icon: castingOff,
      iconOn: castingOn,
    },
    {
      id: 2,
      name: "아티스트",
      url: "/artist",
      icon: artistOff,
      iconOn: artistOn,
    },
    { id: 3, name: "STAFF", url: "/staff", icon: staffOff, iconOn: staffOn },
    {
      id: 4,
      name: "더보기",
      url: login ? "/my" : "/login",
      icon: moreOff,
      iconOn: moreOn,
    },
  ];

  if (location.pathname === `/kmc/callback`) return null;
  
  if (location.pathname === "/artist/profile") return null;
  if (location.pathname === `/artist/profile/edit`) return null;
  if (location.pathname === "/artist/my") return null;
  if (location.pathname === "/artist/send") return null;
  if (location.pathname === "/artist/suggest") return null;
  if (location.pathname === `/artist/${params?.artistId}`) return null;

  if (location.pathname === `/casting/my/${params?.itemId}`) return null;
  if (location.pathname === `/casting/${params?.itemId}`) return null;

  if (location.pathname === `/staff/${params?.staffId}`) return null;
  if (location.pathname === `/staff/feedback/${params?.artistId}`) return null;
  if (location.pathname === `/staff/casting/my`) return null;
  if (location.pathname === `/staff/my`) return null;
  if (location.pathname === `/staff/casting/process/${params?.id}`) return null;
  if (location.pathname === `/staff/filmo/my/${params?.productId}`) return null;
  if (location.pathname === `/staff/filmo/${params?.staffId}`) return null;
  if (location.pathname === `/staff/profile`) return null;
  if (location.pathname === `/staff/profile/edit`) return null;
  if (location.pathname === `/staff/post/edit/${params?.id}`) return null;
  if (location.pathname === `/staff/casting-call/edit/${params?.id}`) return null;
  if (location.pathname === `/staff/post`) return null;
  if (location.pathname === `/staff/casting-call`) return null;
  if (location.pathname === `/staff/received`) return null;
  
  if (location.pathname === `/suggest`) return null;

  if (location.pathname === `/point`) return null;
  if (location.pathname === `/point/charge`) return null;

  return (
    <ContentWrap className="fixed pb-[10px] w-full left-2/4 z-50 bg-Gray01/90 backdrop-blur-xl -translate-x-2/4 pt-2 bottom-0 border rounded-tr-[20px] rounded-tl-[20px]">
      <div className="flex items-center gap-1.5 justify-between RCaption10">
        {tabBarItem.map((item) => {
          const IconOff = item.icon;
          const IconOn = item.iconOn;
          return (
            <NavLink
              key={item.id}
              className={({ isActive }) =>
                `${
                  isActive ? "text-Gray09" : "text-Gray04"
                } flex flex-col flex-1 items-center gap-1.5 mx-auto`
              }
              to={`${item.url}`}
            >
              {({ isActive }) => (
                <>
                  {isActive ? (
                    <IconOn className="mx-auto" />
                  ) : (
                    <IconOff className="mx-auto" />
                  )}
                  <div>{item.name}</div>
                </>
              )}
            </NavLink>
          );
        })}
      </div>
    </ContentWrap>
  );
};

export default TabBar;
