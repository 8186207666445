import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import ContentWrap from "components/ContentWrap";
import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";
import SearchBox from "components/SearchBox";
import SideTab from "components/SideTab";
import StaffCard from "components/StaffCard";
import useDebounce from "hooks/useDebounce";
import Chip from "components/Chip";
import TitleWrap from "components/TitleWrap";
import Button from "components/Button";
import { useStaffs } from "api/Profile/Profile";
import { useRecoilState } from "recoil";
import { innderHeightValue } from "store/auth/atom";
import { JOB_LIST, STAFF_JOB_LIST } from "utils/type";
import InfiniteScroll from "react-infinite-scroller";

const StaffList = () => {
  const navigate = useNavigate();
  const [innderHeight, setInnderHeight] =
    useRecoilState<number>(innderHeightValue);
  const [role, setRole] = useState("");
  const [isSideTabOpen, setIsSideTabOpen] = useState(false);
  const [profileInfo, setProfileInfo] = useState<{
    staffId: number;
    profileId: number;
    castingCallId: number;
  }>({
    staffId: 0,
    profileId: 0,
    castingCallId: 0,
  });

  const handleOpenTab = () => setIsSideTabOpen(true);
  const handleCloseTab = () => setIsSideTabOpen(false);
  const [staffData, setStaffData] = useState({
    role: "",
    page: 0,
    size: 20,
  });

  const [search, setSearch] = useState<string>("");
  const debouncedSearchText = useDebounce(search, 500);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    refetch: staffRefetch,
  } = useStaffs({
    name: debouncedSearchText,
    ...staffData,
  });

  const loadMore = useCallback(() => {
    return setTimeout(() => {
      fetchNextPage();
    }, 200);
  }, []);

  return (
    <ContentWrap className="pb-24">
      <SideTab isOpen={isSideTabOpen} onClose={handleCloseTab}>
        <div className="pl-2 p-4 flex items-center justify-between">
          <div
            onClick={handleCloseTab}
            className="MBody18 cursor-pointer flex items-center"
          >
            <ArrowLeft />
            <div>필터</div>
          </div>
          <div
            onClick={() => {
              // setStaffData({
              //   ...staffData,
              //   role: "",
              // });
              setRole("");
            }}
            className="BBody18 cursor-pointer text-Blue04"
          >
            초기화
          </div>
        </div>
        <div
          style={{ height: `${innderHeight - 127}px` }}
          className="pt-6 pl-4 pb-[100px] flex flex-col items-start gap-10 overflow-y-auto"
        >
          <TitleWrap title="직업">
            <div className="flex items-center flex-wrap gap-2">
              {STAFF_JOB_LIST.map((item, i) => {
                return (
                  <Chip
                    selected={item.role === role}
                    onClick={() => {
                      if (item.role === role) {
                        setRole("");
                      } else {
                        setRole(item.role);
                      }
                    }}
                    key={item.id}
                    title={item.title}
                  ></Chip>
                );
              })}
            </div>
          </TitleWrap>
          {/* <TitleWrap title="키워드" subTitle="중복선택가능">
            <div className="flex items-center flex-wrap gap-2">
              {keyword.map((item, i) => {
                return <Chip key={item.id} title={item.title}></Chip>;
              })}
            </div>
          </TitleWrap> */}
        </div>
        <div className="px-4">
          <Button
            onClick={() => {
              setStaffData({
                ...staffData,
                role: role,
              });
              setIsSideTabOpen(false);
            }}
            className="w-full border-none bg-Blue04 text-white"
            text={"필터 적용하기"}
          ></Button>
        </div>
      </SideTab>
      <div className="flex items-center pt-4 gap-4">
        <SearchBox
          className="w-[316px]"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="스탭 이름을 입력해 주세요"
        />

        <div
          className="cursor-pointer min-w-7 text-Blue04 BBody16"
          onClick={handleOpenTab}
        >
          필터
        </div>
      </div>
      {data?.pages[0].data.content.length === 0 ? (
        <div className="mt-36 items-center text-center">
          <div className="text-Gray05 RBody14">등록된 스탭이 없어요.</div>
        </div>
      ) : (
        <div>
      <InfiniteScroll
        threshold={100}
        hasMore={hasNextPage}
        loadMore={() => loadMore()}
      >
        <div className="flex flex-col gap-4 pt-4">
          {data?.pages?.map((item) => {
            return item.data.content.map((staffItem: any) => {
              return (
                <StaffCard
                  key={staffItem.profileId}
                  image={staffItem?.thumbnailUrl}
                  role={staffItem?.role.label}
                  agency={staffItem?.agency}
                  name={staffItem?.profileName}
                  onClick={() => navigate(`${staffItem.profileId}`)}
                  />
                );
              });
            })}
          </div>
        </InfiniteScroll>
      </div>
    )}
    </ContentWrap>
  );
};

export default StaffList;
