import React from "react";

interface DateinputType {
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  min?:string;
  disabled?: boolean;
  placeholder?: string;
  max?:string;

}

const DateInput = ({ className, disabled, placeholder, min, max, onChange, value }: DateinputType) => {


  return (
    <input
    disabled={disabled}
      type="date"
      min={min}
      onChange={onChange}
      max={max}
      required
      value={value}
      placeholder={placeholder}
      className={` ${className}`}
    />
  );
};

export default DateInput;
