import React, { useEffect, useState } from "react";
import ContentWrap from "components/ContentWrap";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";

import Insta from "../assets/Instagram_black.png";
import Youtue from "../assets/YouTube_black.png";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { info } from "store/auth/atom";
import { Swiper, SwiperSlide } from "swiper/react";
import YouTube from "react-youtube";
import { Autoplay } from "swiper/modules";
import { ReactComponent as SendFeedback } from "assets/sendFeedback.svg";
import { ReactComponent as Feedback } from "assets/icon_checkbox.svg";
import { ReactComponent as BlankStar } from "assets/blankStar.svg";
import { ReactComponent as Star } from "assets/star.svg";
import ImageModal from "components/ImageModal";
import {
  useReceivedProfileDetails,
  useSubmitFeedback,
} from "api/DeliveryProfile/DeliveryProfile";
import BottomButtonPopup from "components/BottomButtonPopup";
import BottomPopup from "components/BottomPopup";
import Textarea from "components/Textarea";
import Button from "components/Button";
import { Toast } from "hooks/useToast";
import HeaderWithBackButton from "components/HeaderWithBackButton";

const ArtistFeedback = () => {
  const { artistId } = useParams();
  const [feedbackScore, setFeedbackScore] = useState(0);
  const [review, setReview] = useState("");
  const [imageDetail, setImageDetail] = useState(false);
  const [sendFeedback, setSendFeedback] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [userData, setUserData] = useRecoilState<{
    id: string;
    username: string;
    role: string;
  }>(info);

  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

  const { data, isLoading, isError, refetch } = useReceivedProfileDetails(
    Number(artistId)
  );

  const ArtistData = data?.data?.data;

  const opt = {
    height: "203",
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
    },
  };

  const calculateAge = () => {
    const currentYear = new Date().getFullYear();
    const calculatedAge =
      currentYear - Number(ArtistData?.birthDate.split("-")[0]);
    return calculatedAge;
  };

  const handleOpenNewTab = (url: string) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  const groupedByYear = ArtistData?.filmographies?.reduce((acc: any, item) => {
    const year = item.releaseYear;
    if (!acc[year]) {
      acc[year] = [];
    }

    acc[year].push(item);

    acc[year].sort((a: any, b: any) => a.orderNumber - b.orderNumber);

    return acc;
  }, {});

  const artistInfo = [
    { id: 1, type: "거주지", data: ArtistData?.basicInfo.address.sido },
    { id: 2, type: "학력", data: ArtistData?.basicInfo.education },
    { id: 3, type: "매니저/소속사", data: ArtistData?.basicInfo.agency },
  ];

  const filteredAttachments = ArtistData?.attachments
    ?.filter(
      (attachment) =>
        attachment.type.code !== "THUMBNAIL" &&
        attachment.type.code !== "ADDITIONAL_VIDEO"
    )
    ?.sort((a, b) => {
      if (
        a.type.code === "ADDITIONAL_IMAGE" &&
        b.type.code !== "ADDITIONAL_IMAGE"
      ) {
        return -1;
      } else if (
        a.type.code !== "ADDITIONAL_IMAGE" &&
        b.type.code === "ADDITIONAL_IMAGE"
      ) {
        return 1;
      }
      return 0;
    });

  const ratingStartValue = () => {
    let result = [];
    for (let i = 0; i < 5; i++) {
      result.push(
        <div
          key={i + 1}
          onClick={() => {
            setFeedbackScore(i + 1);
          }}
        >
          {i + 1 <= feedbackScore ? <Star /> : <BlankStar />}
        </div>
      );
    }
    return result;
  };

  const readRatingStartValue = () => {
    let result = [];
    for (let i = 0; i < 5; i++) {
      result.push(
        <div key={i + 1}>
          {i + 1 <= ArtistData?.rating! ? <Star /> : <BlankStar />}
        </div>
      );
    }
    return result;
  };

  const mutate = useSubmitFeedback();

  const submitFeedback = () => {
    mutate.mutate(
      {
        id: Number(artistId),
        rating: feedbackScore,
        comments: review,
      },
      {
        onSuccess: () => {
          Toast.success("피드백을 보냈어요");
          refetch();
          setSendFeedback(false);
        },
        onError: () => {
          Toast.error("피드백 전송을 실패했습니다.");
        },
      }
    );
  };

  return (
    <>
      {isLoading || isError ? (
        <div>loading</div>
      ) : (
        <ContentWrap
          className={`flex pb-24 ${
            ArtistData?.phoneNumber || ArtistData?.email
              ? "pt-[126px]"
              : "pt-[70px]"
          }  flex-col items-start`}
        >
          <HeaderWithBackButton
            phone={ArtistData?.phoneNumber}
            sms={ArtistData?.phoneNumber}
            email={ArtistData?.email}
            title=""
          >
            <Bookmark className="cursor-pointer" />
          </HeaderWithBackButton>
          {imageDetail && (
            <ImageModal
              onClose={() => setImageDetail(false)}
              className="max-w-full h-[514px]"
            >
              <Swiper slidesPerView={1}>
                {filteredAttachments?.map((item, idx) => {
                  return (
                    <SwiperSlide key={idx} className="h-fit">
                      <img
                        src={`${MEDIA_URL}${item.url}`}
                        alt="IMAGES"
                        className="rounded-[10px] w-full max-w-[360px] h-[514px] mx-auto object-cover "
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </ImageModal>
          )}
          {ArtistData?.description && (
            <div className="mb-5 w-full p-5 rounded-[10px] bg-Gray01 border border-Gray03">
              <div className="mb-4 text-Gray09 BBody18">
                💌{ArtistData?.profileName}님이 보낸 프로필
              </div>
              <div className="RBody16">{ArtistData?.description}</div>
            </div>
          )}
          <div className="w-full flex flex-col gap-2">
            <div>
              <div className="BBody14 text-Gray05">
                {ArtistData?.basicInfo.job.label}
              </div>
              <div className="flex justify-between">
                <div className="flex items-center gap-2">
                  <div className="BBody20">{ArtistData?.profileName}</div>
                  {ArtistData?.snsInfo.instagram && (
                    <img
                      onClick={() => {
                        handleOpenNewTab(ArtistData?.snsInfo.instagram!);
                      }}
                      className="cursor-pointer"
                      src={Insta}
                      alt="instagram"
                    />
                  )}
                  {ArtistData?.snsInfo.youtube && (
                    <img
                      onClick={() => {
                        handleOpenNewTab(ArtistData?.snsInfo.youtube!);
                      }}
                      className="cursor-pointer"
                      src={Youtue}
                      alt="youtube"
                    />
                  )}
                </div>
              </div>
              <div className="flex mb-2 items-center RBody16 gap-2">
                <div>{`${
                  ArtistData?.birthDate.split("-")[0]
                }년생 (${calculateAge()}세)`}</div>
                <div>{`${ArtistData?.basicInfo.height}cm`}</div>
                <div>{`${ArtistData?.basicInfo.weight}kg`}</div>
              </div>
              <div className="flex flex-wrap gap-x-2 RBody16 text-Blue04">
                {ArtistData?.hashtags?.map((item) => {
                  return <div key={item.id}>#{item.name}</div>;
                })}
              </div>
            </div>
            <div>
              <img
                src={`${MEDIA_URL}${ArtistData?.thumbnailUrl}`}
                alt=""
                className="w-full h-[450px] object-cover rounded-[10px]"
              />
              <div className="w-full mt-2">
                <Swiper
                  autoplay={{ delay: 2000, disableOnInteraction: false }}
                  loop={true}
                  modules={[Autoplay]}
                  spaceBetween={8}
                  slidesPerView={filteredAttachments?.length! < 4 ? 3 : "auto"}
                >
                  {filteredAttachments?.map((item, idx) => {
                    return (
                      <SwiperSlide
                        className={
                          filteredAttachments.length! < 4 ? "w-full" : "!w-fit"
                        }
                        onClick={() => {
                          setImageDetail(true);
                        }}
                        key={idx}
                      >
                        <div className="w-full">
                          <img
                            src={`${MEDIA_URL}${item.url}`}
                            alt="IMAGES"
                            className="w-[114px] h-[114px] rounded-[10px] object-cover"
                          />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              {ArtistData?.attachments?.map((item, idx) => {
                if (item.type.code === "ADDITIONAL_VIDEO") {
                  return (
                    <video
                      key={item.id}
                      controls
                      className="w-full h-[186px] rounded-[10px]"
                    >
                      <source
                        src={`${MEDIA_URL}${item.url}`}
                      ></source>
                    </video>
                  );
                }
              })}
              {ArtistData?.videoLinks?.map((item, idx) => {
                let link = "";

                if (item.link.startsWith("https://www.youtube.com")) {
                  link = item.link.split("watch?v=")[1];
                } else {
                  link = item.link.split("?")[0].split("/")[3];
                }
                return (
                  <YouTube
                    key={item.orderNumber}
                    className={"w-full"}
                    opts={opt}
                    iframeClassName="youtubeRounded"
                    videoId={link}
                  />
                );
              })}
            </div>
          </div>
          <div className="flex-1 w-full">
            <hr className="w-full h-px my-10 bg-[#D9D9D9]" />
            <div className="flex flex-wrap gap-y-10 items-center">
              {artistInfo.map((item) => {
                if (item?.id < 2 && item?.data)
                  return (
                    <div key={item.id}>
                      {item.data !== "0cm/0kg" ? (
                        <div className="flex flex-col w-[180px]">
                          <div className="RBody14 text-Gray05">{item.type}</div>
                          <div className="MBody16">{item.data}</div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
              })}
              <div>
                {ArtistData?.hobbies.length !== 0 && (
                  <div className="flex flex-col w-[360px]">
                    <div className="RBody14 text-Gray05">특기</div>
                    <div className="MBody16 flex items-center">
                      {ArtistData?.hobbies.map((item, idx) => {
                        return (
                          <div key={item.id}>
                            {item.name}
                            {idx < ArtistData.hobbies.length - 1 ? "/" : ""}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              {artistInfo.map((item) => {
                if (item.id > 1 && item.data)
                  return (
                    <div className="flex flex-col w-[360px]" key={item.id}>
                      {item.data !== "" ? (
                        <div className="flex flex-col">
                          <div className="RBody14 text-Gray05">{item.type}</div>
                          <div className="MBody16">{item.data}</div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
              })}
            </div>
            {ArtistData?.introduce !== "" && (
              <>
                <hr className="w-full h-px my-10 bg-[#D9D9D9]" />
                <div className="w-full flex flex-col gap-2">
                  <div className="RBody16 text-Gray05">자기소개</div>
                  <div className="MBody16 max-h-[336px] whitespace-pre-wrap overflow-y-scroll">
                    {ArtistData?.introduce}
                  </div>
                </div>
              </>
            )}
            {ArtistData?.filmographies?.length !== 0 && (
              <>
                <hr className="w-full h-px my-10 bg-[#D9D9D9]" />
                <div className="w-full flex flex-col gap-2">
                  <div className="RBody16 text-Gray05">필모그래피</div>
                  {ArtistData ? (
                    <div className="flex flex-col gap-4">
                      {Object.keys(groupedByYear).sort((a, b) => Number(b) - Number(a))?.map((year) => (
                        <div className="flex items-start gap-4" key={year}>
                          <div className="RBody14 text-Gray09">{year}</div>
                          <div className="flex flex-col gap-4">
                            {groupedByYear[year].map((item: any) => (
                              <div key={item.id}>
                                <div className="RBody14 text-Gray05">
                                  {item?.type?.label}
                                </div>
                                <div className="BBody16 flex  items-center gap-2">
                                  <div>{item.title}</div>
                                  <div className="text-Gray05">
                                    {item?.role?.label}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </div>
          {feedback && (
            <BottomPopup
              onClose={() => {
                setFeedback(false);
              }}
            >
              <div className="BBody20">보낸 프로필 피드백</div>
              <div className="mt-4 mb-10">
                <div className="flex items-center gap-1">
                  <div className="RBody16 text-Gray09">별점</div>
                  <div className="BBody16 text-Blue04">
                    {ArtistData?.rating ? ArtistData?.rating : 0}/5
                  </div>
                </div>
                <div className="flex items-center mt-4 gap-4">
                  {readRatingStartValue()}
                </div>
              </div>
              <div className="p-4 bg-Gray01 rounded-[10px] RBody16">
                {ArtistData?.comments}
              </div>
            </BottomPopup>
          )}
          {sendFeedback && (
            <BottomPopup
              onClose={() => {
                setSendFeedback(false);
                setFeedbackScore(0);
              }}
            >
              <div className="BBody20">프로필 피드백</div>
              <div className="mt-4 mb-10">
                <div className="flex items-center gap-1">
                  <div className="RBody16 text-Gray09">별점</div>
                  <div className="BBody16 text-Blue04">
                    {feedbackScore ? feedbackScore : 0}/5
                  </div>
                </div>
                <div className="flex items-center mt-4 gap-4">
                  {ratingStartValue()}
                </div>
              </div>
              <div className="RBody16">이런 점이 좋아요🙂 / 아쉬워요😔</div>
              <Textarea
                onChange={(e) => {
                  setReview(e.target.value.trim());
                }}
                value={review}
                className="h-[105px] my-4"
              ></Textarea>
              <div className="RBody14 text-Gray05">
                타인을 비방하는 내용은 --돼요
              </div>
              <Button
                onClick={() => {
                  submitFeedback();
                }}
                disabled={feedbackScore === 0}
                className="w-full bg-Blue04 text-Gray00 mt-4"
                text={"피드백 보내기"}
              />
            </BottomPopup>
          )}

          {ArtistData?.status.code === "CANCELED" ||
          ArtistData?.status.code === "REFUNDED" ? (
            ""
          ) : (
            <BottomButtonPopup
              onClick={() => {
                if (
                  ArtistData?.status.code === "DELIVERED" ||
                  ArtistData?.status.code === "READ"
                ) {
                  setSendFeedback(true);
                } else {
                  setFeedback(true);
                }
              }}
              onClose={() => {}}
            >
              <div className="flex flex-col pt-2 justify-center items-center MBody14 cursor-pointer text-Gray00">
                {ArtistData?.status.code === "DELIVERED" ||
                ArtistData?.status.code === "READ" ? (
                  <SendFeedback />
                ) : (
                  <Feedback />
                )}

                <div>
                  {ArtistData?.status.code === "DELIVERED" ||
                  ArtistData?.status.code === "READ"
                    ? "피드백 보내기"
                    : "보낸 피드백 보기"}{" "}
                </div>
              </div>
            </BottomButtonPopup>
          )}
        </ContentWrap>
      )}
    </>
  );
};

export default ArtistFeedback;
