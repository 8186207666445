import React from "react";

interface ArtistCardType {
  artistName: string;
  artistJop?: string;
  artistSize?: string;
  onClick?: () => void;
  src?: string;
}

const ArtistCard = ({
  artistName,
  artistJop,
  src,
  artistSize,
  onClick,
}: ArtistCardType) => {

  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

  return (
    <div className="w-[139px]">
      {src &&
      <img
      src={`${MEDIA_URL}${src}`}
      alt="Thumnail"
      onClick={onClick}
      className="object-cover w-[139px] h-[173.75px] cursor-pointer rounded-[10px]"
      />
    }
      <div className="inline-flex gap-2 mt-4 mb-2 items-center">
        <div className="BBody16">{artistName}</div>
        <div className="BBody14 text-Gray05">{artistJop}</div>
      </div>
      {artistSize && <div className="MBody16 text-Gray05">170/54</div>}
    </div>
  );
};

export default ArtistCard;
