import { AxiosError, AxiosResponse } from "axios";
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from "react-query";
import { useNavigate } from "react-router-dom";

import { AuthInfoResponse, Login, LoginCheckData, Signup, SignupData } from "type/auth";
import requestToCastingVote from "utils/requestToCastingVote";

// 회원 가입
const signup = ({
  userId,
  certNum,
  password,
  name,
  phoneNumber,
  email,
  gender,
  birthDate,
  zonecode,
  roadAddress,
  sido,
  jibunAddress,
  sigungu,
  termsOfService,
  termsOfServiceAt,
  privacyPolicy,
  privacyPolicyAt,
  thirdPartySharing,
  thirdPartySharingAt,
  marketingOptIn,
  marketingOptInAt,
  recommendId,
  role,
  businessLicenseId,
  businessName,
  businessNumber,
}: Signup) => {
  return requestToCastingVote({
    method: "POST",
    url: "auth/signup",
    data: {
      userId,
      password,
      name,
      certNum,
      phoneNumber,
      email,
      gender,
      birthDate,
      zonecode,
      roadAddress,
      jibunAddress,
      sido,
      sigungu: sigungu,
      termsOfService: termsOfService,
      termsOfServiceAt: termsOfServiceAt,
      privacyPolicy: privacyPolicy,
      privacyPolicyAt: privacyPolicyAt,
      thirdPartySharing: thirdPartySharing,
      thirdPartySharingAt: thirdPartySharingAt,
      marketingOptIn: marketingOptIn,
      marketingOptInAt: marketingOptInAt,
      recommendId: recommendId,
      role: role,
      businessName: businessName,
      businessNumber: businessNumber,
      businessLicenseId: businessLicenseId,
    },
  });
};
export const useSignupMutation = (
  params: Signup
): UseMutationResult<
  AxiosResponse<SignupData>,
  AxiosError<{
    code: number;
    status: string;
    message: string;
    data: string[] | string;
  }>,
  Signup
> => {
  const signupMutation = useMutation<
    AxiosResponse<SignupData>,
    AxiosError<{
      code: number;
      status: string;
      message: string;
      data: string[] | string;
    }>,
    Signup
  >(["signup"],{
    mutationFn: signup,
  });

  return signupMutation;
};

// 로그인
const login = ({ userId, password, remember }: Login) => {
  return requestToCastingVote({
    method: "POST",
    url: "auth/login",
    data: {
      userId: userId,
      password: password,
      remember: remember,
    },
  });
};
export const useLoginMutation = (
  params: Login
): UseMutationResult<AxiosResponse<LoginCheckData>, Error, Login> => {
  const loginMutation = useMutation<
    AxiosResponse<LoginCheckData>,
    AxiosError,
    Login
  >(["login"], {
    mutationFn: login,
  });
  return loginMutation;
};

// 로그인 체크
const getLoginCheck = (islogin: boolean) => {
  return requestToCastingVote({
    method: "GET",
    url: "auth",
  });
};
export const useLoginCheck = (
  isLogin: boolean
): UseQueryResult<AxiosResponse<LoginCheckData>, Error> =>
  useQuery(["loginCheck", isLogin], () => getLoginCheck(isLogin), {
    enabled: isLogin,
  });

const fetchAuthInfo = (): Promise<AxiosResponse<AuthInfoResponse>> => {
  return requestToCastingVote.get('/auth/info');
};

// React Query 훅 생성
export const useAuthInfo = (isLogin:boolean) => {
  return useQuery('authInfo', fetchAuthInfo,
     {
      enabled: isLogin,
  //   staleTime: 1000 * 60 * 5, // 5분간 캐시 유지
  //   cacheTime: 1000 * 60 * 10, // 10분간 캐시 보관
  }
);
};
